const mirrorPreviewsKey = 'mirror-preview-apps';

export const updatePreviewMirror = (preview: string, appId: number): void => {
  const rawData = localStorage.getItem(mirrorPreviewsKey);
  let mirrorPreview: {[key: string]: string} = {};
      
  if (!!rawData) {
    mirrorPreview = JSON.parse(rawData) as {[key: string]: string};
  }
  mirrorPreview[appId] = preview;
  localStorage.setItem(mirrorPreviewsKey, JSON.stringify(mirrorPreview));
}

export const getPreviewMirror = (appId: number): string => {
  const rawData = localStorage.getItem(mirrorPreviewsKey);
  let mirrorPreview: {[key: string]: string} = {};
      
  if (!!rawData) {
    mirrorPreview = JSON.parse(rawData) as {[key: string]: string};
  }
  
  return !!mirrorPreview[appId] ? mirrorPreview[appId] : '';
}