import React from 'react';
import styled from 'styled-components';

import CustomIcon from './CustomIcon';

const Container = styled.div<{isInverted?: boolean}>`
  width: 100%;
  display: flex;
  padding: 0px;
  
  .icon-container {
    max-width: 12%;
    flex: 0 0 12%;

    display: flex;
    align-items: center;

    justify-content: ${({isInverted})=> isInverted ? 'end' : 'start'};
    order: ${({isInverted})=> isInverted ? 2 : 1};
  }

  .body {
    max-width: 88%;
    flex: 0 0 88%;

    font-size: 16px;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: capitalize;

    padding-top: ${({isInverted})=> isInverted ? 0 : 15}px;
    padding-bottom: ${({isInverted})=> isInverted ? 0 : 15}px;

    order: ${({isInverted})=> isInverted ? 1 : 2};
  }
`;

const IconTextItem: React.FC<{
  icon: string, 
  isImage?: boolean, 
  isIconClikable?: boolean,
  body: JSX.Element, 
  isInverted?: boolean, 
  onClick?: () => void}> 
  = ({icon, isImage = false, isIconClikable=false, body, isInverted, onClick}) => {

  return (
    <Container isInverted={isInverted} onClick={isIconClikable ? ()=>{return 1;} : onClick }>
      <div className='icon-container' onClick={isIconClikable ? onClick : ()=>{return 1;} }>
        <CustomIcon isImage={isImage} icon={icon} />
      </div>
      <div className='body'>
        {body}
      </div>
    </Container>
  )
}

export default IconTextItem;
