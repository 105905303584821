import React from 'react';
import styled from 'styled-components';

const Body = styled.div`
  width: 100%;
  padding: .5rem 1rem;
  text-align: left;
  color: inherit;
  font-size: 1.4rem;

  &.--is-active,
  &:focus,
  &:hover {
    background-color: rgba(56,140,255,.2);
  }

  &.--is-dark.--is-active,
  &.--is-dark:focus,
  &.--is-dark:hover {
    background-color: rgba(100%, 100%, 100%, .2);
  }

  &.--is-dark {
    padding: 1rem;
    text-align: center;
  }
`;

const Item: React.FC<{name: string, isActive?: boolean, isDarkType?:boolean}> 
  = ({name, isActive=false, isDarkType=false}) => {

  const onClickHandle = () => {
    //Typing here the function to change the active option
    console.log('click on ' + name);
  }

  const setClassName = () => {
    let classname = '';

    if(isActive) classname += '--is-active';
    if(isDarkType) classname += ' --is-dark';

    return classname;
  }

  return (
    <Body onClick={onClickHandle} className={setClassName()}>
      {name}
    </Body>
  );
}

export default Item;
