import { 
  DayOfWeek,
  WeekModifier,
  OffsetDirection
} from "@appsinti/stats-utils/src/interfaces/SpecialRangeDatesConfig";
import SelectOption from "../../../../../../../interfaces/SelectOption";
import { parse } from "date-fns";
import { DayOfWeekValues, WeekModifierValues, OffsetDirectionValues } from "@appsinti/stats-utils/src/interfaces/SpecialDateValues";
import { SpecialRangeDateState } from './specialRangeDatesReducer';
import Translation from "@appsinti/i18n/interfaces/Translation";

export function validateTitle(title: Translation): boolean {
  return typeof title === "object" && !!title.en;
}

export function validateFixedRangeDate(date: {startDate: string, endDate: string}): boolean {
    let valid: boolean;
    try {
        parse(date.startDate, "yyyy-MM-dd", new Date());
        parse(date.endDate, "yyyy-MM-dd", new Date());
        valid = true;
    } catch (e) {
        console.error(e);
        valid = false;
    }

  return valid;
}

export function validatePatternRangeDate(
  month: SelectOption<number>,
  weekModifier: SelectOption<WeekModifier>,
  dayOfWeek: SelectOption<DayOfWeek>,
  offset: SelectOption<number>,
  offsetDirection: SelectOption<OffsetDirection>,
): boolean {
    const validMonth = month.value < 13 && month.value > 0;
    const validWeekModifier = Object.values(WeekModifierValues).includes(weekModifier.value);
    const validDayOfWeek = Object.values(DayOfWeekValues).includes(dayOfWeek.value);
    const validOffset = offset.value < 16 && offset.value > 0;
    const validOffsetDirection = Object.values(OffsetDirectionValues).includes(offsetDirection.value);

    return validMonth && validWeekModifier && validDayOfWeek && validOffset && validOffsetDirection;
}

export function validateCustomRangeDate(dates: { [key: string]: {startDate: string, endDate: string};} ): boolean {
    let valid = true;

    for(const date of Object.values(dates)) {
        if(!validateFixedRangeDate(date)) {
            valid = false;
        }
    }

    return valid;
}

function validateDate(state: SpecialRangeDateState): boolean {
  const { selectedType, patternRangeDate, customRangeDates, fixedRangeDate } = state;

  if (selectedType === "date") {
    return validateFixedRangeDate(fixedRangeDate);
  }

  if (selectedType === "pattern-offset") {
    return validatePatternRangeDate(patternRangeDate.month, patternRangeDate.weekModifier, patternRangeDate.dayOfWeek, patternRangeDate.offset, patternRangeDate.offsetDirection);
  }

  if (selectedType === "custom") {
    return validateCustomRangeDate(customRangeDates);
  }

  return false;
}

function validateForm(state: SpecialRangeDateState): boolean {
  const titleValid = validateTitle(state.title);
  const dateValid = validateDate(state);

  return titleValid && dateValid;
}

export default validateForm;
