import React from "react";
import { Route, Routes } from "react-router-dom";
import Graph from "./pages/Graph";

const Clients: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Graph />} />
    </Routes>
  );
};
export default Clients;
