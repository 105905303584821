export const capitalize = (string) =>
    string
        .split(' ')
        .map((item) => item.charAt(0).toLocaleUpperCase() + item.slice(1))
        .join(' ');

export const toFixed = (num, fixed) => {
    if(!num) return '0.0';
    let re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)[0];
}

export const formatPercentage = (value, decimalCount = 2) => {
    const fixedPointValue = toFixed(value, decimalCount);

    return `${fixedPointValue}%`;
}

export const formatMoneyMK = (amount, currencySymbol = '$') => {
    return `${amount > 999999 ? formatMoney(amount/1000, 1, '.', ',', currencySymbol) + 'M' : (amount > 999 ? formatMoney(amount/1000, 1, '.', ',', currencySymbol) + 'K' : formatMoney(amount, 0, '.', ',', currencySymbol))}`;
}

export const formatMoney = (amount = 0.0, decimalCount = 2, decimal = '.', thousands = ',', currencySymbol = '$') => {
    try {
        const negativeSign = amount < 0 ? '-' : '';

        const absoluteValue = Math.abs(amount);
        const fixedPointValue = toFixed(absoluteValue, decimalCount);
        const integerPart = parseInt(fixedPointValue);

        const amountLength = integerPart.toString().length;

        const thousandPartsCount = Math.floor(amountLength/3);
        const thousandPartMoulus = amountLength%3;

        const parts = [];
        for(let i = 1; i <= thousandPartsCount; i++) {
            parts.unshift(integerPart.toString().substring(amountLength - i * 3, amountLength - i * 3 + 3));
        }
        if(thousandPartMoulus !== 0) {
            parts.unshift(integerPart.toString().substring(0, thousandPartMoulus));
        }
        const integerString = amountLength > 3 ? parts.join(thousands) : integerPart;

        const decimalPart = fixedPointValue.split('.')[1];
        const zerosArray = Array(decimalCount).fill();

        let decimalString = '';
        if(decimalPart) {
            const digits = `${decimalPart}`.split('');
            decimalString = zerosArray.map((_, index) => (digits[index] ? digits[index] : '0')).join('');
        } else {
            decimalString = zerosArray.map(() => '0').join('');
        }

        return `${negativeSign}${currencySymbol}${integerString}${!!decimalString && decimalString.length > 0 ? decimal : ''}${decimalString}`
    } catch (e) {
        console.error(e);
    }
};
