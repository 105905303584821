import React, { useEffect, useMemo } from "react";
import { default as MainHeaderApp } from "@appsinti/ui-core/header";
import { ArrowLeftIcon } from "@etiquette-ui/icons";
import { useData } from "../../../../../../data";
import BarChart from "@appsinti/stats-frontend/home/components/charts/bar";
import COLORS from "@appsinti/stats-frontend/resources/constants/colors";
import RangeDateItem from "../../components/RangeDateItem";
import Loader from "../../../../../../components/charts/components/Loader";
import Card from "../../../../containers/Card";
import Content from "../../../../containers/Content";
const { getSortedRangeDatesId } = require("@appsinti/stats-utils");
import NewItemButton from "../../../../components/NewItemButton";

const Main: React.FC = () => {
  const {
    sales,
    fetchSales,
    fetchSpecialRangeDatesConfig,
    specialRangeDatesConfig,
  } = useData();

  useEffect(() => {
    fetchSpecialRangeDatesConfig();
    fetchSales();
  }, []);

  const rangeDataStacks = useMemo(() => {
    if (!sales?.rangeDates) return [] as string[];
    const stacks = new Set<string>();
    for (const set of sales.rangeDates.datasets) {
      if (typeof set.stack === "string") stacks.add(set.stack);
    }
    return Array.from(stacks.values());
  }, [sales?.rangeDates]);

  const rangeDates = useMemo(()=>{
    if(!specialRangeDatesConfig) return undefined;

    return getSortedRangeDatesId(specialRangeDatesConfig);
  },[specialRangeDatesConfig]);
  
  return (
    <>
      <MainHeaderApp
        title={"Edit Special Range Dates"}
        icon={<ArrowLeftIcon styles={{}} />}
        backButtonLocation="../.."
      />
      <Content>
        {sales?.rangeDates && (
          <BarChart
            dataObject={sales?.rangeDates}
            barOptions={{
              isStacked: true,
              stackLabels: sales.rangeDates.stackLabels,
              isTimeBased: sales.rangeDates.isTimeBased,
              timeOptions: { unit: "year", displayFormats: { month: "yyyy" } },
              xAxis: {
                min: sales?.rangeDates.xAxis?.min,
                max: sales?.rangeDates.xAxis?.max,
              },
              yAxis: {
                min: sales?.rangeDates.yAxis?.min,
                max: sales?.rangeDates.yAxis?.max,
              },
            }}
            colorCallback={(index, dataset) => {
              if (!dataset.stack) return COLORS[0];
              const stackIndex = rangeDataStacks.indexOf(dataset.stack);
              return COLORS[stackIndex % COLORS.length];
            }}
          />
        )}
        <Card
          body={
            !!rangeDates && !!specialRangeDatesConfig ? (
              <>
                {rangeDates.map((rangeDateId) => (
                  <RangeDateItem key={rangeDateId} rangeDate={specialRangeDatesConfig[rangeDateId]} rangeDateId={rangeDateId} />
                ))}
                <NewItemButton to="range-date" />
              </>
            ) : (
              <Loader />
            )
          }
        />
      </Content>
    </>
  );
};
export default Main;