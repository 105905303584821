import React, { useEffect, useMemo } from "react";
import Body from "@appsinti/stats-frontend/home/components/Body";
import LineChart from "@appsinti/stats-frontend/home/components/charts/line";
import { useData } from "../../../../data";
import { Ring } from "@uiball/loaders";
import { BG_DARK } from "@etiquette-ui/colors";

const Graph: React.FC = () => {
  const { clients, fetchClients, loading } = useData();

  useEffect(() => {
    fetchClients();
  }, []);

  return clients && !loading ? (
    <Body>
      <LineChart
        dataObject={clients?.monthly}
        lineOptions={{
          xAxis: {
            hasTextAsValue: true,
            min: clients.monthly.xAxis?.min,
            max: clients.monthly.xAxis?.max,
          },
          yAxis: {
            hasTextAsValue: false,
            min: clients.monthly.yAxis?.min,
            max: clients.monthly.yAxis?.max,
          },
          isTimeBased: clients.monthly.isTimeBased,
          timeOptions: {
            unit: "day",
            displayFormats: { day: "EEEEEE" },
          },
        }}
        dottedSegmentOptions={{
          hasADottedSegment: true,
          dottedSegmentType: "segment",
          dottedSegmentAlignment: "end",
        }}
      />
      <LineChart
        dataObject={clients?.dayWeek}
        lineOptions={{
          xAxis: {
            hasTextAsValue: true,
            min: clients.dayWeek.xAxis?.min,
            max: clients.dayWeek.xAxis?.max,
          },
          yAxis: {
            hasTextAsValue: false,
            min: clients.dayWeek.yAxis?.min,
            max: clients.dayWeek.yAxis?.max,
          },
          isTimeBased: clients.dayWeek.isTimeBased,
          timeOptions: { unit: "day", displayFormats: { day: "EEEEEE" } },
        }}
        dottedSegmentOptions={{
          hasADottedSegment: true,
          dottedSegmentType: "complete",
        }}
      />
      <LineChart
        dataObject={clients?.daily}
        lineOptions={{
          xAxis: {
            hasTextAsValue: true,
            min: clients.daily.xAxis?.min,
            max: clients.daily.xAxis?.max,
          },
          yAxis: {
            hasTextAsValue: false,
            min: clients.daily.yAxis?.min,
            max: clients.daily.yAxis?.max,
          },
          isTimeBased: clients?.daily.isTimeBased,
          timeOptions: {
            unit: "day",
            displayFormats: { day: "EEEEEE" },
          },
        }}
      />
      <LineChart
        dataObject={clients?.weekYearly}
        lineOptions={{
          xAxis: {
            hasTextAsValue: true,
            min: clients.weekYearly.xAxis?.min,
            max: clients.weekYearly.xAxis?.max,
          },
          yAxis: {
            hasTextAsValue: false,
            min: clients.weekYearly.yAxis?.min,
            max: clients.weekYearly.yAxis?.max,
          },
          isTimeBased: clients?.weekYearly.isTimeBased,
          timeOptions: {
            unit: "day",
            displayFormats: { day: "EEEEEE" },
          },
        }}
        dottedSegmentOptions={{
          hasADottedSegment: true,
          dottedSegmentType: "segment",
          dottedSegmentAlignment: "start",
        }}
      />
    </Body>
  ) : (
    <Body style={{ display: "flex", justifyContent: "center" }}>
      <Ring color={BG_DARK} size={50} />
    </Body>
  );
};

export default Graph;
