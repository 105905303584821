import React from 'react';
import styled from 'styled-components';
import { useTemperature } from '@appsinti/weather-frontend/providers/temperature';
import Item from './Item';
import { useData } from '../data';
import { dateFormat } from "@appsinti/common-utils";

const Body = styled.div`
  width: 100%;
  padding: 0 10px 24px 10px;
  overflow-x: scroll;
  font-size: 1.2rem;
`;

const Container = styled.div`
  width: max-content;
  display: flex;
  grid-gap: 1.5rem;
`;



const Hourly: React.FC = () => {
  const { hourly } = useData();
  const { temperatureObject } = useTemperature();

  return (
    <Body>
      <Container>
        {!!hourly && hourly.map((hourData, index) => (
          <Item 
            key={index}
            title={dateFormat(hourData.time, "h a")} 
            icon={hourData.icon} 
            temperature={ temperatureObject(hourData.apparentTemperature) } 
            isColumn={true}
          />
        ))}
      </Container>
      
    </Body>
  )
}

export default Hourly;
