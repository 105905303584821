import React from "react";
import StatsPreview from "../../../interfaces/StatsPreview";
import SalesSplits from "./SalesSplits";
import SalesBasic from "./SalesBasic";
import SalesGuestSplits from "./SalesGuestSplits";
import Reservations from "./Reservations";
import CourtesyCancellations from "./CourtesyCancellations";
import styled from "styled-components";
import ClosedSalesCash from "./ClosedSalesCash";

const SplitsContainer = styled.div`
  flex: 5;
  display: flex;
  gap: 5px;
  flex-direction: column;
`;

const BottomContainer = styled.div`
  display: flex;
  flex: 2;
  gap: 5px;
`;

const CardsHalf: React.FC<{ preview: StatsPreview }> = ({ preview }) => {
  if (!!preview.lunch && !!preview.dinner) {
    return (
      <>
        <SplitsContainer>
          <SalesSplits
            total={preview.total}
            lunch={preview.lunch}
            dinner={preview.dinner}
          />
          <SalesGuestSplits
            total={preview.total}
            lunch={preview.lunch}
            dinner={preview.dinner}
          />
          <ClosedSalesCash preview={preview} />
        </SplitsContainer>
        <BottomContainer>
          <Reservations preview={preview} />
          <CourtesyCancellations preview={preview} />
        </BottomContainer>
      </>
    );
  }

  return (
    <>
      <SalesBasic preview={preview} />
      <ClosedSalesCash preview={preview} />
      <BottomContainer>
        <Reservations preview={preview} />
        <CourtesyCancellations preview={preview} />
      </BottomContainer>
    </>
  );
};

export default CardsHalf;
