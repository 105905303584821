import React, { Children, useState } from 'react';
import Select from 'react-select';
import { components } from "react-select";

import { BG } from '@etiquette-ui/colors';

import CustomIcon from '../CustomIcon';
import GroupedHeaderLabel from './components/GroupedHeaderLabel';
import { Label, IconContainer} from './styles'

import GroupedOption, {Option as OptionType} from './interfaces/IGroupOption';

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          value={props.value}
        />{" "}
        <label style={{fontSize: '14px'}}>{props.label}</label>
      </components.Option>
    </div>
  );
};

const ValueContainer = (props) => {
  const { 
    labelProps,
  } = props.selectProps;
  const { label = ''} = labelProps;
  return (
    <components.ValueContainer  {...props}>
      {!!label && (
        <Label>
          {label}
        </Label>
      )}          
      {props.children}
    </components.ValueContainer>
  )
};

const DropdownIndicator = ( props ) => {
  return (
    <components.DropdownIndicator {...props}>
      <IconContainer>
          <CustomIcon icon='chevron-down' color={BG}/>
        </IconContainer>
    </components.DropdownIndicator>
  );
};

type MultiGroupedDropdown = {
  label?: string;
  placeholder?: string
  value?: OptionType[];
  options: GroupedOption[];
  onChange?: (optionsSelected: OptionType[])=>void;
}
const MultiGroupedDropdown: React.FC<MultiGroupedDropdown> = ({value, placeholder="Select one", label="Label Example", options, onChange }) => {

  return (
    <Select<OptionType, false, GroupedOption>
      className={'input'}
      isSearchable={false}
      defaultMenuIsOpen={false}
      classNamePrefix={'selector'}
      backspaceRemovesValue={false}
      placeholder={placeholder}
      options={options}
      value={value}
      formatGroupLabel={GroupedHeaderLabel}
      onChange={onChange}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isMulti
      allowSelectAll={true}
      labelProps={{label}}
      components={{ 
        Option,
        ValueContainer, 
        DropdownIndicator,
      }}
      styles={{
        control: (base) => ({
          ...base,
          minHeight: '50px'
        }),
        indicatorSeparator: (base) => ({
          ...base,
          display: 'none',
        }),
        clearIndicator: (base) => ({
          ...base,
          display: 'none'
        }),
        valueContainer: (base) => ({
          ...base,
          marginTop: '10px',
          overflow: 'visible'
        }),
        multiValueLabel: (base) => ({
          ...base,
          fontSize: '11px',
          fontWeight: '500',
        }),
      }}
    />
  )
}
export default MultiGroupedDropdown;