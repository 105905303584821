import React from 'react';
import { GroupHeader } from '../styles'

import GroupedOption from '../interfaces/IGroupOption';

const GroupedHeaderLabel= (data: GroupedOption) => {
  return (<GroupHeader>
    <span>{data.label}</span>
  </GroupHeader>);
};
export default GroupedHeaderLabel;