import React, { createContext, useState, useContext, PropsWithChildren, useEffect, useMemo } from 'react';

interface IUpdateContext {
    update: boolean;
    loading: boolean,
    triggerUpdate: () => void;
    addLoadingState: (key: string) => void;
    resolveLoadingState: (key: string) => void;
}

const UpdateContext = createContext<IUpdateContext | undefined>(undefined);

const UpdateProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const [update, setUpdate] = useState(false);
    const [loadingStates, setLoading] = useState<{ [key: string]: boolean }>({});

    const loading = useMemo(()=> Object.values(loadingStates).some(st => st), [loadingStates]);

    const triggerUpdate = () => {
        setUpdate(!update);
    };

    const addLoadingState = (key: string) => {
        setLoading(prev => ({ ...prev, [key]: true }));
    }
    
    const resolveLoadingState = (key: string) => {
        setLoading(prev => ({ ...prev, [key]: false }));
    }

    return (
        <UpdateContext.Provider value={{ update, triggerUpdate, loading, addLoadingState, resolveLoadingState }}>
            {children}
        </UpdateContext.Provider>
    );
};

export const useUpdate = (): IUpdateContext => {
    const context = useContext(UpdateContext);
    if (context === undefined) {
        throw new Error('useUpdate must be used within a UpdateProvider')
    }
    return context;
}

export default UpdateProvider;