import React, { useEffect } from "react";
import Body from "@appsinti/stats-frontend/home/components/Body";
import { useData } from "../../data";
import LineChart from "../../components/charts/line";
import Navbar from "../../components/Navbar";

const Cancellations: React.FC = () => {
  const { cancellations, fetchCancellations } = useData();

  useEffect(() => {
    fetchCancellations();
  }, []);

  return (
    <Body>
      <LineChart
        dataObject={cancellations?.daily_cancellations}
        lineOptions={{
          xAxis: {
            hasTextAsValue: true,
            min: cancellations?.daily_cancellations?.xAxis?.min,
            max: cancellations?.daily_cancellations?.xAxis?.max,
          },
          yAxis: {
            hasTextAsValue: false,
            min: cancellations?.daily_cancellations?.yAxis?.min,
            max: cancellations?.daily_cancellations?.yAxis?.max,
          },
          isTimeBased: cancellations?.daily_cancellations.isTimeBased,
          timeOptions: {
            unit: "day",
            displayFormats: { day: "EEEEEE" },
          },
        }}
      />
      <LineChart
        dataObject={cancellations?.daily_courtesies}
        lineOptions={{
          xAxis: {
            hasTextAsValue: true,
            min: cancellations?.daily_courtesies?.xAxis?.min,
            max: cancellations?.daily_courtesies?.xAxis?.max,
          },
          yAxis: {
            hasTextAsValue: false,
            min: cancellations?.daily_courtesies?.yAxis?.min,
            max: cancellations?.daily_courtesies?.yAxis?.max,
          },
          isTimeBased: cancellations?.daily_courtesies.isTimeBased,
          timeOptions: {
            unit: "day",
            displayFormats: { day: "EEEEEE" },
          },
        }}
      />
    </Body>
  );
};
export default Cancellations;
