import React from "react";
import Card from "../containers/Card";
import SectionContainer from "../containers/SectionContainer";
import SectionTitle from "./SectionTitle";
import { Input } from "@etiquette-ui/inputs-dropdowns";
import useI18n from "@appsinti/i18n/useI18n";

const NameSection: React.FC<{
  title: string;
  setTitle: (title: string) => void;
}> = ({ title, setTitle }) => {

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const {translateObject} = useI18n();
  const translations = {
    title: {en: 'Name', es: 'Nombre'},
    label: {en: 'Set Name', es: ''},
  }

  return (
    <Card
      body={
        <SectionContainer>
          <SectionTitle>{translateObject(translations.title)}</SectionTitle>
          <Input
            style={{
              fontSize: '15px'
            }}
            inputProps={{
              onChange: handleNameChange,
              value: title,
              style:{
                paddingTop: '10px'
              }
            }}
            labelProps={{
              label: translateObject(translations.label),
            }}
            containerProps={{
              style: {
                padding: '12px 15px 10px 15px',
                height: 'fit-content'
              }
            }}
            fontBaseSize="14px"
            hoverLabelSize="12px"
          />
        </SectionContainer>
      }
    />
  );
};

export default NameSection;
