import styled from 'styled-components';
import { Text } from '../styles';

export const IconContainer = styled.div`
  width: 2rem;
  margin-top: 2px;
  margin-left: auto;
  transform: scale(1.3);
`;

export const ListItem = styled.span`
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0rem;
  align-items: flex-start;

  ${Text} {
    margin-bottom: 0px;
    position: relative;
    padding-left: 2rem;
    max-width: 90%;
    flex: 0 0 90%;
  text-align: left;

    &:before {
      content: '';
      font-weight: 600;
      position: absolute;
      left: 0px;
      top: 0px;
    }
  }

  input[type='radio']{
    display: none;

    &:checked ~ ${Text} {
      font-weight: 600;

      &:before {
        content: '\\2713';
      }
    }
  }
  
`;