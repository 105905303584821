import React, { useEffect, useRef, useState } from "react";
import { CustomCard, Label, LegendContainer } from "../../../components/charts/styles";
import Header from "../../../components/charts/components/Header";
import Loader from "../../../components/charts/components/Loader";
import useI18n from "@appsinti/i18n/useI18n";
import Translation from "@appsinti/i18n/interfaces/Translation";
import COLORS from "../../../../resources/constants/colors";

type MultipleCharts = {
  datastacks: string[]
  showLegend?: boolean
  title: Translation,
  subtitle: Translation | null
  children?: React.ReactNode;
  isLoading?: boolean
};

const MultipleCharts: React.FC<MultipleCharts> = ({
  datastacks,
  showLegend = true,
  title,
  subtitle,
  children,
  isLoading = false,
}) => {

  const { translateObject } = useI18n();
  const firstChildRef = useRef<HTMLDivElement>(null); 
  const [maxHeight, setMaxHeight] = useState('400px');

  useEffect(() => {
    if (firstChildRef.current) {
      const height = firstChildRef.current.offsetHeight; 
      setMaxHeight(`${height}px`);
    }
  }, [firstChildRef.current]);

  const childrenContainerStyle: React.CSSProperties = {
    maxHeight,
    overflowY: 'auto'
  };

  const [firstChild, ...restChildren] = React.Children.toArray(children);

  return (
    <CustomCard
      background="white"
      header={
        <Header
          title={translateObject(title)}
          subtitle={subtitle && translateObject(subtitle)}
        />
      }
      body={
        !isLoading && !!datastacks?.length ? (
          <>
            {showLegend && (
              <LegendContainer>
                {datastacks.map((label, i) => (
                  <Label
                    key={label}
                    color={COLORS[i]}
                  >
                    {label}
                  </Label>
                ))}
              </LegendContainer>
            )}
            <div style={childrenContainerStyle}>
              <div ref={firstChildRef}>{firstChild}</div>
              {restChildren}
            </div>
          </>
        ) : (
          <Loader />
        )
      }
    />
  )
}

export default MultipleCharts