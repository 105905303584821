import React, { PropsWithChildren, useState } from "react";
import { useEffect } from "react";
import IArea from "../interfaces/IArea";
import { getData, modifyStarredCameras, getCamerasFromIds } from "../services/cameras";
import IVideo from "../interfaces/IVideo";

export type DataContextType = {
  loading: boolean;
  appId: number|undefined;
  areas: IArea[];
  previews: IVideo[];
  starred: IVideo[];
  lastUpdate: Date;
  fetchData: () => Promise<void>;
  updateStarred: (ids: string[]) => void;
};

const DataContext = React.createContext<DataContextType>({
  loading: false,
  appId: undefined,
  areas: [],
  previews: [],
  starred: [],
  lastUpdate: new Date(),
  fetchData: async () => {},
  updateStarred: (ids)=> {},
});

export const useData = () => React.useContext(DataContext);

export const DataProvider: React.FC<PropsWithChildren<{
  appId: number;
}>> = ({ appId: initId, children }) => {
  const appId = initId;
  const [loading, setLoading] = useState<boolean>(false);
  const [areas, setAreas] = useState<IArea[]>([])
  const [previews, setPreviews] = useState<IVideo[]>([]);
  const [starred, setStarred] = useState<IVideo[]>([]);
  const [lastUpdate, setLastUpdate] = useState<Date>(new Date());

  const fetchData = async () => {
    setLoading(true);

    const { areas, previews, starred } = await getData(appId);
    setAreas(areas)
    setStarred(starred);
    setPreviews(previews);

    setLastUpdate(new Date());
    setLoading(false);
  };

  const updateStarred = (ids: string[]) => {
    setLoading(true);
    modifyStarredCameras(ids, appId);
    setStarred(getCamerasFromIds(ids, areas));
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, [appId]);

  return (
    <DataContext.Provider
      value={{
        loading,
        appId,
        areas,
        previews,
        starred,
        lastUpdate,
        fetchData,
        updateStarred
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
