import React from "react";
import CardTemplate from "@appsinti/ui-core/CardTemplate";
import { MetricData } from "@appsinti/ui-core/styles";
import StatsPreview from "../../../../interfaces/StatsPreview";
import { RIGHT_DARK } from "@etiquette-ui/colors";
import { formatMoneyMK } from "@appsinti/common-utils";
import { CardTitleComponent, CustomRow, MainColumn } from "../../styles";

const SalesColumn: React.FC<{ preview: StatsPreview }> = ({ preview }) => {
  return (
    <CardTemplate
      background={"white"}
      noPadding={true}
      header={
        <CustomRow>
          <CardTitleComponent title={"Sales"} />
        </CustomRow>
      }
      body={
        <CustomRow>
          <MainColumn className="main smaller">
            <div className="column">
              <MetricData size={"small"} weight={"bold"} color={RIGHT_DARK}>
                ALL DAY
              </MetricData>
              <MetricData size={"bigger"} weight={"bold"} color={RIGHT_DARK}>
                {formatMoneyMK(preview.salesData.total.totalSales)}
              </MetricData>
            </div>
            <div className="column">
              <MetricData size={"small"} weight={"bold"} color={RIGHT_DARK}>
                LUNCH
              </MetricData>
              <MetricData size={"bigger"} weight={"bold"} color={RIGHT_DARK}>
                {formatMoneyMK(preview.salesData.lunch!.totalSales)}
              </MetricData>
            </div>
            <div className="column">
              <MetricData size={"small"} weight={"bold"} color={RIGHT_DARK}>
                DINNER
              </MetricData>
              <MetricData size={"bigger"} weight={"bold"} color={RIGHT_DARK}>
                {formatMoneyMK(preview.salesData.dinner!.totalSales)}
              </MetricData>
            </div>
          </MainColumn>
        </CustomRow>
      }
    />
  );
};

export default SalesColumn;
