import React, {useState} from 'react';
import styled from 'styled-components';
import {SwitchButton as SuperSwitchButton} from '@etiquette-ui/selectors';

import { useTemperature } from '@appsinti/weather-frontend/providers/temperature';
import Temperature from '@appsinti/weather-frontend/interfaces/Temperature';

import { PRIMARY } from '@etiquette-ui/colors';

const CustomSuperSwitchButton = styled(SuperSwitchButton)<{active: boolean}>`
  width: 100%;
  margin-left: 0px;

  background-color: ${({active}) => active ? "#FFB100" : PRIMARY };

  div {
    transform: translate( ${({active}) => active ? 33 : 2  }px, 3px);
  }

`; 

const Container = styled.div`
  width: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  
  position: absolute;
  top: 1.5rem;
  right: 2rem;

  .label{
    text-transform: uppercase;
    font-size: 1.4rem;
    color: inherit;
    width: 100%;

    display: flex;
    justify-content: space-between;
    padding: 2px 3px;
    color: white;
    span {
      display: block;
    }
  }
`;

const SwitchButton: React.FC = () => {

  const { selectedTemperatureUnit, setSelectedTemperatureUnit } = useTemperature();

  const getIsActive: (unit: keyof Temperature) => boolean = (unit) => {
    return unit === 'C' ? true : false;
  }

  const changeUnit: ()=>void = () => {
    setSelectedTemperatureUnit( selectedTemperatureUnit === 'C' ? 'F' : 'C' );
  }

  return (
    <Container onClick={changeUnit}>
      <CustomSuperSwitchButton active={ getIsActive(selectedTemperatureUnit) }></CustomSuperSwitchButton>
      <div className="label">
        <span>°F</span><span>°C</span>
      </div>
    </Container>
    
  )
}

export default SwitchButton;
