import React from 'react';
import {PrimaryButton, SecondaryButton} from '@etiquette-ui/buttons';

import {MiddleWidth} from '@appsinti/ui-core/styles';
import {Container, CustomRow, Text } from '../../../components/dialogs/styles';

import useI18n from '@appsinti/i18n/useI18n';
import { RIGHT_DARK } from '@etiquette-ui/colors';

type Props = {
  onSubmit: ()=>void,
}
const Success: React.FC<Props> = ({ onSubmit }) => {
  const { translate } = useI18n();

  return (
    <Container>
      <Text color={RIGHT_DARK}>
        <strong>{translate('The upgrade was successful')}.</strong>
      </Text>
      <CustomRow>
        <MiddleWidth>
          <PrimaryButton onClick={onSubmit}>{translate('Close')}</PrimaryButton>
        </MiddleWidth>
      </CustomRow>
    </Container>
  )
}
export default Success;

