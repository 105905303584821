import { Link } from "react-router-dom";
import styled from "styled-components";

const CustomLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:hover, &:focus {
    text-decoration: none;
    outline: 0px;
  }
`;

export default CustomLink;
