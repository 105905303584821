import React from "react";

import { HeaderContainer } from "../styles";
import { EditIcon } from "@etiquette-ui/icons";
import { Link } from "react-router-dom";
import styled from "styled-components";

type Header = {
  title: string;
  subtitle?: string | React.ReactNode;
  className?: string;
  to?: string;
};

const EditContainer = styled.div`
  display: flex;
  padding: 0 2.5px;
  justify-content: end;
  align-items: center;
  flex-grow: 1;
  a { 
    display: block;
    padding: 0 2.5px;
  }
`;

const Header: React.FC<Header> = ({
  title,
  subtitle,
  className = "",
  to,
}) => {
  return (
    <HeaderContainer className={className}>
      <div className="chart-title">{title}</div>
      <div className="chart-subtitle">{subtitle}</div>
      {to &&
        <EditContainer>
          <Link to={to}>
          <EditIcon
            styles={{
              svg: {
                width: "15px",
                height: "15px",
              },
              line: {
                fill: "none",
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeWidth: "1.5px",
                stroke: "white",
              },
            }}
          />
          </Link>
        </EditContainer>
      }
    </HeaderContainer>
  );
};
export default Header;
