import { CardTitle } from '@appsinti/ui-core/styles';
import React from 'react';

const CardTitleComponent: React.FC<{ title: string }> = ({ title }) => {
    return (
      <div className="column">
        <CardTitle>{title}</CardTitle>
      </div>
    );
  };
export default CardTitleComponent;
