import React, {useState, useEffect, useMemo} from 'react';
import {useAlert, ALERT_TYPES} from '@etiquette-ui/alert-dialog'; 

import IArea from '@appsinti/bigbrother-frontend/interfaces/IArea';
import IGroupedOption, {Option} from '@appsinti/ui-core/dropdown/interfaces/IGroupOption';
import MultiGroupedDropdown from '@appsinti/ui-core/dropdown/MultiGroupedDropdown';

import {getOptions, getSelectedOptions} from '@appsinti/bigbrother-frontend/utils/index';
import ReplaceHomeCameraDialog from '../dialogs/replaceHomeCameraDialog';
import InfoDialog from '@appsinti/ui-core/dialogs/InfoDialog';
import ConfirmDialog from '@appsinti/ui-core/dialogs/ConfirmDialog';

import useI18n from '@appsinti/i18n/useI18n';
import {modifyPreviewCameras} from "../../../services/cameras";
import IVideo from '../../../interfaces/IVideo';
import { useSocket } from '../../Socket';

const ALERT_STATUS: {[key: string]: string} = {
  'low_limit': 'low_limit', 
  'high_limit': 'high_limit', 
  'delete': 'delete', 
  'add': 'add', 
  'replace': 'replace', 
};

const PreviewCamerasManager: React.FC<{
  areas: IArea[], 
  appId: number, 
  previews: IVideo[]
}> = ({areas, appId, previews}) => {

  const { translate, translateObject } = useI18n();
  const [selectedOptions, setSelectedOptions] = useState<Option[]>();
  const homeCamerasCounter: number = 2;
  const alert = useAlert();
  const {socket: videoSocket} = useSocket();

  const options: IGroupedOption[] = useMemo(()=>{
    if (!areas.length) return [];
    return getOptions(areas, translateObject);
  },[areas]);

  useEffect(() => {
    const previewsId: string[] = previews.map(preview => preview.id);
    setSelectedOptions(getSelectedOptions(options, previewsId));
  }, [previews]);

  const changeOptions = (newOptions : Option[] | undefined) => {
    if(!newOptions) return;
    
    setSelectedOptions(newOptions);
    modifyPreviewCameras(newOptions.map(option => option.value), appId);
  }

  const showDialog = (status: string, checkedOptions?: Option[], newOption?: Option) => {
    switch(status){
      case ALERT_STATUS['low_limit']:
        alert({
          type: ALERT_TYPES.CUSTOM,
          config: {
            props:{
              title: translate('Action required'),
              textBody: translate('You need to highlight other camera to replace this one'),
              closeButtonText: translate('Close')
            },
            body: InfoDialog,
          }
        })
        break;
      case ALERT_STATUS['delete']:
        alert({
          type: ALERT_TYPES.CUSTOM,
          config: {
            props:{
              title: translate('Action required'),
              textBody: translate('Click on Update to delete the camera assigned to appsinti home'),
              confirmButtonText: translate('Update'),
              closeButtonText: translate('Close'),
              onSubmit: () => changeOptions(checkedOptions),
            },
            body: ConfirmDialog,
          }
        })
        .then(() => {
          setSelectedOptions(checkedOptions);
        })
        .catch(() => {
        });
        break;
      case ALERT_STATUS['add']:
        alert({
          type: ALERT_TYPES.CUSTOM,
          config: {
            props:{
              title: translate('Action required'),
              textBody: translate('Click on Update to set the camera as assigned to appsinti home'),
              confirmButtonText: translate('Update'),
              closeButtonText: translate('Close'),
              onSubmit: () => changeOptions(checkedOptions)
            },
            body: ConfirmDialog,
          },
        })
        .then(() => {
          setSelectedOptions(checkedOptions);
        })
        .catch(() => {
        });
        break;
      case ALERT_STATUS['replace']:
        alert({
          type: ALERT_TYPES.CUSTOM,
          config: {
            props: {
              newOption,
              videoSocket,
              selectedOptions,
              setSelectedOption: changeOptions,
            },
            body: ReplaceHomeCameraDialog,
          }
        })
        break;
    }
  }

  const onChange = (checkedOptions: Option[]) => {
    
    if(checkedOptions.length === 0){
      showDialog(ALERT_STATUS['low_limit']);
      return false;
    }

    if(!!selectedOptions && checkedOptions.length === homeCamerasCounter+1) {
      const replaceOption: Option = checkedOptions.filter(option => !selectedOptions.includes(option))[0];
      showDialog(ALERT_STATUS['replace'], checkedOptions, replaceOption);
      return false;
    }

    if(!!selectedOptions && checkedOptions.length === selectedOptions.length-1 ){
      showDialog(ALERT_STATUS['delete'], checkedOptions);
      return false;
    }
    
    showDialog(ALERT_STATUS['add'],checkedOptions);
  }

  return (
    <>{ (!!options && !!selectedOptions ) && (
      <MultiGroupedDropdown 
        placeholder={ translate('Select...') }
        label={ translate('Appsinti home cameras') }
        options={options}
        value={selectedOptions}
        onChange={onChange}
      />
    )}
    </>
  )
}
export default PreviewCamerasManager;