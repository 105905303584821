import CardTemplate from "@appsinti/ui-core/CardTemplate";
import React from "react";
import { CustomRow } from "../styles";
import CardTitleComponent from "../CardTitleComponent";
import { MetricData } from "@appsinti/ui-core/styles";
import StatsPreview from "../../../interfaces/StatsPreview";

const Reservations: React.FC<{ preview: StatsPreview }> = ({ preview }) => {
  return (
    <CardTemplate
      background={"white"}
      header={
        <CustomRow>
          <CardTitleComponent title={"People"} />
          <CardTitleComponent title={"Reserve"} />
        </CustomRow>
      }
      body={
        <CustomRow>
          <div className="column">
            <MetricData size={"big"}>
              {`${preview.total.customerSeatedCount}/${preview.total.customerDoneCount + preview.total.customerSeatedCount}`}
            </MetricData>
          </div>
          <div className="column">
            <MetricData size={"big"}>
              {`${preview.total.reservationCount}r / ${preview.total.reservationPeopleCount}p`}
            </MetricData>
          </div>
        </CustomRow>
      }
    />
  );
};

export default Reservations;
