import React, {useState, useMemo} from 'react';
import styled from 'styled-components';
import { parse, format } from "date-fns";
import useI18n from '@appsinti/i18n/useI18n';
import { PRIMARY, WRONG_LIGHT } from "@etiquette-ui/colors";
import { Calendar } from "@etiquette-ui/calendars";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Row = styled.div`
  max-width: 100%;
  flex: 0 0 100%;
  padding: 0px;
  display: flex;
  padding: 0px 5px;
`;

const DateBlock = styled.div`
  max-width: 50%;
  flex: 0 0 50%;
  padding: 0px 5px;
  text-align: left;
`;

const Label = styled.p`
  font: normal normal normal 14px/18px Montserrat;
  text-align: left;
  margin: auto;
  margin-left: 0px;
`;

const Input = styled.span`
  font: normal normal normal 14px/18px Montserrat;
  color: ${PRIMARY};
  font-weight: 600;
  text-align: right;
`;

const CalendarContainer = styled.div<{isOpen?:boolean}>`
  max-width: 100%;
  flex: 0 0 100%;
  top: 100%;
  left: 0px;
  right: 0px;
  display: flex;
  position: relative;
  justify-content: center;
  background: white;
  overflow: hidden;
  transition: height .5s ease;
  height: ${({isOpen}) => !!isOpen ? '270px' : '0px'};

  @media (min-width: 768px) {
    height: ${({isOpen}) => !!isOpen ? '290px' : '0px'};
  }
`;

const Error = styled.div`
  padding: 5px;
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: -5px;
  font-weight: 600;
  font-size: 1.3rem;
  color: ${WRONG_LIGHT};
`;

const ToggleRangeCalendar: React.FC<{
  dates: {startDate: string, endDate: string};
  setDates: (startDate: Date, endDate: Date) => void;
  dateFormat?: string;
  error?: string;
  disabledRestrictedSpan?: boolean;
}>  = ({dates, setDates, dateFormat = 'MMMM dd, yyyy', error = '', disabledRestrictedSpan=true}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [localDates, setLocalDates] = useState<Date[]>([parse(dates.startDate, 'yyyy-MM-dd', new Date()), parse(dates.endDate, 'yyyy-MM-dd', new Date())]);
  const {translateObject, selectedLang} = useI18n();

  const onChange = (dates: Date[]) => {
    setLocalDates(dates);    
    if(!!dates[0] && !!dates[1]) setDates(dates[0], dates[1]);
  }

  const dateLimits = useMemo(()=>{
    let currentYear = localDates[0].getFullYear();
    return ({start: new Date (currentYear, 0, 1), end: new Date(currentYear, 11, 31)});
  },[localDates]);
  
  return (
    <Container>
      <Row onClick={()=>setIsOpen(!isOpen)}>
        <DateBlock>
          <Label>{translateObject({en: 'Starts', es: 'Inicio'})}</Label>
          <Input>{format(localDates[0], dateFormat) ?? ''}</Input>
        </DateBlock>
        <DateBlock>
          <Label>{translateObject({en: 'Ends', es: 'Fin'})}</Label>
          <Input>{!!localDates[1] ? format(localDates[1], dateFormat) : ''}</Input>
        </DateBlock>        
      </Row>
      {!!error && (<Row><Error>{error}</Error></Row>)}
      <CalendarContainer isOpen={isOpen}>
        <Calendar
          language={selectedLang}
          selectsRange={true}
          startDate={localDates[0]}
          endDate={localDates[1]}
          formatDate={"MMMM dd"}
          onChange={(dates) => onChange(dates)}
          containerProps={{
            style: {              
              paddingTop: '10px',
            }
          }}
          minDate={disabledRestrictedSpan ? dateLimits.start : undefined}
          maxDate={disabledRestrictedSpan ? dateLimits.end : undefined}
        />
      </CalendarContainer>      
    </Container>
  )
}
export default ToggleRangeCalendar;