
import { useContext } from 'react'
import { I18nContext, I18nContextType } from './provider';
import { Language } from './interfaces/Language';

const useI18n: () => I18nContextType = () => {
    const context = useContext(I18nContext);

    return context;
}


export default useI18n;