import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {BG} from '@etiquette-ui/colors';

import MainHeaderApp from '@appsinti/ui-core/header';

import Header from './components/Header';
import Accordion from './components/accordion';

import {APP_PUBLIC_NAME} from '@appsinti/bigbrother-frontend/constants/config';
import useI18n from '@appsinti/i18n/useI18n';
import SocketProvider from './Socket';
import { DataProvider } from './data';

const Container = styled.div`
  background-color: ${BG};
`;

const Body = styled.div`
  padding: 0px .5rem;
  padding-bottom: 2rem;
  min-height: 79vh;
`;

const Home: React.FC<{ appId: number, groupId: number | null }> = ({ appId, groupId }) => {
    const {translateObject} = useI18n();

    return (
        <SocketProvider appId={appId}>
            <DataProvider appId={appId}>
                <MainHeaderApp title={translateObject(APP_PUBLIC_NAME)} backButtonLocation={groupId ? `/${groupId}` : '..'} />
                <Container>
                    <Header/>
                    <Body>
                        <Accordion />
                    </Body>
                </Container>
            </DataProvider>
        </SocketProvider>
    )
}

export default Home;
