import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import Container from "@appsinti/ui-core/header/Container";
import { getToday } from "@appsinti/ui-core/dateAndTime/utils";

import useI18n from "@appsinti/i18n/useI18n";
import { ShowMenu } from "pages/home/components/ShowMenu";
import Icon from "@etiquette-ui/icons";
import { useUpdate } from "@appsinti/trigger-update";
import { Ring } from "@uiball/loaders";
import { PRIMARY } from "@etiquette-ui/colors";

const DISPLAY_DATE_FORMAT = "iiii MMMM dd, yyyy";

const Date = styled.div`
  flex: 0 0 60%;
  font-size: 1.2rem;
  text-align: center;
`;

const Header: React.FC<{
  setHeaderHeight: React.Dispatch<React.SetStateAction<number | undefined>>;
  headerHeight: number | undefined;
}> = ({ setHeaderHeight, headerHeight }) => {
  const { selectedLang } = useI18n();
  const containerRef = useRef<HTMLHeadElement>(null);
  const { triggerUpdate, loading } = useUpdate();

  useEffect(() => {
    setHeaderHeight(containerRef.current?.clientHeight);
  }, [containerRef.current?.clientHeight]);

  return (
    <Container ref={containerRef}>
      <div style={{ flex: "0 0 20%" }} onClick={triggerUpdate}>
        {loading ? (
          <Ring color={PRIMARY} size={20} />
        ) : (
          <Icon
            icon="update"
            styles={{
              svg: { height: "20px", width: "20px" },
            }}
          />
        )}
      </div>
      <Date>{getToday(selectedLang, DISPLAY_DATE_FORMAT)}</Date>
      <ShowMenu />
    </Container>
  );
};

export default Header;
