import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  PropsWithChildren,
} from "react";

interface IMetronomeContext {
  registerCallback: (callback: Function, args?: any[]) => string;
  unregisterCallback: (key: string) => void;
}

const MetronomeContext = createContext<IMetronomeContext | undefined>(
  undefined
);

export const MetronomeProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [callbacks, setCallbacks] = useState<{
    [key: string]: { callback: Function; args: any[] };
  }>({});
  const callbacksRef = useRef(callbacks);
  const idCounterRef = useRef(0);

  const registerCallback = (callback: Function, args: any[] = []) => {
    const key = `callback-${idCounterRef.current++}`;
    callbacksRef.current[key] = { callback, args };
    setCallbacks(callbacksRef.current);
    return key;
  };

  const unregisterCallback = (key: string) => {
    delete callbacksRef.current[key];
    setCallbacks(callbacksRef.current);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      Object.values(callbacks).forEach(({ callback, args }) =>
        callback(...args)
      );
    }, 1000);

    return () => clearInterval(interval);
  }, [callbacks]);

  return (
    <MetronomeContext.Provider value={{ registerCallback, unregisterCallback }}>
      {children}
    </MetronomeContext.Provider>
  );
};

export const useMetronome = (): IMetronomeContext => {
  const context = useContext(MetronomeContext);
  if (context === undefined) {
    throw new Error("useMetronome must be used within a MetronomeProvider");
  }
  return context;
};

export default MetronomeProvider;
