import { BG } from "@etiquette-ui/colors";
import styled from "styled-components";

export const DateContainer = styled.div`
  font-weight: normal;
  display: flex;
  grid-gap: 1rem;
`;

export const Updater = styled.div`
  font-weight: 450;
  text-align: right;

  justify-content: end;
  grid-gap: 1rem;
`;

export const SelectorContainer = styled.div`
  max-width: 100%;
  flex: 0 0 100%;
  margin-top: .5rem;
`;

export const HeaderContainer = styled.div`
  position: sticky;
  top: 5.6rem;
  right: 0px;
  left: 0px;
  z-index: 20;

  padding: 0rem 1.5rem;
  padding-bottom: .7rem;
  margin-bottom: .8rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  background-color: ${BG};

  ${Updater}, ${DateContainer}{
    color: white;
    font-size: 1.2rem;
    padding: .5rem 0px;
    max-width: 50%;
    flex: 0 0 50%;

    display: flex;
    align-items: center;
  }
`;