import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import StatsPreview from "../interfaces/StatsPreview";
import { BACKEND } from "../resources/constants/urls";
import { useUpdate } from "@appsinti/trigger-update";
import { getPreviewMirror, updatePreviewMirror } from "../../../preview-mirror";

export type DataContextType = {
  loading: boolean;
  lastUpdate: Date;
  preview: StatsPreview | null,
  config: {
    appId: number;
  };
};

const DataContext = React.createContext<DataContextType>({
  loading: false,
  preview: null,
  lastUpdate: new Date(),
  config: {
    appId: 0,
  },
});

export const usePreviewData = () => React.useContext(DataContext);

export const PreviewDataProvider: React.FC<{
  appId: number;
  children: any;
}> = ({ appId, children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [preview, setPreview] = useState<StatsPreview | null>(null);
  const [lastUpdate, setLastUpdate] = useState<Date>(new Date());
  const { update, addLoadingState, resolveLoadingState } = useUpdate();

  const getLocalPreview = () => {    
    const preview = getPreviewMirror(appId);
    if(!preview) fetchData();
    else  {
      setPreview(JSON.parse(preview) as StatsPreview);
      setLoading(false);
      resolveLoadingState('stat-frontend');
    }
  }

  const fetchData = async () => {
    const current = await axios.get<StatsPreview>(`${BACKEND}/appsinti/stats/preview`, { headers: { "app-id": appId } });
    updatePreviewMirror(JSON.stringify(current.data), appId);
    getLocalPreview();
    setLastUpdate(new Date());
  };

  useEffect(() => {
    getLocalPreview();
  }, []);

  useEffect(() => {
    if (!appId) return;
    if(update){
      setLoading(true);
      addLoadingState('stat-frontend');
    }
    fetchData();
  }, [appId, update]);

  return (
    <DataContext.Provider
      value={{
        loading,
        preview,
        lastUpdate,
        config: { appId },
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
