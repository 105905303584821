import React from "react";
import styled from "styled-components";

import Icon from "./Icon";

const Body = styled.div<{ isColumn: boolean; isActive?: boolean }>`
  display: flex;
  flex-direction: ${({ isColumn }) => (isColumn ? "column" : "row")};

  max-width: 100%;

  gap: ${({ isColumn }) => (isColumn ? "5px" : "0")};

  .icon-container {
    max-width: 2.2rem;
    flex: 0 0 2.2rem;
    height: auto;
    margin: ${({ isColumn }) => (isColumn ? ".5rem" : "0px")} auto;
  }

  .name,
  .temperature {
    font-size: inherit;

    max-width: ${({ isColumn }) => (isColumn ? "100%" : "40%")};
    flex: 0 0 ${({ isColumn }) => (isColumn ? "" : "40%")};
  }

  .name {
    text-align: ${({ isColumn }) => (isColumn ? "center" : "left")};
    font-weight: ${({ isActive }) => (isActive ? 600 : 400)};
  }

  .temperature {
    text-align: ${({ isColumn }) => (isColumn ? "center" : "right")};
    font-weight: ${({ isActive, isColumn }) =>
      isActive || isColumn ? 600 : 400};
  }
`;

const Item: React.FC<{
  title: string;
  temperature: string;
  icon: string;
  isColumn?: boolean;
  isActive?: boolean;
}> = ({ title, temperature, icon, isColumn = false, isActive = false }) => {
  return (
    <Body isColumn={isColumn} isActive={isActive}>
      <div className="name">{title}</div>
      <div className="icon-container">
        <Icon name={icon} />
      </div>
      <div className="temperature">{temperature}</div>
    </Body>
  );
};

export default Item;
