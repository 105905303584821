import { LanguageIcon } from '@etiquette-ui/icons';
import { format as dateFormat, add, subDays } from 'date-fns';
import { enUS, es } from 'date-fns/locale';
export const DATE_FORMAT = 'yyyy-MM-dd';
export const ALT_DATE_FORMAT = 'dd/MM/yy';
export const ALT_DATE_FORMAT_FULL_YEAR = 'dd/MM/yyyy';
export const TIME_FORMAT = 'hh:mm:ss';
export const DISPLAY_DATE_FORMAT = 'iiii, d MMMM yyyy';//Tuesday, 11 January 2021
export const ALT_DISPLAY_DATE_FORMAT = "MMM d''yy h:mm a";//May 12’21 1:00 p.m.
export const DISPLAY_TIME_FORMAT = 'h : mm a';
export const DISPLAY_DAY_FORMAT = 'iiii';//Tuesday, 11 January 2021

const config = {
  locale: enUS
}

export const setLanguage = (lang:string) => {
  switch(lang){
    case 'en': 
      return ({locale: enUS});
    case 'es': 
      return ({locale: es})
  }
}

export const stringToDate = (dateString: string) => {
    const DATE_REGEX = /\d{4}-\d{2}-\d{2}/;

    if (DATE_REGEX.test(dateString) === false) {
        throw new Error(`Date format is not correct for date: ${dateString}`)
    }

    const [y, m, d] = dateString.split('-')

    return new Date(+y, +m - 1, Number(d));
}

export const getToday = (lang:string = 'en', format = DATE_FORMAT) => {
    // const today = dateFormat(new Date('2021-07-06T00:00:00'), format, config); //Temp line for testing a tuesday
    const today = dateFormat(new Date(), format, setLanguage(lang));

    return capitalize(today)
}

export const getTodayNameDay = (lang:string = 'en', format = DISPLAY_DAY_FORMAT) => {
  // const today = dateFormat(new Date('2021-07-06T00:00:00'), format, config); //Temp line for testing a tuesday
  const today = dateFormat(new Date(), format, setLanguage(lang));

  return capitalize(today)
}

const capitalize = (string:string) => string.split(" ").map(item => item.charAt(0).toLocaleUpperCase() + item.slice(1)).join(' ')

export const getCurrentTime = () => dateFormat(new Date(), DATE_FORMAT, config);

export const getFormattedDate = (date: string, format = DATE_FORMAT) => {
    const dateFormatted = dateFormat(new Date(date), format, config);

    return capitalize(dateFormatted);
}

export const getFormattedTime = (date: string, format = TIME_FORMAT) => {
    const dateFormatted = dateFormat(new Date(date), format);

    return capitalize(dateFormatted);
}

export const getDisplayDate = (date:string, format = DISPLAY_DATE_FORMAT, selectedLang = 0) => {
    const dateFormatted = dateFormat(new Date(date), format, {
        locale: selectedLang ? enUS : es
    });

    return capitalize(dateFormatted);
}

export const getDisplayTime = (date:string, format = DISPLAY_TIME_FORMAT) => {
    const timeFormatted = dateFormat(new Date(date), format);

    return capitalize(timeFormatted);
}
export const extractTime = (time:string) => (dateFormat(new Date(time), 'HHmm'));
export const extractTimeHH = (time:string) => (dateFormat(new Date(time), 'HH'));
export const extractTimemm = (time:string) => (dateFormat(new Date(time), 'mm'));

export const extractYearDay = (time:string) => (dateFormat(new Date(time), 'd MMMM yyyy'));

export const getMonthInterval = (date = null) => {
    const today = !!date ? new Date(date) : new Date();

    const start = subDays(new Date(`${dateFormat(today, 'yyyy-MM')}-01`), 6);
    const end = new Date(`${dateFormat(add(today, { months: 1 }), 'yyyy-MM')}-06`);

    return { start: dateFormat(start, DATE_FORMAT), end: dateFormat(end, DATE_FORMAT) };
}

export const removeDays = (date:string, days:number) => {
    const dateObj = !!date ? new Date(date) : new Date();
    return subDays(dateObj, days);
}

export const addDays = (date:string, days:number) => {
    const dateObj = !!date ? new Date(date) : new Date();
    return add(dateObj, { days });
}

export const addMonths = (date:string, months:number) => {
    const dateObj = !!date ? new Date(date) : new Date();
    return add(dateObj, { months });
}