export const EventEmitter = {
    events: {},
    dispatch: function(event: string, data: any) {
        if (!this.events[event]) return
        this.events[event].forEach(callback => callback(data))
    },
    subscribe: function(event: string, callback: any) {
        if (!this.events[event]) this.events[event] = []
        this.events[event].push(callback)
    }
}

