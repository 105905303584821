import React from "react";
import { daysOfWeek, monthNames, months, weekModifiers } from '../../../../../../../../resources/constants/dates';
import SelectOption from "../../../../../../../../interfaces/SelectOption";
import { DayOfWeek, WeekModifier } from "@appsinti/stats-utils/interfaces/SpecialDaysConfig";
import styled from 'styled-components';
import useI18n from "@appsinti/i18n/useI18n";
import SpecialDropdown from "../../../../../components/SpecialDropdown";

const Label = styled.p`
  font: normal normal normal 14px/18px Montserrat;
  text-align: left;
  margin: auto;
  margin-left: 0px;
`;

const DropdownContainer = styled.div`
  max-width: 50%;
  width: fit-content;
  display: flex;
  align-items: center;
`;

const DropdownRow = styled.div`
  width: 100%;
  padding: 0px 5px;
  display: flex;
  justify-content: space-between;
`;

const translations = {
  the: {en: 'The', es: 'El'},
  day: {en: 'Day', es: 'Día'},
  month: {en: 'Month', es: 'Mes'},
  of: {en: 'of', es: 'de'}
}

const PatternDateSection: React.FC<{
  weekModifier: SelectOption<WeekModifier>;
  dayOfWeek: SelectOption<DayOfWeek>;
  month: SelectOption<number>;
  setMonth: (month: SelectOption<number>) => void;
  setDayOfWeek: (dayOfWeek: SelectOption<DayOfWeek>) => void;
  setWeekModifier: (weekModifier: SelectOption<WeekModifier>) => void;
}> = ({ weekModifier, dayOfWeek, month, setMonth, setDayOfWeek, setWeekModifier }) => {

  const {translateObject, selectedLang} = useI18n();
  
  return (
    <>
      <DropdownRow>
        <Label>{translateObject(translations.the)}</Label>
        <DropdownContainer>
          <SpecialDropdown  
            value={weekModifier}
            options={weekModifiers(selectedLang)}
            onChange={setWeekModifier}
          />
        </DropdownContainer>        
      </DropdownRow>
      
      <DropdownRow>
        <Label>{translateObject(translations.day)}</Label>
        <DropdownContainer>
          <SpecialDropdown  
            value={dayOfWeek}
            options={daysOfWeek(selectedLang)}
            onChange={setDayOfWeek}
          />
        </DropdownContainer>
      </DropdownRow>
      <DropdownRow>
        <Label>{translateObject(translations.month)}</Label>
        <DropdownContainer>
          <Label style={{marginRight: '5px'}}>{translateObject(translations.of)}</Label>
          <SpecialDropdown  
            value={month}
            options={months(selectedLang)}
            onChange={setMonth}
          />
        </DropdownContainer>
      </DropdownRow>
    </>
  );
};
export default PatternDateSection;