import { useUpdate } from "@appsinti/trigger-update";
import { PRIMARY } from "@etiquette-ui/colors";
import { Ring } from "@uiball/loaders";
import React from "react";
import styled from "styled-components";

const Button = styled.button`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  border: none;
  outline: none;
  background-color: white;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 30px;
  height: 30px;
`;

const UpdateAllButton: React.FC = () => {
  const { triggerUpdate, loading } = useUpdate();

  return (
    <Button onClick={triggerUpdate}>
      {loading ? <Ring color={PRIMARY} size={30} /> : <Logo src={`${process.env.PUBLIC_URL}/logo512.png`}/>}
    </Button>
  );
};

export default UpdateAllButton;
