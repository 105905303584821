import styled from 'styled-components';
import { Input as InputEtiquette } from '@etiquette-ui/inputs-dropdowns';

const Input = styled(InputEtiquette)`

  height: 4.5rem;
  margin-bottom: 1.6rem;

  input, label {
    padding-left: 0px;
  }
  
  label {
    font-size: 1.2rem;
    top: .6rem;
  }

  p {
    bottom: -2.3rem;
    font-size: 1.1rem;
  }

  input {
    font-size: 1.4rem;

    &:hover, &:active, &:focus {
      + label {
        font-size: 1rem!important;
      }
    }
  }
`;
export default Input;