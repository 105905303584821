import styled from 'styled-components';

export const Container = styled.div`
  max-width: 100%;
  position: relative;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const FullWidth = styled.div`
  max-width: 100%;
  flex: 0 0 100%;
`;

export const MiddleWidth = styled.div`
  max-width: 50%;
  flex: 0 0 50%;
`;

export const CardTitle = styled.span<{size?: string}>`

  display: block;
  text-align: center;
  line-height: 1;

  font-size: ${({size}) => { switch(size) {
    case 'small': return 8;
    case 'medium': return 10;
    case 'big': return 12;
    case 'bigger': default: return 14;
  } }}px;

  font-weight: 500;
  color: white;

  @media screen and (max-width: 380px){
    font-size: ${({size}) => { switch(size) {
      case 'small': return 6;
      case 'medium': return 8;
      case 'big': return 10;
      case 'bigger': default: return 12;
    } }}px;
  }

`;

export const BottomTitle = styled.span`
  display: block;
  text-align: center;
  font-weight: 600;
  font-size: 6px;
  color: black;
`;

export const MetricData = styled.span<{size?: string, weight?: string, color?: string}>`

  display: block;
  font-weight: 600;
  text-align: center;
  line-height: 1;

  font-weight: ${({weight}) => { switch(weight) {
    case 'bold': return 800;
    case 'light': return 400;
    case 'normal': default: return 600;
  } }};

  font-size: ${({size}) => { switch(size) {
    case 'biggest': return 21;
    case 'bigger': return 17;
    case 'big': return 15;
    case 'medium': return 13;
    case 'small': return 11;
    case 'smaller': default: return 9;
  } }}px;

@media screen and (max-width: 380px){
    font-size: ${({size}) => { switch(size) {
      case 'biggest': return 19;
      case 'bigger': return 15;
      case 'big': return 13;
      case 'medium': return 11;
      case 'small': return 9;
      case 'smaller': default: return 7;
    } }}px;
  }

@media screen and (max-height: 750px){
    font-size: ${({size}) => { switch(size) {
      case 'biggest': return 19;
      case 'bigger': return 15;
      case 'big': return 13;
      case 'medium': return 11;
      case 'small': return 9;
      case 'smaller': default: return 7;
    } }}px;
  }
@media screen and (max-height: 710px){
    font-size: ${({size}) => { switch(size) {
      case 'biggest': return 17;
      case 'bigger': return 13;
      case 'big': return 11;
      case 'medium': return 9;
      case 'small': return 7;
      case 'smaller': default: return 5;
    } }}px;
  }

  color: ${({color}) => color ? color : 'black'};
`;
