import React from 'react';
import { PrimaryButton} from '@etiquette-ui/buttons';
import { WRONG_LIGHT, RIGHT_DARK   } from '@etiquette-ui/colors';

import {MiddleWidth} from '@appsinti/ui-core/styles';
import {Container, CustomRow, Text } from '../../sytles';

import useI18n from '@appsinti/i18n/useI18n';

type Props = {
  onClose: ()=>void, 
  isSuccess?: boolean
}
const Completed: React.FC<Props> = ({onClose, isSuccess = false, setTitle, newName, id }) => {
  const { translate } = useI18n();

  if (isSuccess) {
      setTitle(id, newName)
  }

  return (
    <Container>
      <Text color={isSuccess ? RIGHT_DARK : WRONG_LIGHT}>
        <strong>{`${isSuccess ? translate('The upgrade was successful') : translate('The upgrade failed, try again later')}`}.</strong>
      </Text>
      <CustomRow>
        <MiddleWidth>
          <PrimaryButton onClick={onClose}>{translate('Close')}</PrimaryButton>
        </MiddleWidth>
      </CustomRow>
    </Container>
  )
}
export default Completed;

