import {
  DayOfWeek,
  WeekModifier,
} from "@appsinti/stats-utils/src/interfaces/SpecialDaysConfig";
import SelectOption from "../../../../../../../interfaces/SelectOption";
import { SpecialDayState } from "./specialDayReducer";
import { parse } from "date-fns";
import { DayOfWeekValues, WeekModifierValues } from "@appsinti/stats-utils/src/interfaces/SpecialDateValues";

export function validateTitle(title: string): boolean {
  return title !== "" && title !== null && title !== undefined;
}

export function validateFixedDate(date: string): boolean {
    let valid: boolean;
    try {
        parse(date, "yyyy-MM-dd", new Date());
        valid = true;
    } catch (e) {
        console.error(e);
        valid = false;
    }

  return valid;
}

export function validatePatternDate(
  month: SelectOption<number>,
  weekModifier: SelectOption<WeekModifier>,
  dayOfWeek: SelectOption<DayOfWeek>
): boolean {
    const validMonth = month.value < 13 && month.value > 0;
    const validWeekModifier = Object.values(WeekModifierValues).includes(weekModifier.value);
    const validDayOfWeek = Object.values(DayOfWeekValues).includes(dayOfWeek.value);

    return validMonth && validWeekModifier && validDayOfWeek;
}

export function validateCustomDate(dates: { [key: string]: string;} ): boolean {
    let valid = true;

    for(const date of Object.values(dates)) {
        if(!validateFixedDate(date)) {
            valid = false;
        }
    }

    return valid;
}

function validateDate(state: SpecialDayState): boolean {
  const { selectedType, patternDay, customDates, fixedDay } = state;

  if (selectedType === "date") {
    return validateFixedDate(fixedDay.date);
  }

  if (selectedType === "pattern") {
    return validatePatternDate(patternDay.month, patternDay.weekModifier, patternDay.dayOfWeek);
  }

  if (selectedType === "custom") {
    return validateCustomDate(customDates);
  }

  return false;
}

function validateForm(state: SpecialDayState): boolean {
  const titleValid = validateTitle(state.title);
  const dateValid = validateDate(state);

  return titleValid && dateValid;
}

export default validateForm;
