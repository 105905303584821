import React from "react";
import StatsPreview from "../../interfaces/StatsPreview";
import FullWidthHalfHeight from "./configurations/FullWidthHalfHeight";
import FullWidthThirdHeight from "./configurations/FullWidthThirdHeight";
import HalfWidthHalfHeight from "./configurations/HalfWidthHalfHeight";
import HalfWidthThirdHeight from "./configurations/HalfWidthThirdHeight";
import {
  HeightRestriction,
  WidthRestriction,
} from "../../interfaces/Restrictions";

const Section: React.FC<{
  preview: StatsPreview;
  heightRestriction: HeightRestriction;
  widthRestriction: WidthRestriction;
}> = ({ preview, heightRestriction, widthRestriction }) => {
  switch (widthRestriction) {
    case "full-width":
      if (heightRestriction === "half")
        return <FullWidthHalfHeight preview={preview} />;
      if (heightRestriction === "third")
        return <FullWidthThirdHeight preview={preview} />;
      return null;
    case "half-width":
      if (heightRestriction === "half")
        return <HalfWidthHalfHeight preview={preview} />;
      if (heightRestriction === "third")
        return <HalfWidthThirdHeight preview={preview} />;
      return null;
    default:
      return null;
  }
};

export default Section;
