import CardTemplate from "@appsinti/ui-core/CardTemplate";
import React from "react";
import { CustomRow } from "../styles";
import CardTitleComponent from "../CardTitleComponent";
import { MetricData } from "@appsinti/ui-core/styles";
import { RIGHT_DARK } from "@etiquette-ui/colors";
import { formatMoney } from "@appsinti/common-utils";
import { CompleteStats, SalesData } from "../../../interfaces/StatsPreview";
import styled from "styled-components";

const MainColumn = styled.div`
  &.main {
    display: flex;
    flex-direction: row;
    gap: 10px;

    @media screen and (max-width: 380px) {
      gap: 5px;
    }
  }
`;

const SalesGuestSplits: React.FC<{
  total: CompleteStats;
  lunch: SalesData;
  dinner: SalesData;
}> = ({ total, lunch, dinner }) => {
  return (
    <CardTemplate
      background={"white"}
      header={
        <CustomRow>
          <CardTitleComponent title={"Sales/Guest"} />
        </CustomRow>
      }
      body={
        <CustomRow>
          <MainColumn className="main">
            <div className="column">
              <MetricData size={"medium"} weight={"bold"} color={RIGHT_DARK}>
                ALL DAY
              </MetricData>
              <MetricData size={"biggest"} weight={"bold"} color={RIGHT_DARK}>
                {formatMoney(total.salesPerGuest, 0)}
              </MetricData>
            </div>
            <div className="column">
              <MetricData size={"medium"} weight={"bold"} color={RIGHT_DARK}>
                LUNCH
              </MetricData>
              <MetricData size={"biggest"} weight={"bold"} color={RIGHT_DARK}>
                {formatMoney(lunch.salesPerGuest, 0)}
              </MetricData>
            </div>
            <div className="column">
              <MetricData size={"medium"} weight={"bold"} color={RIGHT_DARK}>
                DINNER
              </MetricData>
              <MetricData size={"biggest"} weight={"bold"} color={RIGHT_DARK}>
                {formatMoney(dinner.salesPerGuest, 0)}
              </MetricData>
            </div>
          </MainColumn>
        </CustomRow>
      }
    />
  );
};

export default SalesGuestSplits;
