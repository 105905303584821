import React, { useEffect } from 'react';
import { ACCESS_USER_TYPES } from 'resources/constants/config';
import styled from 'styled-components';

const Window = styled.iframe`
    width: 100vw;
    height: 100vh;
    border: none;
`;

const Login:React.FC = () => {
    useEffect(() => {
      const loginFrame = document.getElementById('loginframe') as HTMLIFrameElement;
      loginFrame.addEventListener('load', onLoad);
    }, []);

    const onLoad = () => {
        const loginFrame = document.getElementById('loginframe') as HTMLIFrameElement;
        if(!!loginFrame && loginFrame.contentWindow){
            loginFrame.contentWindow.postMessage('getData', process.env.REACT_APP_AUTH_URL ?? '');

            window.addEventListener('message', e => {
                if (typeof e.data !== 'string' || !e.data.startsWith('data:')) return;
                const toStore = e.data.slice(5);
                if (toStore !== "") {

                    const parts = toStore.split('|');
                    parts.pop();
                    const data = parts.join('|');
                    const parsed = JSON.parse(data);

                    if (parsed.ofType === ACCESS_USER_TYPES.GLOBAL || parsed.ofType === ACCESS_USER_TYPES.ROOT) {
                        localStorage.setItem('auth', data);
                        window.location.href = '/';
                      } else {
                        alert("You don't have access to this app");
                      }
                }
            }, false);
        }
    }

    return (
        <Window id="loginframe" title="login" src={process.env.REACT_APP_AUTH_URL}></Window >
    );
}
export default Login;