import React from "react";
import MainCard from "@appsinti/ui-core/MainCard";
import DashboardPreviewConfig from "./interfaces/DashboardPreviewConfig";
import styled from "styled-components";

const Window = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const Body: React.FC<{ url: string }> = ({ url }) => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Window title="dashboard" src={url}></Window>
      <Overlay />
    </div>
  );
};

const Preview: React.FC<{ appId: number; config: DashboardPreviewConfig }> = ({
  appId,
  config,
}) => {
  return <MainCard title={"Dasboard"} body={<Body url={config.url} />} />;
};

export default Preview;
