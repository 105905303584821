import React from 'react';
import styled from 'styled-components';

import CloudIcon from '../../resources/icons/cloud-icon.svg';
import BrokenClouds from '../../resources/icons/broken-clouds-day-icon.svg';
import ClearSky from '../../resources/icons/clear-sky-day-icon.svg';
import FewClouds from '../../resources/icons/few-clouds-day-icon.svg';
import Mist from '../../resources/icons/mist-day-icon.svg';
import Rain from '../../resources/icons/rain-day-icon.svg';
import ScatteredClouds from '../../resources/icons/scattered-clouds-day-icon.svg';
import ShowerRain from '../../resources/icons/shower-rain-day-icon.svg';
import Snow from '../../resources/icons/snow-day-icon.svg';
import Thunderstorm from '../../resources/icons/thunderstorm-day-icon.svg';

const IconTranslate = {
  'broken_clouds': BrokenClouds,
  'clear_sky': ClearSky,
  'few_clouds': FewClouds,
  'mist': Mist,
  'rain': Rain,
  'scattered_clouds': ScatteredClouds,
  'shower_rain': ShowerRain,
  'snow': Snow,
  'thunderstorm': Thunderstorm,
}

const Image = styled.img`
  max-width: 100%;
  width: 100%;
  height: auto;
`;

const Icon: React.FC<{name: string}> = ({name}) => {
  return (
    <Image src={IconTranslate[name] ?? CloudIcon}/>
  )
}

export default Icon;
