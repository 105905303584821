 export const DayOfWeekValues = {
  Sunday: "sunday",
  Monday: "monday",
  Tuesday: "tuesday",
  Wednesday: "wednesday",
  Thursday: "thursday",
  Friday: "friday",
  Saturday: "saturday",
};

export const WeekModifierValues = {
  First: "first",
  Second: "second",
  Third: "third",
  Fourth: "fourth",
  Fifth: "fifth",
  Last: "last",
};

export const OffsetDirectionValues = {
  Before: "before",
  After: "after",
};
