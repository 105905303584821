import styled from 'styled-components';
import { BG_DARK  } from '@etiquette-ui/colors';

import { IconContainer } from '../../styles';

export const Container = styled.div`
  background-color: white;
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 10px;
`;
export const Header = styled.div`
  position: sticky;
  top: 0px;
  left: 0px;
  right: 0px;
  
  padding: 1.2rem 1rem;
  border-radius: 0px 0px .8rem .8rem;
  background-color: ${BG_DARK};

  display: flex;

  ${IconContainer}.--is-right {
    margin-left: auto;
  }
  width: 100%;
`;
export const Body = styled.div`
  margin-top: -2rem;
  padding: 1rem;
  padding-top: 3rem;
  border-radius: 12px;
  background-color: white;
`;
export const Title = styled.h3`
  margin: 0px;
  font-size: 1.4rem;  
  font-weight: 600;
  color: white;
`;
