import styled from "styled-components";
import { INPUTS, PRIMARY } from '@etiquette-ui/colors';

export const GroupHeader = styled.div`
  display: 'flex';
  align-items: 'center';
  justify-content: 'space-between';
  text-transform: capitalize;
  font-size: 1.4rem;
  font-weight: 600;
  color: black;
`;

export const Label = styled.label`
  font-weight: 500;
  position: absolute;
  padding-left: 0px;
  color: ${PRIMARY};
  pointer-events: none;
  left: 10px;
  top: -13px;
  font-size: 1.2rem;
  pointer-events: none;
`;
export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${INPUTS};
  border-radius: 8px;
  padding: 0.8rem 1rem;
  position: relative;
  border: solid 1px transparent;

  &.--active {
  border: solid 1px ${PRIMARY};
  }

  &:hover, &:active {
  border: solid 1px ${PRIMARY};
  }
`;

export const InputContainer = styled.div`
  height: 100%;
  position: relative;
  flex: 1;

  &.--input-with-label {
    padding-top: 1rem;
  }

  .input {
    display: flex;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 1.4rem;
    background-color: transparent;
    transition: padding-top 0.15s cubic-bezier(0.17, 0.04, 0.03, 1);
  }
`;

export const IconContainer = styled.div<{isCollapsable?:boolean}>`
  height: 100%;
  width: 25px;
  position: absolute;

  top: 0px;
  bottom: 0px;
  right: 0px;

  display: flex;
  align-items: center;

  pointer-events: none;

  transform: ${({isCollapsable})=> isCollapsable ? "scaleY(-1)" : "scaleY(1)"};
  transition-duration: .3s;
`;

