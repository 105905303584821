import React, { useEffect, useState, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { BG_DARK } from '@etiquette-ui/colors';

import useI18n from '@appsinti/i18n/useI18n';
import { useMetronome } from '@appsinti/metronome';

const Container = styled.div`
  width: 100%;
  height: 100%;
  
  border-radius: 0px 0px 8px 8px;

  display: flex;
  flex-direction: column;

  background-color: #565C6A;
`;

const Header = styled.header`
  display: flex;
  padding: 3px 12px 4px 12px;
  border-radius: 0px 0px 8px 8px;
  background-color: ${BG_DARK};
  min-height: 20px;
  justify-content: space-between;
`;

const Title = styled.div`
  max-width: 45%;
  flex: 0 0 45%;
  line-height: 1;
  font-size: 14px;
  font-weight: 600;
  color: white;
  @media screen and (max-width: 380px){
    font-size: 10px;
  }
`;

const Time = styled.div`
  line-height: 1;
  font-size: 10px;
  padding-top: 2px;
  text-align: right;
  font-weight: normal;
  color: white;
  @media screen and (max-width: 380px){
    font-size: 8px;
  }
`;

const Body = styled.div`
  padding: 4px;
  flex: 1 1 auto;
`;

const MainCard: React.FC<PropsWithChildren<{ className?: string, title: string, lastUpdatedTime?: Date|undefined, body?:React.ReactNode}>> 
= ({ className, title, lastUpdatedTime, body, children }) => {
  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  const { registerCallback, unregisterCallback } = useMetronome();
  const { translate, formatDateDistance } = useI18n();

  useEffect(() => {
    const updateTimestamp = () => {
      setCurrentTime(new Date());
    };

    const key = registerCallback(updateTimestamp);

    return () => unregisterCallback(key);
  }, [registerCallback, unregisterCallback]);

  return (
    <Container className={className}>
      <Header className='header'>
        <Title>{title}</Title>
        {!!lastUpdatedTime && (<Time>{translate('FORMAT_TIME_DISTANCE', { distance: formatDateDistance(lastUpdatedTime, currentTime) })}</Time>)}
      </Header>
      <Body className='body'>
        {body && (<>{body}</>)}
        {children && (<>{children}</>)}
      </Body>
    </Container>
  )
}

export default MainCard;
