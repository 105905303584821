
import axios from 'axios';

export const customAxios = axios.create({});

customAxios.interceptors.response.use(res => {
    return res;
  },
  error => {
    if (error.response.status === 401) {
        localStorage.removeItem('auth');
        window.location.reload();
    }
    throw error;
});

export default customAxios;