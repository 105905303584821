import React from "react";
import DashboardPreviewConfig from "./interfaces/DashboardPreviewConfig";
import styled from "styled-components";
import { default as MainHeaderApp } from "@appsinti/ui-core/header";
import useI18n from "@appsinti/i18n/useI18n";
import { APP_PUBLIC_NAME } from "@appsinti/dashboard-frontend/constants/config";

const Window = styled.iframe`
  width: 100%;
  height: 100vh;
  border: none;
`;

const Content = styled.div`
  position: relative;
  width: 100%;
`;

const Body: React.FC<{ url: string }> = ({ url }) => {
  return <Window title="dashboard" src={url}></Window>;
};

const Home: React.FC<{ config: DashboardPreviewConfig, groupId: number | null }> = ({
  config,
  groupId,
}) => {
  const { translateObject } = useI18n();
  return (
    <>
      <MainHeaderApp title={translateObject(APP_PUBLIC_NAME)} backButtonLocation={groupId ? `/${groupId}` : '..'} />
      <Content>
        <Body url={config.url} />
      </Content>
    </>
  );
};

export default Home;
