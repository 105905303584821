import {
  addDays,  
  getDay,
  isSameMonth,
  parse,
  startOfMonth,
  set,
} from "date-fns";

import { DayOfWeekValues, WeekModifierValues } from "./interfaces/SpecialDateValues";

const daysOfWeekIndexes = {
  [DayOfWeekValues.Sunday]: 0,
  [DayOfWeekValues.Monday]: 1,
  [DayOfWeekValues.Tuesday]: 2,
  [DayOfWeekValues.Wednesday]: 3,
  [DayOfWeekValues.Thursday]: 4,
  [DayOfWeekValues.Friday]: 5,
  [DayOfWeekValues.Saturday]: 6,
};

export function parseDayOfWeek(dayOfWeek) {
  return daysOfWeekIndexes[dayOfWeek];
}

const weekModifiersValues = {
  [WeekModifierValues.First]: 1,
  [WeekModifierValues.Second]: 2,
  [WeekModifierValues.Third]: 3,
  [WeekModifierValues.Fourth]: 4,
  [WeekModifierValues.Fifth]: 5,
  [WeekModifierValues.Last]: "last",
};

export function parseWeekModifier(weekModifier) {
  return weekModifiersValues[weekModifier];
}

export function getDateFromRule(
  patternDay,
  year
) {
  const currentYear = year ?? new Date().getFullYear();
  const month = patternDay.month;
  const dayOfWeek = parseDayOfWeek(patternDay.dayOfWeek);
  const week = parseWeekModifier(patternDay.weekModifier);

  let date = startOfMonth(new Date(currentYear, month - 1));

  while (getDay(date) !== dayOfWeek) {
    date = addDays(date, 1);
  }

  if (typeof week === "number") {
    const targetDate = addDays(date, (week - 1) * 7);

    if (!isSameMonth(targetDate, date) || (week === 5 && !isSameMonth(addDays(targetDate, -7), date))) {
      return getLastOccurrence(date);
    }

    return targetDate;
  } else if (week === "last") {
    return getLastOccurrence(date);
  } else {
    return null;
  }
}

function getLastOccurrence(date) {
  let lastDate = date;
  while (isSameMonth(addDays(lastDate, 7), date)) {
    lastDate = addDays(lastDate, 7);
  }
  return lastDate;
}

export function getDateFromCustom(
  customDate,
  year
) {
  const dates = Object.values(customDate.dates).sort(
    (valueA, valueB) =>
      parse(valueA, "yyyy-MM-dd", new Date()).getTime() -
      parse(valueB, "yyyy-MM-dd", new Date()).getTime()
  );
  const currentYear = year ?? new Date().getFullYear();
  let targetDate = null;

  for (const value of dates) {
    const year = value.slice(0, 4);
    if (+year === +currentYear) {
      targetDate = parse(value, "yyyy-MM-dd", new Date());
      break;
    }
  }
  return targetDate;
}

export function parseDate(date, year = null) {
  const currentYear = year ?? new Date().getFullYear();
  if(date.type === "date") {
    return set(parse(date.date, "yyyy-MM-dd", new Date()), { year: currentYear });
  }
  if(date.type === "custom") {
    const targetDate = getDateFromCustom(date, currentYear);
    return targetDate;
  }
  if(date.type === "pattern") {
    const targetDate = getDateFromRule(date, currentYear);
    return targetDate;
  }

  return null;
}

export function getSortedRangeDatesId(rangeDatesList, year){
  const baseDates = {};
  const currentYear = year ?? new Date().getFullYear();
  for(const [key, rangeDate] of Object.entries(rangeDatesList)) {
    switch(rangeDate.date.type){
      case 'date':
        baseDates[key] = set(parse(rangeDate.date.startDate, "yyyy-MM-dd", new Date()), { year: currentYear });
      break;
      case 'custom':
        const sortedDates = Object.values(rangeDate.date.dates).map((date)=>date.startDate).sort((a, b) =>
        parse(a, "yyyy-MM-dd", new Date()).getTime() - parse(b, "yyyy-MM-dd", new Date()).getTime());
        baseDates[key] = set(parse(sortedDates[0], "yyyy-MM-dd", new Date()), { year: currentYear });
      break;
      case 'pattern-offset':
        baseDates[key] = getDateFromRule({
          month: rangeDate.date.month,
          dayOfWeek: rangeDate.date.dayOfWeek,
          weekModifier: rangeDate.date.weekModifier
        }, currentYear)
      break;      
    }
  }
  return Object.entries(baseDates).sort((a,b) => a[1].getTime() - b[1].getTime()).map(date=>date[0]);
}

export function parseRangeDate(date, startYear=null, endYear=null) {
  if(!startYear || !endYear) return {};
  switch(date.date.type){
    case 'date': 
      const years = getYearsObject(date.date.startDate, date.date.endDate, startYear, endYear);
      return ({...date, years});
    case 'custom':
      const customYears = getCustomYearsObject(date.date.dates);
      return ({...date, years: customYears});      
    case 'pattern-offset':
      const patternYears = getOffsetYearsObject(date.date, startYear, endYear);
      return ({...date, years: patternYears});    
  }
}

const getYearsObject = (startDate, endDate, startYear, endYear) => {
  const years = {}

  const months = getMonthsObjects(startDate, endDate);

  for(let year = +startYear; year <= +endYear; year++) {
    years[year] = months;
  }
  return years;
}

const getCustomYearsObject = (dates) => {
  const years = {}

  for (const [key, date ] of Object.entries(dates)) {
    const currentDate = parse(date.startDate, "yyyy-MM-dd", new Date());
    const currentYear = currentDate.getFullYear();

    if(!years[currentYear]) years[currentYear] = getMonthsObjects(date.startDate, date.endDate)
  }
  
  return years;
}

const getOffsetYearsObject = (pattern, startYear, endYear) => {

  const rule = {
    month: pattern.month,
    dayOfWeek: pattern.dayOfWeek,
    weekModifier: pattern.weekModifier,
  }

  const years = {};
  for(let year = +startYear; year <= +endYear; year++) {
    const date = getDateFromRule(rule, year);
    let months = {};
    if(pattern.offsetDirection === 'before') {
      months = getMonthsObjects(addDays(date, (pattern.offset*-1)),date);
    } else {
      months = getMonthsObjects(date, addDays(date, pattern.offset));
    }
    years[year] = months;
  }

  return years;
}

const getMonthsObjects = (startDate, endDate) => {
  const months = {};
  const currentDate = typeof startDate === 'string' ? parse(startDate, "yyyy-MM-dd", new Date()) : startDate;
  const stopDate = typeof endDate === 'string' ?parse(endDate, "yyyy-MM-dd", new Date()) : endDate;
  while (currentDate <= stopDate) {
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();

    if(!months[month]) months[month] = [];
    months[month] = [...months[month], day];
    
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return months;
}