import React from 'react';
import styled from 'styled-components';
import Icon from '@etiquette-ui/icons';

const Image = styled.img`
  width: 16px;
  height: 16px;
`;

type CustomIcon = {
  icon: string;
  isImage?: boolean;
  color?: string;
  svgStyles?: {[key: string]: any}
}
const CustomIcon: React.FC<CustomIcon> = ({icon, isImage=false, color='white', svgStyles={}}) => {

  const {svg ={}, line = {}, ...restStyles} = svgStyles;
  
  return (
    <>
      { isImage 
        ? (<Image src={icon} />)
        : (<Icon
          icon={icon}
          styles={{
            svg: { width: '19px', ...svg },
            line: {
              fill: 'none',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2px',
              stroke: `${color}`,
              ...line
            },
            ...restStyles
          }}
        />)}
    </>
  )
}
export default CustomIcon;