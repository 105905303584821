import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { PRIMARY, TEXT } from '@etiquette-ui/colors';

import MenuHeader from './MenuHeader';
import Logout from './Logout';

import { default as SoundEditor } from '@appsinti/sound-frontend/editor';
import { Preview } from '@appsinti/weather-frontend';
import { useApps } from 'pages/home/AppProvider';
import CustomLink from "@appsinti/ui-core/CustomLink";
import { useParams } from 'react-router-dom';
import { NavBlueprint } from 'resources/api/interfaces/IApp';

const Background = styled.div`
  position: fixed;
  background-color: ${TEXT};
  opacity: 0.0;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5;
  pointer-events: none;
  transition-property: opacity;
  transition-duration: 0.2s;
  &.--open {
    opacity: 0.5;
    pointer-events: all;
  }
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const Menu = styled.div`
  position: fixed;
  background-color: ${PRIMARY};
  right: -85vw;
  top: 0;
  width: 85vw;
  height: 100%;
  border-radius: 8px 0px 0px 8px;
  z-index: 6;
  pointer-events: none;
  transition-property: right;
  transition-duration: 0.3s;
  display: flex;
  flex-direction: column;
  &.--open {
    right: 0;
    pointer-events: all;
  }
`;

const Body = styled.div`
  padding: 3rem 1.2rem 2rem 1.2rem;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ComponentPicker: React.FC<{
  appId: number;
  module: string;
  config: { [key: string]: any };
}> = ({ appId, module, config }) => {
  switch (module) {
    case "weather":
      return <Preview />;
    case "sound":
      return <SoundEditor appId={appId} />;
    default:
      return <div></div>;
  }
};

const LateralMenu: React.FC<{ toggle: () => void, isOpen: boolean }> = ({ toggle, isOpen }) => {
  const [animate, setAnimate] = useState<boolean>(false);
  const { apps } = useApps();
  const { groupId } = useParams<{ groupId: string }>();

  const grid = useMemo(() => {
    if (!groupId || apps === null) return [];
    const selectedApps = apps[groupId];
    if (!selectedApps) return [];
    return selectedApps
      .filter((app) => app.blueprint.type === "navigation")
      .map((app) => {
        const blueprint = app.blueprint as NavBlueprint;
        return blueprint.withLink ?
          <CustomLink key={app.id} to={`/app/${app.id}`}>
            <ComponentPicker appId={app.id} module={app.module} config={app.config} />
          </CustomLink> :
          <ComponentPicker key={app.id} appId={app.id} module={app.module} config={app.config} />
      });
  }, [apps, groupId]);

  useEffect(() => {
    setAnimate(true);
  }, []);

  const close = () => {
    setAnimate(false);
    setTimeout(() => {
      toggle();
    }, 300);
  }

  return (
    <>
      <Background onClick={close} className={isOpen ? '--open' : ''} />
      <Menu className={animate ? '--open' : ''}>
        <MenuHeader toggle={close} />
        <Body>
          <div>
            {grid}
          </div>
          <Logout />
        </Body>
      </Menu>
    </>
  );
};

export default LateralMenu;
