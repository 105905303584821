import CardTemplate from "@appsinti/ui-core/CardTemplate";
import styled from "styled-components";


const Card = styled(CardTemplate)`
    border-radius: 8px;
    overflow: visible;
    .body {
        background-color: white;
        padding: 8px;
        gap: 3px;

        @media (min-width: 350px) {
            padding: 15px;
        }
    }
`;

export default Card;