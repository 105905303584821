import React, { useEffect, useState } from "react";
import BaseDialog from "@appsinti/ui-core/dialogs/BaseDialog";

import EditName from "./components/EditName";
import Success from "./components/Success";

enum STATUS {
  PENDING = "pending",
  COMPLETED = "completed",
};

type Props = {
  open: boolean;
  onClose: (props?: any) => void;
  onSubmit: (props?: any) => void;
  id: string;
  name: string;
  appId: number|undefined;
};
const EditNameDialog: React.FC<Props> = ({ open, onSubmit, onClose, id, name, appId }) => {
  const [processStatus, setProcessStatus] = useState<string>(STATUS.PENDING);
  const [newName, setNewName] = useState<string>(name);
  
  const changeStatus = (status: STATUS) => {
    setProcessStatus(status);
  }

  const handleSubmit = () => {
    onSubmit(newName);
  }

  return (
    <BaseDialog
      open={open}
      onClose={processStatus===STATUS.PENDING ? onClose : handleSubmit}
      dismissible={false}
      isResponsive={true}
      header={"Edit Name"}
    >
      {processStatus === STATUS.PENDING && (
        <EditName
          id={id}
          name={name}
          newName={newName}
          setNewName={setNewName}
          nextStep={() => changeStatus(STATUS.COMPLETED)}
          onClose={onClose}
          appId={appId}
        />
      )}
      {processStatus === STATUS.COMPLETED && (
        <Success
          onSubmit={handleSubmit}
        />
      )}
    </BaseDialog>
  );
};
export default EditNameDialog;
