import styled from "styled-components";
import { PrimaryButton, SecondaryButton } from '@etiquette-ui/buttons';

export const TextBody = styled.div`
  min-width: 30rem;
  max-width: 35rem;
  font-size: 1.5rem;
  color: black;
`;

export const Footer = styled.div`
  padding: 1rem;
  display: flex;
  ${PrimaryButton},${SecondaryButton} {
    max-width: 45%;
    flex: 0 0 45%;
  }

  ${PrimaryButton} {
    max-width: 20rem;
    margin-left: auto;
  }
`;

export const ButtonContainer = styled.div `
  max-width: 20rem;
  min-width: 15rem;
  margin: auto;
`;