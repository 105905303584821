import { DayOfWeek, WeekModifier } from "@appsinti/stats-utils/src/interfaces/SpecialDaysConfig";
import { DayOfWeekValues, WeekModifierValues, OffsetDirectionValues } from "@appsinti/stats-utils/src/interfaces/SpecialDateValues";
import Translation from "@appsinti/i18n/interfaces/Translation";

export const WeekModifiersNames: { [key in WeekModifier]: Translation } = {
  [WeekModifierValues.First]: {en: "First", es: ""},
  [WeekModifierValues.Second]: {en: "Second", es: ""},
  [WeekModifierValues.Third]: {en: "Third", es: ""},
  [WeekModifierValues.Fourth]: {en: "Fourth", es: ""},
  [WeekModifierValues.Fifth]: {en: "Fifth", es: ""},
  [WeekModifierValues.Last]: {en: "Last", es: ""},
};
  
  export const weekModifiers = (lang: string) => Object.values(WeekModifierValues).map((modifier) => ({
    value: modifier,
    label: WeekModifiersNames[modifier][lang],
  }));
  
  export const daysOfWeekNames: { [key in DayOfWeek]: Translation } = {
    [DayOfWeekValues.Monday]: {en: "Monday", es: ""},
    [DayOfWeekValues.Tuesday]: {en: "Tuesday", es: ""},
    [DayOfWeekValues.Wednesday]: {en: "Wednesday", es: ""},
    [DayOfWeekValues.Thursday]: {en: "Thursday", es: ""},
    [DayOfWeekValues.Friday]: {en: "Friday", es: ""},
    [DayOfWeekValues.Saturday]: {en: "Saturday", es: ""},
    [DayOfWeekValues.Sunday]: {en: "Sunday", es: ""},
  };

  export const daysOfWeek = (lang: string) => Object.values(DayOfWeekValues).map((day) => ({
    value: day,
    label: daysOfWeekNames[day][lang],
  }));
  
  export const monthNames: { [key: number]: Translation } = {
    1: {en: "January", es: "Enero"},
    2: {en: "February", es: "Febrero"},
    3: {en: "March", es: "Marzo"},
    4: {en: "April", es: "Abril"},
    5: {en: "May", es: "Mayo"},
    6: {en: "June", es: "Junio"},
    7: {en: "July", es: "Julio"},
    8: {en: "August", es: "Agosto"},
    9: {en: "September", es: "Septiembre"},
    10: {en: "October", es: "Octubre"},
    11: {en: "November", es: "Noviembre"},
    12: {en: "December", es: "Diciembre"},
  };

  export const months = (lang: string) => Object.keys(monthNames).map((key) => ({
    value: Number(key),
    label: monthNames[parseInt(key)][lang],
  }));

  export const offsetDirectionNames: {[key: number]: Translation} = {
    [OffsetDirectionValues.Before]: {en: 'Before', es: 'Antes'},
    [OffsetDirectionValues.After]: {en: 'After', es: 'Después'},
  }

  export const offsetModifiers = (lang: string) => Object.keys(offsetDirectionNames).map((modifier) => ({
    value: modifier,
    label: offsetDirectionNames[modifier][lang],
  }));

  export const DayCounterName: Translation = {en: "Days", es: "Días"}; 
  export const daysCounter = (lang: string) => Array.from(Array(15),(x,i)=>({
    value: i+1,
    label: `${i+1} ${i === 0 ? DayCounterName[lang].replace('s','') : DayCounterName[lang]}`,
  }));