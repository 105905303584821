import { Link } from "react-router-dom";
import styled from "styled-components";
import { TEXT } from '@etiquette-ui/colors';

const ListItemContainer = styled(Link)`
  display: flex;
  width: 100%;
  text-decoration: none;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
  margin: 1px 0;
  p {
    margin: 0;
    padding: 0;
    color: ${TEXT};
    font-weight: 600;
  }
`;

export default ListItemContainer;
