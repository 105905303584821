import React from "react";
import CardTemplate from "@appsinti/ui-core/CardTemplate";
import { MetricData } from "@appsinti/ui-core/styles";
import StatsPreview from "../../../../interfaces/StatsPreview";
import { RIGHT_DARK } from "@etiquette-ui/colors";
import {
  formatMoneyMK,
} from "@appsinti/common-utils";
import { CardTitleComponent, CustomRow } from "../../styles";

const SalesRow: React.FC<{ preview: StatsPreview }> = ({
    preview,
  }) => {
  return (
    <CardTemplate
      background={"white"}
      noPadding={true}
      header={
        <CustomRow>
          <CardTitleComponent title={"Total Sales"} />
          <CardTitleComponent title={"Lunch"} />
          <CardTitleComponent title={"Dinner"} />
        </CustomRow>
      }
      body={
        <CustomRow>
          <div className="column">
            <MetricData size={"biggest"} weight={"bold"} color={RIGHT_DARK}>
              {formatMoneyMK(preview.salesData.total.totalSales)}
            </MetricData>
          </div>
          <div className="column">
            <MetricData size={"biggest"} weight={"bold"} color={RIGHT_DARK}>
              {formatMoneyMK(preview.salesData.lunch!.totalSales)}
            </MetricData>
          </div>
          <div className="column">
            <MetricData size={"biggest"} weight={"bold"} color={RIGHT_DARK}>
              {formatMoneyMK(preview.salesData.dinner!.totalSales)}
            </MetricData>
          </div>
        </CustomRow>
      }
    />
  );
};

export default SalesRow;
