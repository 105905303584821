import React, { useEffect, useState } from 'react';
import CustomIcon from '@appsinti/ui-core/CustomIcon';
import { getTodayNameDay, getToday, ALT_DATE_FORMAT_FULL_YEAR } from '@appsinti/ui-core/dateAndTime/utils';
import { IconContainer } from '../../styles';
import PreviewCamerasManager from './PreviewCamerasManager';
import useI18n from '@appsinti/i18n/useI18n';
import { Ring } from "@uiball/loaders";
import { useMetronome } from '@appsinti/metronome';
import { DateContainer, HeaderContainer, SelectorContainer, Updater } from './styles';
import { useData } from '../../data';

const Header: React.FC = () => {
  const { translate, selectedLang, formatDateDistance } = useI18n();
  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  const { registerCallback, unregisterCallback } = useMetronome();
  const { lastUpdate, fetchData, loading } = useData();
  const { areas, previews, appId } = useData();

  useEffect(() => {
    const updateTimestamp = () => {
      setCurrentTime(new Date());
    };

    const key = registerCallback(updateTimestamp);

    return () => unregisterCallback(key);
  }, [registerCallback, unregisterCallback]);

  return (
    <>
      <HeaderContainer>
        <DateContainer>
          {`${getTodayNameDay(selectedLang).substring(0, 3)} ${getToday(ALT_DATE_FORMAT_FULL_YEAR)} `}
        </DateContainer>
        <Updater>
          <span>{translate('FORMAT_TIME_DISTANCE', { distance: formatDateDistance(lastUpdate, currentTime) })}</span>
          {loading ? <Ring color={"white"} size={20} /> : <IconContainer onClick={() => fetchData()}>
            <CustomIcon icon='update' />
          </IconContainer>}
        </Updater>
        <SelectorContainer>
          {appId && (<PreviewCamerasManager 
            areas={areas}
            appId={appId}
            previews={previews}
          />)}
        </SelectorContainer>
      </HeaderContainer>
    </>
  )
}
export default Header;
