import React from 'react';
import styled from 'styled-components';
import useI18n from '@appsinti/i18n/useI18n';


import IconTextItem from '@appsinti/ui-core/IconTextItem';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`

  margin-top: auto;
  position: relative;
  padding-top: 0.5rem;
  cursor: pointer;

  &::before {
    content: '';
    width: 70%;
    height: 2px;
    left: 15%;
    top: 0px;
    position: absolute;
    opacity: .7;
    border-top: solid 2px rgba(255, 255, 255, .3);
  }
`;

const Logout: React.FC = () => {
   const { translate } = useI18n();
   let navigate = useNavigate();
  const logout = () => {
    // localStorage.removeItem('auth');
    // window.location.reload();
    navigate('/logout');
  };

  return (
    <>
      <Container>
        <IconTextItem 
          onClick={logout}
          icon={'logout'}
          body={<>{translate('Logout')}</>}
        />
      </Container>
    </>
  );
};

export default Logout;
