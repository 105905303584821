import styled from "styled-components";

export const IconContainer = styled.div<{isLeftPosition?:boolean, isCollapsable?:boolean}>`
  width: 2rem;
  height: 2rem;
  margin-right: ${({isLeftPosition}) => isLeftPosition ? '1rem' : '0px'};

  &.--is-collapsable {
    transform: ${({isCollapsable})=> isCollapsable ? "scaleY(-1)" : "scaleY(1)"};
    transition-duration: .3s;
  }
`;