import React from "react";
import styled from "styled-components";
import { Ring } from "@uiball/loaders";
import MainCard from "@appsinti/ui-core/MainCard";
import { PreviewDataProvider, usePreviewData } from "./data";
import CardsQuarter from "./components/CardsQuarter";
import CardsHalf from "./components/CardsHalf";

const CustomMainCard = styled(MainCard)`
  .body {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: stretch;
  }
`;

export type Size = "half" | "quarter";

const Body: React.FC<{ size: Size }> = ({ size }) => {
  const { preview, loading, lastUpdate } = usePreviewData();

  return (
    <CustomMainCard title={"Sales"} lastUpdatedTime={lastUpdate}>
      {loading && !preview ? (
        <div style={{ alignSelf: "center" }}>
          <Ring color={"white"} size={30} />
        </div>
      ) : (
        <>
          {size === "quarter" && preview && <CardsQuarter preview={preview} />}
          {size === "half" && preview && <CardsHalf preview={preview} />}
        </>
      )}
    </CustomMainCard>
  );
};

const Preview: React.FC<{ appId: number; size: Size }> = ({ appId, size }) => {
  return (
    <PreviewDataProvider appId={appId}>
      <Body size={size} />
    </PreviewDataProvider>
  );
};

export default Preview;
