import React from "react";
import { ChevronRightIcon } from "@etiquette-ui/icons";
import ListItemContainer from "../../../containers/ListItemContainer";
import { SpecialDay } from "@appsinti/stats-utils/interfaces/SpecialDaysConfig";

const SpecialDayItem: React.FC<{ specialDay: SpecialDay, dayId: string }> = ({ specialDay, dayId }) => {
  return (
    <ListItemContainer to={"day"} state={{ specialDay, dayId }}>
      <p>{specialDay.title}</p>
      <ChevronRightIcon styles={{ svg: { width: "20px", height: "20px" } }} />
    </ListItemContainer>
  );
};

export default SpecialDayItem;