import React, { useState } from 'react';
import MenuIcon from 'resources/img/menu-icon.svg';
import styled from 'styled-components';
import LateralMenu from './LateralMenu';

const Container = styled.div`
  display: none;
  width: 20%;
  max-width: 20%;
  justify-content: flex-end;
  display: flex;
    cursor: pointer;
    order: 4;
`;

const Menu = styled.img`
  width: 25px;
  height: 25px;
`;

export const ShowMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <Container onClick={toggle}>
        <Menu src={MenuIcon} alt={'isOpen-menu'} />
      </Container>
      {isOpen && <LateralMenu toggle={toggle} isOpen={isOpen} />}
    </>
  )
}
