import React from "react";
import StatsPreview from "../../../interfaces/StatsPreview";
import SalesSplits from "./SalesSplits";
import SalesBasic from "./SalesBasic";
import SalesGuestSplits from "./SalesGuestSplits";
import ClosedSalesCash from "./ClosedSalesCash";
import Reservations from "./Reservations";
import CourtesyCancellations from "./CourtesyCancellations";
import styled from "styled-components";

const SplitsContainer = styled.div`
  flex: 5;
  display: flex;
  gap: 5px;

  @media screen and (max-height: 745px) {
    flex: 4;
  }
`;

const CardsQuarter: React.FC<{ preview: StatsPreview }> = ({ preview }) => {
  return (
    <>
      {!!preview.lunch && !!preview.dinner ? (
        <SplitsContainer>
          <SalesSplits
            total={preview.total}
            lunch={preview.lunch}
            dinner={preview.dinner}
          />
          <SalesGuestSplits
            total={preview.total}
            lunch={preview.lunch}
            dinner={preview.dinner}
          />
        </SplitsContainer>
      ) : (
        <SalesBasic preview={preview} />
      )}
      <ClosedSalesCash preview={preview} />
      <Reservations preview={preview} />
      <CourtesyCancellations preview={preview} />
    </>
  );
};

export default CardsQuarter;
