import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import { default as StatsApp } from "@appsinti/stats-frontend";
import { default as WeatherApp } from "@appsinti/weather-frontend/home";
import { default as WeatherConfig } from "@appsinti/weather-frontend/interfaces/WeatherConfig";
import { default as BigBrotherApp } from "@appsinti/bigbrother-frontend/home";
import { default as DashboardApp } from "@appsinti/dashboard-frontend/Home";
import DashboardPreviewConfig from "@appsinti/dashboard-frontend/interfaces/DashboardPreviewConfig";
import { useApps } from "pages/home/AppProvider";
import IApp from "resources/api/interfaces/IApp";
import { BACKEND, BACKEND_HOST } from "resources/constants/urls";


const AppContainer = styled.div`
  width: 100%;
  margin-top: 5.6rem;
`;

const ComponentPicker: React.FC<{ module: string, config: { [key:string]: any }, appId: number, groupId: number | null }> = ({ module, config, appId, groupId }) => {
  switch (module) {
    case "weather":
      return <WeatherApp appId={appId} groupId={groupId} />;
    case "stats":
      return <StatsApp config={{ backendUrl: BACKEND, backendHost: BACKEND_HOST, appId: appId, groupId: groupId }} />;
    case "bigbrother":
      return <BigBrotherApp appId={appId} groupId={groupId} />;
    case "dashboard":
      return <DashboardApp config={config as DashboardPreviewConfig} groupId={groupId} />;
    default:
      return <div>Not found</div>;
  }
};

const AppPage: React.FC = () => {
  const { appId } = useParams<{ appId: string }>();
  const { apps } = useApps();
  const [currentApp, setCurrentApp] = useState<IApp | null>(null);

  useEffect(() => {
    if (apps === null) return;
    if(appId === undefined) return;
    const app = Object.values(apps).reduce((acc, curr) => ([...acc, ...curr]), []).find((app) => app.id === +appId);
    if (!app) return;
    setCurrentApp(app);
  }, [appId, apps]);

  return (
    <>
      <AppContainer>
        {currentApp && currentApp.module && currentApp.config && (
          <ComponentPicker appId={currentApp.id} module={currentApp.module} config={currentApp.config} groupId={currentApp.groupId} />
        )}
      </AppContainer>
    </>
  );
};

export default AppPage;
