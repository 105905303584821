import React, { useCallback, useMemo, useState } from "react";

import COLORS from "@appsinti/stats-frontend/resources/constants/colors";
import IChartData, {
  IDataset,
} from "@appsinti/stats-frontend/interfaces/IChartData";
import { CustomCard, LegendContainer, Label } from "../styles";
import Header from "../components/Header";

import useI18n from "@appsinti/i18n/useI18n";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import ChartDataLabels from "chartjs-plugin-datalabels";
import type { ChartData } from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale,
  zoomPlugin,
  ChartDataLabels
);

import { BarOptions, getOptions } from "./options";
import Loader from "../components/Loader";

type BarChart = {
  dataObject: IChartData;
  barOptions: BarOptions;
  isOnlyOneColor?: boolean;
  colorCallback?: (
    index: number,
    dataset: IDataset,
    dataObject?: IChartData
  ) => string;
  isLoading?: boolean;
  showLegend?: boolean;
  fullCard?: boolean;
  editLocation?: string;
};
const BarChart: React.FC<BarChart> = ({
  dataObject,
  barOptions,
  isOnlyOneColor = false,
  colorCallback,
  isLoading = false,
  showLegend = true,
  fullCard = true,
  editLocation,
}) => {
  const { translateObject } = useI18n();
  const [hasData, setHasData] = useState(false);

  const defineColor = useCallback(
    (index: number, dataset: IDataset, dataObject?: IChartData) => {
      if (!!colorCallback) return colorCallback(index, dataset, dataObject);
      if (!dataObject || isOnlyOneColor) return COLORS[1];
      return COLORS[
        (COLORS.length -
          ((dataObject.datasets.length - index) % COLORS.length)) %
          COLORS.length
      ];
    },
    [dataObject, isOnlyOneColor, colorCallback]
  );

  const options = useMemo(() => getOptions(barOptions), [barOptions]);

  const data = useMemo(() => {
    if (!dataObject) return null;
    setHasData(
      dataObject.datasets.length > 0 && dataObject.datasets[0].data.length > 0
    );

    const isMultipleDatasets = dataObject.datasets.length > 1;

    if (isMultipleDatasets) {
      const data: ChartData<"bar", number[], string | number> = {
        labels: dataObject.isTimeBased
          ? dataObject.labels
          : dataObject.labels.map((label) => translateObject(label)),
        datasets: dataObject.datasets.map((dataset, index) => ({
          data: dataset.data,
          label:
            typeof dataset.label === "string" ||
            typeof dataset.label === "undefined"
              ? dataset.label
              : translateObject(dataset.label),
          backgroundColor: defineColor(index, dataset),
          borderColor: defineColor(index, dataset),
          stack: dataset.stack,
        })),
      };
      return data;
    }

    const data: ChartData<"bar", number[], string | number> = {
      labels: dataObject.isTimeBased
        ? dataObject.labels
        : dataObject.labels.map((label) => translateObject(label)),
      datasets: dataObject.datasets.map((dataset) => ({
        data: dataset.data,
        backgroundColor: dataset.data.map((_, index) =>
          defineColor(index, dataset, dataObject)
        ),
        borderColor: dataset.data.map((_, index) =>
          defineColor(index, dataset, dataObject)
        ),
        stack: dataset.stack,
      })),
    };

    return data;
  }, [dataObject, defineColor]);

  return fullCard ? (
    <CustomCard
      background="white"
      header={
        <Header
          title={translateObject(dataObject.title)}
          subtitle={dataObject.subtitle && translateObject(dataObject.subtitle)}
          to={editLocation}
        />
      }
      body={
        isLoading === false && !!data && !!data.datasets ? (
          hasData ? (
            <>
              <Bar options={options} data={data} height={250} />
              {showLegend &&
                (!barOptions.isStacked ||
                  barOptions.stackLabels !== undefined) && (
                  <LegendContainer>
                    {barOptions.isStacked
                      ? data.datasets
                          .filter(
                            (v, i, a) =>
                              a.findIndex((t) => t.stack === v.stack) === i
                          )
                          .map(
                            (dataset) =>
                              !!barOptions.stackLabels &&
                              !!dataset.stack && (
                                <Label
                                  key={dataset.label}
                                  color={dataset.borderColor?.toString() ?? ""}
                                >
                                  {translateObject(
                                    barOptions.stackLabels[dataset.stack]
                                  )}
                                </Label>
                              )
                          )
                      : data.datasets.map((dataset) => (
                          <Label
                            key={dataset.label}
                            color={dataset.borderColor?.toString() ?? ""}
                          >
                            {dataset.label}
                          </Label>
                        ))}
                  </LegendContainer>
                )}
            </>
          ) : (
            <h3 style={{ textAlign: "center" }}>No Data</h3>
          )
        ) : (
          <Loader />
        )
      }
    />
  ) : (
    data && <Bar options={options} data={data} height={250} />
  );
};
export default BarChart;
