import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {getSelectedSource} from "../services/sources";
import {EventEmitter} from "../events/eventEmitter";
import SwitchButton from "../SwitchButton";
import {getPlayingState, setPlayingState} from "../services/player";

class Player {
    private url
    private instance
    public player
    constructor(url) {
        this.url = url
        this.player = useRef(new Audio(url))

        if (typeof Player.instance === 'object') {
            return Player.instance
        }

        Player.instance = this
        return this
    }
}

const AudioController = ({instanceId}) => {
    const eventName = 'sound_toggle_action'
    const [isActive, setIsActive] = useState(getPlayingState());
    const selectedSource = getSelectedSource()
    const audioElement = selectedSource ? new Player(selectedSource.url)  : null

    EventEmitter.subscribe(eventName, ({play, id}) => {
        setIsActive(play)
        if (id !== instanceId) return

        if (play) {
            audioElement.player.current.play()
            audioElement.player.current.muted = false
            return
        }
        audioElement.player.current.muted = true
    })

    const produceToggleEvent = (newState: boolean) => EventEmitter.dispatch(eventName, {play: newState, id: instanceId})
    const toggle = (newState: boolean) => {
        setIsActive(newState)
        produceToggleEvent(newState)
        setPlayingState(newState)
    }
    return (
        <SwitchButton isActive={isActive} setIsActive={toggle}/>
    )
}

export default AudioController
