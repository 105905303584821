import React, { PropsWithChildren, useMemo, useState } from "react";
import IRoute from "../interfaces/IRoute";
import Sales from "./pages/sales";
import Cash from "./pages/cash";
import Cost from "./pages/cost";
import Cancellations from "./pages/cancellations";
import Clients from './pages/clients';

type DataContextType = {
  routes: IRoute[];
};

const allRoutes: (appId: number) => IRoute[] = (appId) => [
  { path: `sales`, title: { en: "Sales", es: "Ventas" }, Component: Sales },
  { path: 'cash', title: { en: "Cash", es: "Flujo" }, Component: Cash },
  { path: 'cost', title: { en: "Cost", es: "Costos" }, Component: Cost },
  { path: 'guests', title: { en: "Guests", es: "Clientes" }, Component: Clients },
  {
    path: `cancellations/`,
    title: { en: "Cancellations", es: "Cancelaciones" },
    Component: Cancellations,
  },
];

const RoutesContext = React.createContext<DataContextType>({
  routes: [],
});

export const RoutesProvider: React.FC<
  PropsWithChildren<{
    appId: number;
  }>
> = ({ appId, children }) => {
  const routes = useMemo(() => allRoutes(appId), [appId]);

  return (
    <RoutesContext.Provider
      value={{
        routes,
      }}
    >
      {children}
    </RoutesContext.Provider>
  );
};

export const useRoutes = () => React.useContext(RoutesContext);
