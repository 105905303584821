import GroupedOption, {Option} from '@appsinti/ui-core/dropdown/interfaces/IGroupOption';
import Translation from "@appsinti/i18n/interfaces/Translation";
import IArea from "../interfaces/IArea";


export const getSelectedOptions = (options : GroupedOption[], previewsId: string[]) : Option[] => {
  const selectedOptions : Option[] = [];
  
  if(previewsId.length === 0) return [];

  for(const optionsGroup of options){
    for(const option of optionsGroup.options ){
      if(previewsId.includes(option.value)){
        selectedOptions.push(option)
      }

      if(previewsId.length === selectedOptions.length) break;
    }
    if(previewsId.length === selectedOptions.length) break;
  }
  return selectedOptions;
}

export const getOptions = (cameras: IArea[], translateObject: (lang: Translation) => string): GroupedOption[] => {
  let groupOptions: GroupedOption[] = [];
  for(const area of cameras){
    const groupOption: GroupedOption = {
      label: translateObject(area.name),
      options: area.cameras.map( camera => ({
        label: translateObject(camera.locationName), 
        value: camera.id,
        parentId: area.id
      }))      
    }
    groupOptions.push(groupOption);
  }
  return groupOptions;
}