import authCrudder from './auth-crud';
import { API, BACKEND } from '../constants/urls';
import { executeCall } from './utils';
import Axios from './api-client';
import IUser from './interfaces/IUser';
import IApp from './interfaces/IApp';
import IGroup from './interfaces/IGroup';
import Authorization from './auth';
import { AxiosRequestHeaders } from 'axios';

export const authAPI = authCrudder(API, "auth/access-list");
export const getUsersOfGroup = ({ groupId, division, role }: { groupId: number, division?: number | null, role?: number | null }) => executeCall<IUser[]>(() => Axios.get<IUser[]>(`${API}/security/user/ofgroup/public/${groupId}/${!!division ? division : '0'}/${!!role ? role : '0'}`));
export const getApps = (groupId: number | null) => executeCall<IApp[]>(() => Axios.post<IApp[]>(`${BACKEND}/appsinti/apps`, { groupId }, { headers: Authorization as AxiosRequestHeaders }));
export const getGroupInfo = (groupId: number) => executeCall<IGroup>(() => Axios.get<IGroup>(`${API}/group/${groupId}`, { headers: Authorization as AxiosRequestHeaders }));