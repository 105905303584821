import React from 'react';
import styled from 'styled-components';
import { BG_DARK } from '@etiquette-ui/colors';

const Card = styled.div<{background?: string, noPadding?: boolean}>`
  max-width: 100%;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${({background}) => background ? background : ''};
  outline: 1px solid #9BA2AB;
  display: flex;
  flex-direction: column;
  flex: 2;

  .header {
    width: 100%;
    padding: 3px;
    background-color: ${BG_DARK};
  }

  .body {
    padding: ${({noPadding}) => noPadding ? '0px' : '4px 6px'};
    width: 100%;
    flex: 1 1 auto;
  }

  .footer {
    width: 100%;
    padding: 8px;
    padding-top: 0px;
  }

  &.--is-child, &.--is-nested{
    .body, .footer {
      padding-left: 3px;
      padding-right: 3px;
    }
  }

  &.--is-child {
    outline: 0px;
  }
`;


const CardTemplate: React.FC<{header?: React.ReactNode, body: React.ReactNode, footer?: React.ReactNode, background?:string, className?:string, noPadding?: boolean}>
= ({header, body, footer, background, className, noPadding}) => {
  return (
    <Card className={className} background={ background && background} noPadding={noPadding}>
      {header && (
        <div className='header'>
          {header}
        </div>
      )}
      
      <div className='body'>
        {body}
      </div>

      {footer && (
        <div className='footer'>
          {footer}
        </div>
      )}
    </Card>
  )
}

export default CardTemplate;
