import React from 'react';
import { PrimaryButton } from '@etiquette-ui/buttons';
import BaseDialog from './BaseDialog';

import {TextBody, ButtonContainer, Footer} from './styles';

const Actions:React.FC<{onClose, buttonText}> = ({ onClose, buttonText }) => {
  return (
    <Footer>
      <ButtonContainer>
        <PrimaryButton onClick={onClose}>{buttonText}</PrimaryButton>
      </ButtonContainer>
    </Footer>
  );
}

type InfoDialog = {
  textBody: string,
  title?: string,
  onClose: ()=>void
  closeButtonText: string
}
const InfoDialog: React.FC<InfoDialog> = ({textBody, title, onClose, closeButtonText}) => {
  return (
    <BaseDialog
      open={open}
      header={title}
      onClose={onClose}
      isResponsive={false}
      dismissible={false}
      footer={<Actions onClose={onClose} buttonText={closeButtonText} />}
    >
      <TextBody>{textBody}</TextBody>
      
    </BaseDialog>
  )
};
export default InfoDialog;

