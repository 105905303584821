import React from "react";
import CardTemplate from "@appsinti/ui-core/CardTemplate";
import { MetricData } from "@appsinti/ui-core/styles";
import StatsPreview from "../../../../interfaces/StatsPreview";
import { CustomRow, MainColumn } from "../../styles";
import { formatPercentage } from "../../../../../../utils";
import { WRONG_DARK } from "@etiquette-ui/colors";

const CancellationCourtesy: React.FC<{ preview: StatsPreview }> = ({ preview }) => {
  return (
    <CardTemplate
      background={"white"}
      noPadding={true}
      body={
        <CustomRow>
          <MainColumn className="main">
            <div className="column">
              <MetricData size={"medium"} weight={"bold"}>
                CANCEL
              </MetricData>
              <MetricData size={"bigger"} weight={"bold"} color={WRONG_DARK}>
                {formatPercentage(preview.salesData.total.cancellation, 1)}
              </MetricData>
            </div>
            <div className="column">
              <MetricData size={"medium"} weight={"bold"}>
                COURTESY
              </MetricData>
              <MetricData size={"bigger"} weight={"bold"} color={WRONG_DARK}>
                {formatPercentage(preview.salesData.total.courtesy, 1)}
              </MetricData>
            </div>
          </MainColumn>
        </CustomRow>
      }
    />
  );
};

export default CancellationCourtesy;
