import React from 'react';
import { PRIMARY } from '@etiquette-ui/colors';
import { Dropdown } from '@etiquette-ui/inputs-dropdowns';
import SelectOption from '../../../../interfaces/SelectOption';
import { DayOfWeek, WeekModifier, OffsetModifier } from '@appsinti/stats-utils/src/interfaces/SpecialRangeDatesConfig';

type Props = {
  [x:string]: any;
}
const CustomInput: React.FC<{
  children?: React.ReactNode,
  props: Props
}>  = ({children, ...props}) => {
  return (
    <label style={{
      padding: '5px', 
      display: 'flex', 
      minWidth: '100px', 
      justifyContent: 'end'
    }}>
      {children}
    </label>
  );
};

const dropdownConstants:{[x:string]: any} = {
  containerProps: { 
    style: { 
      '--bg': 'transparent'
    } 
  },
  trailingIconProps: {
    disabled: true
  },
  selectStyles: {
    menu: (base)=>({
      ...base,
      marginTop: '0px',
    }),
    singleValue: (base)=>({
      ...base,
      font: 'normal normal normal 14px/18px Montserrat',
      color: PRIMARY,
      fontWeight: '600',
    }),
    option: (base)=>({
      ...base,
      fontSize: '13px'
    })
  }
}

const SpecialDropdown: React.FC<{
  value: SelectOption<number|WeekModifier|DayOfWeek|OffsetModifier>, 
  options: SelectOption<number|WeekModifier|DayOfWeek|OffsetModifier>[], 
  onChange: (value: SelectOption<number|WeekModifier|DayOfWeek|OffsetModifier>) => void,
}> = ({value, options, onChange}) => {
  return (
    <Dropdown 
      CustomValueComponent={CustomInput}     
      containerProps={dropdownConstants.containerProps} 
      trailingIconProps={dropdownConstants.trailingIconProps}           
      selectProps={{ 
        value: value,
        options: options,
        onChange: onChange,
        styles: {...dropdownConstants.selectStyles}
      }}
    />
  )
}
export default SpecialDropdown;