import React, { useState } from 'react';
import {PrimaryButton, SecondaryButton} from '@etiquette-ui/buttons';

import Input from '@appsinti/ui-core/Input';
import {MiddleWidth} from '@appsinti/ui-core/styles';
import {Container, CustomRow, Text } from '../../sytles';

import useI18n from '@appsinti/i18n/useI18n';

type Props = {
  name: string, 
  status: string,
  onClose: ()=>void,
  newName: string,
  setNewName: React.Dispatch<string>,
  setProcessStatus:React.Dispatch<string>, 
}
const Lounch: React.FC<Props> = ({name, status, onClose, newName, setNewName, setProcessStatus}) => {
  const [error, setError] = useState<string>('');
  const { translate } = useI18n();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.value !== '') setError('');
    setNewName(event.target.value);
  }

  const handleSubmit = () => {
    newName !== '' 
      ? setProcessStatus(status)
      : setError(translate('Name must be in a valid format'));
  }

  return (
    <Container>
      <Text>
        <strong>{translate('Current name')}:</strong> {name}
      </Text>
      <Input
        inputProps={{
          onChange: handleChange,
          value: newName
        }}
        labelProps={{
          label: translate('New name')
        }}
        errorProps={{
          error: error 
        }}
      />
      <CustomRow>
        <MiddleWidth>
          <SecondaryButton onClick={onClose}>{translate('Cancel')}</SecondaryButton>
        </MiddleWidth>
        <MiddleWidth>
          <PrimaryButton onClick={handleSubmit}>{translate('Update')}</PrimaryButton>
        </MiddleWidth>
      </CustomRow>
      
      
    </Container>
  )
}
export default Lounch;
