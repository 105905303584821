import React, { useState } from 'react';
import BaseDialog from '@appsinti/ui-core/dialogs/BaseDialog';

import Lounch from './components/Lounch';
import Validation from './components/Validation';
import Completed from './components/Completed';

const STATUS: {[key: string]: string} = {
  'pending': 'pending',
  'to be confirmed': 'to be confirmed',
  'completed': 'completed'
};

type Props = {
  onClose:()=>void
  id: string,
  name: string,
  type?: string,
}
const EditNameDialog: React.FC<Props> = ({onClose, id, name, setTitle, appId}) => {
  const [processStatus, setProcessStatus] = useState<string>(STATUS['pending']);
  const [newName, setNewName] = useState<string>('');
  const [success, setSuccess] = useState(false)

  return (
    <BaseDialog open={open} onClose={onClose} dismissible={false} isResponsive={true} header={'Edit Name'}>
      { (processStatus === STATUS['pending']) &&
        (<Lounch
          name={name}
          newName={newName}
          setNewName={setNewName}
          setProcessStatus={setProcessStatus}
          status={STATUS['to be confirmed']}
          onClose={onClose} />
        )}

      { (processStatus === STATUS['to be confirmed']) &&
        (<Validation
          name={name}
          newName={newName}
          id={id}
          setProcessStatus={setProcessStatus}
          setSuccess={setSuccess}
          previousStatus={STATUS['pending']}
          nextStatus={STATUS['completed']}
          onClose={onClose} 
          appId={appId}
          />
        )}

      { (processStatus === STATUS['completed']) &&
        (<Completed
            onClose={onClose}
            isSuccess={success}
            newName={newName}
            id={id}
            setTitle={setTitle}
            appId={appId}
            />
        )}

    </BaseDialog>
  )
}
export default EditNameDialog
