import styled from "styled-components";
import CardTemplate from '@appsinti/ui-core/CardTemplate';

export const CustomCard = styled(CardTemplate)`
  border-radius: 2.4rem 2.4rem 0px 0px;
  margin-bottom: 1.5rem;

  .body {
    padding: 8px 8px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  color:white;
  font-size: 1.2rem;

  padding: 0.6rem .4rem;

  align-items: center;

  &.flex-between {
    justify-content: space-between;
  }

  .chart-title,
  .chart-subtitle{
    font-size: inherit;
    color: white;
    flex: 0 1 auto;
  }
  
  .chart-title{
    font-weight: bold;
  }

  .chart-subtitle{
    font-weight: normal;
  }

`;

export const LegendContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0.5rem;
  padding: 0px 1.5rem;
  align-items: center;
  justify-content: center;
`;

export const Label = styled.div<{color: string}>`
  padding-left: 1rem;
  font-size: 1.1rem;;
  position: relative;
  color: #727272;

  &:before {
    content: '';
    position: absolute;
    top: .4rem;
    left: 0px;
    width: .7rem;
    height: .7rem;
    border-radius: 50%;
    background-color: ${({color}) => color ? color : "#e5e5e5"};
  }
`;