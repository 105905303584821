import CardTemplate from "@appsinti/ui-core/CardTemplate";
import React from "react";
import { CustomRow } from "../styles";
import CardTitleComponent from "../CardTitleComponent";
import { RIGHT_DARK } from "@etiquette-ui/colors";
import { MetricData } from "@appsinti/ui-core/styles";
import { formatMoneyMK, formatPercentage } from "@appsinti/common-utils";
import StatsPreview from "../../../interfaces/StatsPreview";

const ClosedSalesCash: React.FC<{ preview: StatsPreview }> = ({ preview }) => {
  return (
    <CardTemplate
      background={"white"}
      header={
        <CustomRow>
          <CardTitleComponent title={"Closed sales"} />
          <CardTitleComponent title={"Cash %"} />
        </CustomRow>
      }
      body={
        <CustomRow>
          <div className="column">
            <MetricData size={"bigger"} weight={"bold"} color={RIGHT_DARK}>
              {formatMoneyMK(preview.total.closedSales)}
            </MetricData>
          </div>
          <div className="column">
            <MetricData size={"bigger"} weight={"bold"} color={RIGHT_DARK}>
              {formatPercentage(preview.total.cash, 1)}
            </MetricData>
          </div>
        </CustomRow>
      }
    />
  );
};

export default ClosedSalesCash;
