import { AxiosRequestHeaders } from 'axios';
import Axios from './api-client';
import { APP_ID } from 'resources/constants/config';
import Authorization from './auth';
import { executeCall } from './utils';

const authCrudder: (domain: string, resource: string)=>any = (domain, resource) => {
  const url = `${domain}/${resource}`;

  const headers = (groupId: number | string) => {
    return groupId ? { ...Authorization, groupId } : Authorization;
  };

  return {
    getAll: (groupId: number | string) => executeCall(() => Axios.get(url + `/${APP_ID}/${groupId}`, { headers: headers(groupId) as AxiosRequestHeaders })),
  };
};

export default authCrudder;
