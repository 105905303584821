import {EventEmitter} from "../events/eventEmitter";

const key = 'playing_state'

export const getPlayingState = () => {
    const value = sessionStorage.getItem(key)
    return !(!value || value !== 'true');
}

export const setPlayingState = (newState: boolean) => sessionStorage.setItem(key, newState+'')

export const clearPlayer = () => {
    const eventName = 'sound_toggle_action'
    window.onload = () => {
        sessionStorage.removeItem(key)
        EventEmitter.dispatch(eventName, {play: false })
    }

}

