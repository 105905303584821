import SelectOption from "../../../../../../../interfaces/SelectOption";
import { v4 as uuidv4 } from "uuid";
import { WeekModifiersNames, daysOfWeekNames, monthNames, offsetDirectionNames, DayCounterName } from '../../../../../../../resources/constants/dates';
import { add, format, parse, set } from "date-fns";
import { DayOfWeek, SpecialRangeDate, WeekModifier, OffsetDirection } from "@appsinti/stats-utils/src/interfaces/SpecialRangeDatesConfig";
import { DayOfWeekValues, WeekModifierValues, OffsetDirectionValues } from "@appsinti/stats-utils/src/interfaces/SpecialDateValues";
import Translation from '@appsinti/i18n/interfaces/Translation';

export interface SpecialRangeDateState {
  selectedType: "date" | "pattern-offset" | "custom";
  title: Translation;
  fixedRangeDate: { 
    startDate: string,
    endDate: string
  };
  patternRangeDate: {
    offset: SelectOption<number>;
    offsetDirection: SelectOption<OffsetDirection>;
    month: SelectOption<number>;
    weekModifier: SelectOption<WeekModifier>;
    dayOfWeek: SelectOption<DayOfWeek>;
  };
  customRangeDates: { [key: string]: {startDate: string, endDate: string} };
}

type SpecialRangeDateAction =
  | { type: "SET_TITLE"; payload: string }
  | { type: "SET_SELECTED_TYPE"; payload: "date" | "pattern-offset" | "custom" }
  | { type: "SET_FIXED_RANGE_DATE"; payload: {startDate: string, endDate: string} }
  | { type: "SET_PATTERN_RANGE_DATE_MONTH"; payload: SelectOption<number> }
  | { type: "SET_PATTERN_RANGE_DATE_WEEK_MODIFIER"; payload: SelectOption<WeekModifier> }
  | { type: "SET_PATTERN_RANGE_DATE_OF_WEEK"; payload: SelectOption<DayOfWeek> }
  | { type: "SET_PATTERN_RANGE_DATE_OFFSET"; payload: SelectOption<number> }
  | { type: "SET_PATTERN_RANGE_DATE_OFFSET_DIRECTION"; payload: SelectOption<OffsetDirection> }
  | { type: "SET_CUSTOM_RANGE_DATES_DATE"; payload: {key: string, startDate: string, endDate: string} }
  | { type: "REMOVE_CUSTOM_RANGE_DATES_DATE"; payload: string }
  | { type: "ADD_CUSTOM_RANGE_DATES_DATE" }

export const getInitialState = (
  initialSpecialRangeDate?: SpecialRangeDate,
  selectedLang?: string,
): SpecialRangeDateState => {
  const today = format(new Date(), "yyyy-MM-dd");
  const key = uuidv4();
  const lang = !!selectedLang ? selectedLang : 'en';

  const intialState: SpecialRangeDateState = {
    selectedType: "date",
    title: {en: "", es: ""},
    fixedRangeDate: { startDate: today, endDate: today },
    patternRangeDate: {
      month: { value: 1, label: monthNames[1][lang] },
      dayOfWeek: {
        value: DayOfWeekValues.Sunday,
        label: daysOfWeekNames[DayOfWeekValues.Sunday][lang],
      },
      weekModifier: {
        value: WeekModifierValues.First,
        label: WeekModifiersNames[WeekModifierValues.First][lang],
      },
      offset: {value: 1, label: `1 ${DayCounterName[lang].replace('s','')}`},
      offsetDirection: {
        value: OffsetDirectionValues.Before,
        label:offsetDirectionNames[OffsetDirectionValues.Before][lang]
      },
    },
    customRangeDates: { 
      [key]: { startDate: today, endDate: today }, 
    },
  };

  if (initialSpecialRangeDate) {
    intialState.title = initialSpecialRangeDate.title;
    intialState.selectedType = initialSpecialRangeDate.date.type;

    if (initialSpecialRangeDate.date.type === "date") {
      intialState.fixedRangeDate = { 
        startDate: initialSpecialRangeDate.date.startDate, 
        endDate:  initialSpecialRangeDate.date.endDate
      };
    }

    if (initialSpecialRangeDate.date.type === "pattern-offset") {
      intialState.patternRangeDate = {
        month: {
          value: initialSpecialRangeDate.date.month,
          label: monthNames[initialSpecialRangeDate.date.month][lang],
        },
        dayOfWeek: {
          value: initialSpecialRangeDate.date.dayOfWeek,
          label: daysOfWeekNames[initialSpecialRangeDate.date.dayOfWeek][lang],
        },
        weekModifier: {
          value: initialSpecialRangeDate.date.weekModifier,
          label: WeekModifiersNames[initialSpecialRangeDate.date.weekModifier][lang],
        },
        offset: {
          value: initialSpecialRangeDate.date.offset,
          label: String(initialSpecialRangeDate.date.offset)
        },
        offsetDirection: {
          value: initialSpecialRangeDate.date.offsetDirection,
          label: offsetDirectionNames[initialSpecialRangeDate.date.offsetDirection][lang]
        },
      };
    }

    if(initialSpecialRangeDate.date.type === "custom") {
      intialState.customRangeDates = Object.fromEntries(Object.entries(initialSpecialRangeDate.date.dates).sort(([, valueA], [, valueB])=> parse(valueA.startDate, "yyyy-MM-dd", new Date()).getTime() - parse(valueB.startDate, "yyyy-MM-dd", new Date()).getTime()));
    }
  }

  return intialState;
};

export const getSubmitObject = (state: SpecialRangeDateState): SpecialRangeDate | null => {
  if(state.selectedType === "date") {
    return {
      title: state.title,
      date: {
        type: "date",
        startDate: state.fixedRangeDate.startDate,
        endDate: state.fixedRangeDate.endDate,
      }
    }
  }

  if(state.selectedType === "pattern-offset") {
    return {
      title: state.title,
      date: {
        type: "pattern-offset",
        month: state.patternRangeDate.month.value,
        weekModifier: state.patternRangeDate.weekModifier.value,
        dayOfWeek: state.patternRangeDate.dayOfWeek.value,
        offset: state.patternRangeDate.offset.value,
        offsetDirection: state.patternRangeDate.offsetDirection.value,
      }
    }
  }

  if(state.selectedType === "custom") {
    return {
      title: state.title,
      date: {
        type: "custom",
        dates: state.customRangeDates,
      }
    }
  }

  return null;
}

const specialRangeDatesReducer = (
  state: SpecialRangeDateState,
  action: SpecialRangeDateAction
): SpecialRangeDateState => {
  switch (action.type) {
    case "SET_TITLE":{
      const {title} = state;
      title.en = action.payload
      return { ...state, title};}
    case "SET_SELECTED_TYPE":
      return { ...state, selectedType: action.payload };
    case "SET_FIXED_RANGE_DATE":
      return { ...state, fixedRangeDate: { startDate: action.payload.startDate, endDate: action.payload.endDate } };
    case "SET_PATTERN_RANGE_DATE_MONTH":
      return {
        ...state,
        patternRangeDate: { ...state.patternRangeDate, month: action.payload },
      };
    case "SET_PATTERN_RANGE_DATE_OF_WEEK":
      return {
        ...state,
        patternRangeDate: { ...state.patternRangeDate, dayOfWeek: action.payload },
      };
    case "SET_PATTERN_RANGE_DATE_WEEK_MODIFIER":
      return {
        ...state,
        patternRangeDate: { ...state.patternRangeDate, weekModifier: action.payload },
      };
    case "SET_PATTERN_RANGE_DATE_OFFSET": 
      return {
        ...state,
        patternRangeDate: { ...state.patternRangeDate, offset: action.payload },
      };
    case "SET_PATTERN_RANGE_DATE_OFFSET_DIRECTION": 
      return {
        ...state,
        patternRangeDate: { ...state.patternRangeDate, offsetDirection: action.payload },
      }; 
    case "SET_CUSTOM_RANGE_DATES_DATE":
      return { ...state, customRangeDates: { ...state.customRangeDates, [action.payload.key]: { startDate: action.payload.startDate, endDate: action.payload.endDate } } }
    case "REMOVE_CUSTOM_RANGE_DATES_DATE":
      const customRangeDates = {};
      for(const [key, value] of Object.entries(state.customRangeDates)) {
        if(key !== action.payload) {
          customRangeDates[key] = value;
        }
      }
      return { ...state, customRangeDates }
    case "ADD_CUSTOM_RANGE_DATES_DATE":
      const date = Object.values(state.customRangeDates).pop();
      const years = Object.entries(state.customRangeDates).map(([,range]) => parse(range.startDate, "yyyy-MM-dd", new Date()).getFullYear());      
      let nextYear = date && date.startDate ? add(parse(date.startDate, "yyyy-MM-dd", new Date()), { years: 1 }).getFullYear() : new Date().getFullYear();
      
      while(years.includes(nextYear)){ nextYear++; };

      const newCustomDate = {
        startDate: format(date && date.startDate ? set(parse(date.startDate, "yyyy-MM-dd", new Date()), { year: nextYear }) : new Date(), "yyyy-MM-dd"),
        endDate: format(date && date.endDate ? set(parse(date.endDate, "yyyy-MM-dd", new Date()), { year: nextYear }) : new Date(), "yyyy-MM-dd")
      }

      return { 
        ...state, 
        customRangeDates: { 
          ...state.customRangeDates, 
          [uuidv4()]: newCustomDate 
        } 
        }
    default:
      return state;
  }
};

export default specialRangeDatesReducer;