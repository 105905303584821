import React from "react";
import { usePreviewData } from "../data";
import { Ring } from "@uiball/loaders";
import styled from "styled-components";
import MainCard from "@appsinti/ui-core/MainCard";
import Section from "./Section";
import {
  HeightRestriction,
  WidthRestriction,
} from "../../interfaces/Restrictions";

const CustomMainCard = styled(MainCard)`
  >.body {
    gap: 5px;
    padding: 5px;
  }
`;

const Body: React.FC<{
  heightRestriction: HeightRestriction;
  widthRestriction: WidthRestriction;
}> = ({ heightRestriction, widthRestriction }) => {
  const { preview, loading, lastUpdate } = usePreviewData();

  return (
    <CustomMainCard title={"Sales"} lastUpdatedTime={lastUpdate}>
      {loading && !preview ? (
        <div style={{ justifySelf: "center" }}>
          <Ring color={"white"} size={30} />
        </div>
      ) : (
        preview && (
          <Section
            preview={preview}
            heightRestriction={heightRestriction}
            widthRestriction={widthRestriction}
          />
        )
      )}
    </CustomMainCard>
  );
};

export default Body;
