import styled, {keyframes} from 'styled-components';

const Animation = keyframes`
  0% { height: 0px; }
  50% { height: 50%; }
  100% { height: 100%}
`;

const DropdownContainer = styled.div<{background?: string}>`
  left: 0px;
  top: 100%;
  z-index: 2;
  width: 100%;
  height: fit-content;
  position: absolute;

  overflow: hidden;
  padding: 10px 0px;
  background-color: ${ ({background}) => background ? background : 'white' };
  
  &:after {
    content: "";
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 4px;
    display: block;
    position: absolute;
    background: transparent;
    background: linear-gradient(to bottom,rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 100%);
  }
  
`;

export default DropdownContainer;