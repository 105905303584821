import Translation from './interfaces/Translation';
import React, { useState, useEffect, useCallback } from 'react';
import { processTranslations, getTranslation } from './helper';
import { Language } from './interfaces/Language'; 
import { DISPLAY_DATE_FORMAT, getDisplayDate, Language as LanguageLocale, formatDateDistance as getDateDistance } from '@appsinti/common-utils';

const Locales: { [key in Language]: LanguageLocale } = {
    [Language.English]: LanguageLocale.English,
    [Language.Spanish]: LanguageLocale.Spanish, 
}

export type I18nContextType = {
    selectedLang: Language;
    setSelectedLang: (lang: Language) => void;
    translateObject: (value: Translation, lang?: Language ) => string;
    translate: (value: string, vars?: { [key: string]: any }) => string;
    formatDate: (date: string | Date, format?: string) => string;
    formatDateDistance: (startDate: string | Date, endDate: string | Date) => string;
};

export const I18nContext = React.createContext<I18nContextType>({
    selectedLang: Language.English,
    setSelectedLang: () => {},
    translateObject: (value, lang) => value.toString(),
    translate: (value, vars) => value,
    formatDate: (date) => date.toString(),
    formatDateDistance: (startDate, endDate) => startDate.toString() + endDate.toString(),
});

const getLanguageFromStorage = (): Language => {
    try {
        const lang = localStorage.getItem('lang-code');
        if (lang === null || !(lang in Language)) {
            return Language.English;
        }
        return lang as Language;
    } catch {
        return Language.English;
    }
};

const saveLanguageToStorage = (lang: Language) => {
    try {
        localStorage.setItem('lang-code', lang);
    } catch {
        console.error('Local Storage unavailable');
    }
};

const I18nProvider: React.FC<{ children: JSX.Element }> = ({ children }) => {
    const [selectedLang, setSelectedLang] = useState(getLanguageFromStorage);

    useEffect(() => {
        setSelectedLang(getLanguageFromStorage());
    }, []);

    const formatDate: I18nContextType['formatDate'] = useCallback(
        (date, format = DISPLAY_DATE_FORMAT) => {
          return getDisplayDate(date, format, Locales[selectedLang]);
        },
        [selectedLang]
    );

    const formatDateDistance: I18nContextType['formatDateDistance'] = useCallback(
        (startDate, endDate) => {
          return getDateDistance(startDate, endDate, Locales[selectedLang]);
        },
        [selectedLang]
    );

    const values: I18nContextType = {
        selectedLang: selectedLang,
        setSelectedLang: (lang: Language) => {
            setSelectedLang(lang);
            saveLanguageToStorage(lang);
        },
        translateObject: (value: Translation, lang?: Language ) => processTranslations(value, lang || selectedLang),
        translate: (value, vars) => getTranslation(value, vars, selectedLang),
        formatDate,
        formatDateDistance,
    };

    return (<I18nContext.Provider value={values}>{children}</I18nContext.Provider>);
};

export default I18nProvider;