import React from 'react';
import {PrimaryButton, SecondaryButton} from '@etiquette-ui/buttons';

import {MiddleWidth} from '@appsinti/ui-core/styles';
import {Container, CustomRow, Text } from '../../sytles';
import {modifyNameById} from "../../../../../services/sources";
import useI18n from '@appsinti/i18n/useI18n';

type Props = {
  name: string,
  newName: string,
  previousStatus: string,
  nextStatus: string,
  onClose: ()=>void,
  setProcessStatus:React.Dispatch<string>,
  appId: number,
}
const Validation: React.FC<Props> = ({name, newName, previousStatus, nextStatus, onClose, setProcessStatus, id, lang, setSuccess, appId}) => {
  const { translate } = useI18n();
  const setFieldName = async() => {
    const success = await modifyNameById(appId, id, newName)
    setSuccess(success)
    setProcessStatus(nextStatus)
  }

  return (
    <Container>
      <Text>
        {translate('Click on Continue button to complete the renamed')}
        <br /><br />{translate('Current name')}: {name}
        <br /><strong>{translate('New name')}: {newName}</strong>
      </Text>
      <CustomRow>
        <MiddleWidth>
          <SecondaryButton onClick={()=> setProcessStatus(previousStatus)}>{translate('Back and edit')}</SecondaryButton>
        </MiddleWidth>
        <MiddleWidth>
          <PrimaryButton onClick={setFieldName}>{translate('Continue')}</PrimaryButton>
        </MiddleWidth>
      </CustomRow>
    </Container>
  )
}
export default Validation;

