import React from "react";
import CardTemplate from "@appsinti/ui-core/CardTemplate";
import StatsPreview, {
  CompleteStats,
  SalesData,
} from "../../../interfaces/StatsPreview";
import { CustomRow } from "../styles";
import CardTitleComponent from "../CardTitleComponent";
import { MetricData } from "@appsinti/ui-core/styles";
import { RIGHT_DARK } from "@etiquette-ui/colors";
import { formatMoneyMK } from "@appsinti/common-utils";
import styled from "styled-components";

const MainColumn = styled.div`
  &.main {
    display: flex;
    flex-direction: row;
    gap: 10px;

    @media screen and (max-width: 380px) {
      gap: 5px;
    }
  }
`;

const SalesSplits: React.FC<{
  total: CompleteStats;
  lunch: SalesData;
  dinner: SalesData;
}> = ({ total, lunch, dinner }) => {
  return (
    <CardTemplate
      background={"white"}
      noPadding={true}
      header={
        <CustomRow>
          <CardTitleComponent title={"Sales"} />
        </CustomRow>
      }
      body={
        <CustomRow>
          <MainColumn className="main">
            <div className="column">
              <MetricData size={"medium"} weight={"bold"} color={RIGHT_DARK}>
                ALL DAY
              </MetricData>
              <MetricData size={"biggest"} weight={"bold"} color={RIGHT_DARK}>
                {formatMoneyMK(total.totalSales)}
              </MetricData>
            </div>
            <div className="column">
              <MetricData size={"medium"} weight={"bold"} color={RIGHT_DARK}>
                LUNCH
              </MetricData>
              <MetricData size={"biggest"} weight={"bold"} color={RIGHT_DARK}>
                {formatMoneyMK(lunch.totalSales)}
              </MetricData>
            </div>
            <div className="column">
              <MetricData size={"medium"} weight={"bold"} color={RIGHT_DARK}>
                DINNER
              </MetricData>
              <MetricData size={"biggest"} weight={"bold"} color={RIGHT_DARK}>
                {formatMoneyMK(dinner.totalSales)}
              </MetricData>
            </div>
          </MainColumn>
        </CustomRow>
      }
    />
  );
};

export default SalesSplits;
