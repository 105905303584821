import React, {useState, useEffect} from 'react';

import BaseDialog from '@appsinti/ui-core/dialogs/BaseDialog'; 
import {PrimaryButton, SecondaryButton} from '@etiquette-ui/buttons';
import { PRIMARY } from '@etiquette-ui/colors';

import {Option} from '@appsinti/ui-core/dropdown/interfaces/IGroupOption';
import {MiddleWidth} from '@appsinti/ui-core/styles';

import CustomIcon from '@appsinti/ui-core/CustomIcon';
import useI18n from '@appsinti/i18n/useI18n';

import Completed from '../components/Completed';
import {Container, CustomRow, Text } from '../styles';
import { IconContainer, ListItem } from './styles';
import VideoViewer from './components/VideoViewer';
import { Socket } from "socket.io-client";

const STATUS: {[key: string]: string} = {
  'pending': 'pending', 
  'view video': 'view video',
  'completed': 'completed'
};

type ReplacehomeCameraDialog = {
  onClose:()=>void,
  newOption: Option,
  selectedOptions: Option[],
  setSelectedOption: React.Dispatch<React.SetStateAction<Option[] | undefined>>,
  videoSocket: Socket,
}
const ReplacehomeCameraDialog: React.FC<ReplacehomeCameraDialog> = ({onClose, newOption, selectedOptions, setSelectedOption, videoSocket}) => {
  const [processStatus, setProcessStatus] = useState<string>(STATUS['pending']);
  const [editedOptions, setEditedOptions] = useState<{new: Option, old: Option|undefined}>({new: newOption, old: undefined});
  const { translate } = useI18n();
  
  const selectOldOption = (oldOption: Option|undefined) => {
    let options = editedOptions;
    options.old = oldOption;
    setEditedOptions(options);
  }

  const viewCamera = (value: Option) => {
    selectOldOption(value);
    setProcessStatus( STATUS['view video'] ); 
  }

  const changeCamera = () => {
    if(!editedOptions.old) return;

    const remainOptions = selectedOptions.filter(option => option !== editedOptions.old);
    setSelectedOption([...remainOptions, editedOptions.new]);
    setProcessStatus(STATUS['completed']);
  }

  const onCancel = () => {
    selectOldOption(undefined);
    setProcessStatus(STATUS['pending']);
  }

  const getListLabel = (index: number, label: string): string => {
    switch(index) {
      case 0:
        return `${translate('Top camera')}: ${label}`
      case (selectedOptions.length-1):
        return `${translate('Bottom camera')}: ${label}`
      default:
        return `${index} ${translate('camera')}: `;
    }
  }

  return (
    <BaseDialog open={open} onClose={onClose} dismissible={false} isResponsive={true} header={translate('Update Camera')}>
      { (processStatus === STATUS['pending']) && 
        (<Container>
          <Text>
            <strong>{translate('New camera')}: </strong>{newOption.label}
          </Text>
          <Text>
            {translate('You will change the camera view from the Appsinti home. Choose one below to replace:')}
          </Text>

          {selectedOptions.map((selectedOption, index) => (
            <ListItem key={selectedOption.value}>
              <input type="radio" id={selectedOption.value} name="cameraListItem" value={selectedOption.value}></input>
              <Text onClick={()=> selectOldOption(selectedOption)}>
                <label htmlFor={selectedOption.value}>
                  {getListLabel(index, selectedOption.label)}
                </label>
              </Text>
              <IconContainer onClick={() => viewCamera(selectedOption)}>
                <CustomIcon icon={'eye-on'} color={PRIMARY}/>
              </IconContainer>
            </ListItem>
          ))}
          
          <CustomRow style={{justifyContent: 'space-between'}}>
            <MiddleWidth>
              <SecondaryButton onClick={()=>{onClose(); selectOldOption(undefined);}}>{translate('Cancel')}</SecondaryButton>
            </MiddleWidth>
            <MiddleWidth>
              <PrimaryButton onClick={changeCamera}>{translate('Update')}</PrimaryButton>
            </MiddleWidth>
          </CustomRow>   
        </Container>
        )}

      { (!!editedOptions.old && processStatus === STATUS['view video']  ) && 
        (<VideoViewer
            option={editedOptions.old}
            changeCamera={changeCamera}
            onCancel={onCancel}
            videoSocket={videoSocket}
         />)}

      { (processStatus === STATUS['completed']) && 
        (<Completed 
          onClose={onClose} 
          isSuccess={true}
        />)}
      
    </BaseDialog>
  )
}
export default ReplacehomeCameraDialog;