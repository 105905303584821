import React from "react";
import ToggleCalendar from "./ToggleCalendar";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  padding-bottom: 7px;
`;

const FixedDateSection: React.FC<{
  date: string;
  setDate: (date: Date) => void;
}> = ({ date, setDate }) => {
  return (
    <Container>
      <ToggleCalendar date={date} setDate={setDate} dateFormat='MMMM dd' />
    </Container>    
  );
};

export default FixedDateSection;
