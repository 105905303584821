import CardTemplate from "@appsinti/ui-core/CardTemplate";
import React from "react";
import CardTitleComponent from "../CardTitleComponent";
import { CustomRow } from "../styles";
import { MetricData } from "@appsinti/ui-core/styles";
import { formatPercentage } from "@appsinti/common-utils";
import { WRONG_DARK } from "@etiquette-ui/colors";
import StatsPreview from "../../../interfaces/StatsPreview";
import styled from "styled-components";

const MainColumn = styled.div`
  &.main {
    display: flex;
    flex-direction: column;
    gap: 15px;

    @media screen and (max-width: 380px) {
      gap: 10px;
    }
  }
`;

const CourtesyCancellations: React.FC<{ preview: StatsPreview }> = ({
  preview,
}) => {
  return (
    <CardTemplate
      background={"white"}
      body={
        <CustomRow>
          <MainColumn className="main">
            <div className="column">
            <MetricData size={"medium"} weight={"bold"}>CANCEL</MetricData>
              <MetricData size={"biggest"} weight={"bold"} color={WRONG_DARK}>
                {formatPercentage(preview.total.cancellation, 1)}
              </MetricData>
            </div>
            <div className="column">
            <MetricData size={"medium"} weight={"bold"}>COURTESY</MetricData>
              <MetricData size={"biggest"} weight={"bold"} color={WRONG_DARK}>
                {formatPercentage(preview.total.courtesy, 1)}
              </MetricData>
            </div>
          </MainColumn>
        </CustomRow>
      }
    />
  );
};

export default CourtesyCancellations;
