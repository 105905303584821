import styled from "styled-components";

import {Row, MiddleWidth} from '@appsinti/ui-core/styles';

export const Container = styled.div`
  width: 100%;
  min-width: 30rem;
  max-width: 35rem;
`;

export const CustomRow = styled(Row)`
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1.5rem;

  ${MiddleWidth} {
    max-width: 48%;
  }
`;

export const Text = styled.div<{color?:string}>`
  font-size: 1.4rem;
  margin-bottom: 1.6rem;

  color: ${({color})=> color ? color : 'black'};

  strong {
    font-weight: 600;
  }
`;
