import React, { useEffect } from "react";
import Body from "@appsinti/stats-frontend/home/components/Body";
import LineChart from "@appsinti/stats-frontend/home/components/charts/line";
import { useData } from "../../data";

const Cost: React.FC = () => {
  const { costs, fetchCosts } = useData();

  useEffect(() => {
    fetchCosts();
  }, []);

  return (
    <Body>
      <LineChart
        dataObject={costs?.monthly_total}
        lineOptions={{
          xAxis: {
            hasTextAsValue: true,
            min: costs?.monthly_total?.xAxis?.min,
            max: costs?.monthly_total?.xAxis?.max,
          },
          yAxis: {
            hasTextAsValue: false,
            min: costs?.monthly_total?.yAxis?.min,
            max: costs?.monthly_total?.yAxis?.max,
          },
          isTimeBased: costs?.monthly_total.isTimeBased,
          timeOptions: {
            unit: "day",
            displayFormats: { day: "EEEEEE" },
          },
        }}
      />
      <LineChart
        dataObject={costs?.monthly_food_bar}
        lineOptions={{
          xAxis: {
            hasTextAsValue: true,
            min: costs?.monthly_food_bar?.xAxis?.min,
            max: costs?.monthly_food_bar?.xAxis?.max,
          },
          yAxis: {
            hasTextAsValue: false,
            min: costs?.monthly_food_bar?.yAxis?.min,
            max: costs?.monthly_food_bar?.yAxis?.max,
          },
          isTimeBased: costs?.monthly_food_bar.isTimeBased,
          timeOptions: {
            unit: "day",
            displayFormats: { day: "EEEEEE" },
          },
        }}
      />
      <LineChart
        dataObject={costs?.monthly_food_bar_pct}
        lineOptions={{
          xAxis: {
            hasTextAsValue: true,
            min: costs?.monthly_food_bar_pct?.xAxis?.min,
            max: costs?.monthly_food_bar_pct?.xAxis?.max,
          },
          yAxis: {
            hasTextAsValue: false,
            min: costs?.monthly_food_bar_pct?.yAxis?.min,
            max: costs?.monthly_food_bar_pct?.yAxis?.max,
          },
          isTimeBased: costs?.monthly_food_bar_pct.isTimeBased,
          timeOptions: {
            unit: "day",
            displayFormats: { day: "EEEEEE" },
          },
        }}
      />
    </Body>
  );
};
export default Cost;
