import styled from 'styled-components';

const Container = styled.header`
  width: 100%;
  padding: 13px 15px;

  display: flex;
  align-items: center;

  background-color: white;
`;

export default Container;