import React, {useEffect, useState} from 'react';
import {useAlert, ALERT_TYPES} from '@etiquette-ui/alert-dialog';
import CustomIcon from '@appsinti/ui-core/CustomIcon';
import EditIcon from '@appsinti/ui-core/resources/img/edit-icon-alt.svg';

import useI18n from '@appsinti/i18n/useI18n';
import { useData } from '../../../data';

import Video from './Video';
import EditNameDialog from '../../../dialogs/EditNameDialog/index';

import { IconContainer } from '../../../styles';
import {Container, Header, Title, Body} from '../styles';

import IArea from '@appsinti/bigbrother-frontend/interfaces/IArea';
import { useCollapse } from 'react-collapsed';

const Card: React.FC<{area:IArea, isCollapsedInit?:boolean, isTitleEditable?:boolean}> = ({area, isTitleEditable = true, isCollapsedInit=false}) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(isCollapsedInit);
  const { translateObject } = useI18n();
  const alert = useAlert();
  const { appId } = useData();
  const [title, setTitle] = useState<string>(translateObject(area.name))
  const { getCollapseProps } = useCollapse({
    isExpanded: !isCollapsed,
  });

  useEffect(()=>{
    setTitle(translateObject(area.name));
  },[area.name]);

  const showEditAreaDialog = () => {
    alert({
      type: ALERT_TYPES.CUSTOM,
      config: {
        props: {
          id: area.id,
          name: title,
          appId,
        },
        body: EditNameDialog,
      },
    }).then((name: string)=>{
      setTitle(name)
    })
  }

  return (
    <Container key={area.id}>
      <Header>
        {isTitleEditable && (
          <IconContainer onClick={() => showEditAreaDialog()} isLeftPosition={true}>
            <CustomIcon icon={EditIcon} isImage={true} />
          </IconContainer>
        )}
        <Title>
          {`${title} (${area.cameras.length})`}
        </Title>
        <IconContainer className='--is-right --is-collapsable' isCollapsable={!isCollapsed} onClick={()=>setIsCollapsed(!isCollapsed)}>
          <CustomIcon icon='chevron-down'/>
        </IconContainer>
      </Header>
      <div {...getCollapseProps({})}>
        <Body>
          {area.cameras.map(({id, locationName, previewImageURL, areaId})=>(
            <Video key={id} areaId={!!areaId ? areaId : area.id} title={translateObject(locationName)} id={id} preview={previewImageURL} appId={appId} />
          ))}
        </Body>
      </div>
    </Container>
  )
}
export default Card;
