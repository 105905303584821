import React from "react";
import { Route, Routes } from "react-router-dom";
import Main from "./pages/Main";
import Edit from "./pages/Edit";

const EditSpecialDays: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="day/:dayId?" element={<Edit />} />
    </Routes>
  );
};

export default EditSpecialDays;
