import { toFixed } from "@appsinti/common-utils";
import Translation from "@appsinti/i18n/interfaces/Translation";
import type { ChartOptions } from "chart.js";

export type BarOptions = {
  isHorizontal?: boolean;
  isStacked?: boolean;
  stackLabels?: { [key: string]: Translation };
  isPercentageSet?: boolean;
  isTimeBased?: boolean;
  xAxis?: {
    min?: number | string;
    max?: number | string;
    hasTextAsValue?: boolean;
    showLabels?: boolean;
  };
  yAxis?: {
    min?: number | string;
    max?: number | string;
    hasTextAsValue?: boolean;
    showLabels?: boolean;
  };
  timeOptions?: {
    unit?:
      | false
      | "millisecond"
      | "second"
      | "minute"
      | "hour"
      | "day"
      | "week"
      | "month"
      | "quarter"
      | "year";
    displayFormats?: {
      millisecond?: string;
      second?: string;
      minute?: string;
      hour?: string;
      day?: string;
      week?: string;
      month?: string;
      quarter?: string;
      year?: string;
    };
  };
};

export const getOptions: (quotes: BarOptions) => ChartOptions<"bar"> = ({
  isHorizontal = false,
  isStacked = false,
  isPercentageSet = false,
  xAxis = { hasTextAsValue: false, showLabels: true },
  yAxis = { hasTextAsValue: false, showLabels: true },
  isTimeBased = false,
  timeOptions,
}) => {
  const options: ChartOptions<"bar"> = {
    responsive: true,
    indexAxis: isHorizontal ? ("y" as const) : ("x" as const),
    scales: {
      x: {
        type: isHorizontal ? "linear" : isTimeBased ? "time" : "category",
        time: timeOptions,
        grid: {
          display: false,
        },
        stacked: isStacked,
        ticks:
          isTimeBased || xAxis.hasTextAsValue
            ? {}
            : {
                callback: function (val) {
                  if (typeof val === "string") return val;
                  return formattNumber(val);
                },
                display: xAxis.showLabels,
              },
        bounds: "data",
      },
      y: {
        stacked: isStacked,
        ticks: yAxis.hasTextAsValue
          ? {}
          : {
              callback: function (val) {
                if (typeof val === "string") return val;
                return formattNumber(val);
              },
              display: yAxis.showLabels,
            },
        min: yAxis.min,
        max: yAxis.max,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "bottom" as const,
      },
      title: {
        display: false,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "xy",
          threshold: 1,
        },
        zoom: {
          wheel: {
            enabled: false,
          },
          pinch: {
            enabled: true,
          },
          mode: "xy",
        },
      },
      datalabels: {
        display: true,
        anchor: "end",
        align: isHorizontal ? "end" : "top",
        color: "#454546",
        font: { size: 10 },
        formatter: (value: any, context: any) => {
          const formattedValue = isPercentageSet ? `${value.toFixed(2)}%` : formattNumber(value);
          if (isHorizontal) {
            const label = context.chart.data.labels
            ? context.chart.data.labels[context.dataIndex]
            : "";
      
            return `${formattedValue} - ${label}`;
          }

          return formattNumber(value);
        },
      },
    },
  };

  if(!!options.scales?.x && options.scales?.x?.type !== 'linear') {
    options.scales.x.min = xAxis.min;
    options.scales.x.max = xAxis.max;
  }

  return options;
};

const formattNumber = (number: number) => {
  if(typeof number === 'string')
    return number;
  if(Math.abs(number)>=1000000){
    return `${toFixed(number/1000000, 1)}M`
  } else if(Math.abs(number)>1000){
    return `${toFixed(number/1000, 1)}K`
  } else {
    return toFixed(number, 1);
  }
}