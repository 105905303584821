import React, {useEffect, useState} from "react";
import styled from "styled-components";
import PlayIcon from "../../../../resources/play.png"
import { useData } from "../../../data";

const Image = styled.div<{ src: string }>`
  width: 90vw;
  height: 45vw;
  background-image: url(${({src}) => src});
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`


const Preview: React.FC<{ url: string, onClick: any }> = ({url, onClick}) => {
    const [updatedUrl, setUrl] = useState<string>(url);
    const { lastUpdate } = useData();

    useEffect(()=>{
        setUrl(`${url}?t=${Date.now()}`)
    }, [lastUpdate]);

    return (
        <Image src={updatedUrl} onClick={onClick}>
            <img src={PlayIcon} alt="play" style={{width: '50px', height: '50px', zIndex: 10}}/>
        </Image>
    )
}

export default Preview
