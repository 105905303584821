import React from "react";
import { PreviewDataProvider } from "./data";
import Body from "./components/Body";
import {
  HeightRestriction,
  WidthRestriction,
} from "../interfaces/Restrictions";

const Preview: React.FC<{
  appId: number;
  heightRestriction: HeightRestriction;
  widthRestriction: WidthRestriction;
}> = ({ appId, heightRestriction, widthRestriction }) => {
  return (
    <PreviewDataProvider appId={appId}>
      <Body
        heightRestriction={heightRestriction}
        widthRestriction={widthRestriction}
      />
    </PreviewDataProvider>
  );
};

export default Preview;
