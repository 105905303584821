import React, { useEffect, useMemo } from "react";

import COLORS from "@appsinti/stats-frontend/resources/constants/colors";
import IChartData from "@appsinti/stats-frontend/interfaces/IChartData";
import { CustomCard, LegendContainer, Label } from "../styles";
import Header from "../components/Header";
import Loader from "../components/Loader";

import useI18n from "@appsinti/i18n/useI18n";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale,
} from "chart.js";
import { Line } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import type { ChartData, ScriptableLineSegmentContext } from "chart.js";
import "chartjs-adapter-date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale,
  zoomPlugin
);

import { LineOptions, getOptions } from "./options";

type DottedSegmentOptions = {
  hasADottedSegment?: boolean,
  dottedSegmentType?: 'complete' | 'segment',
  dottedSegmentAlignment?: 'start' | 'end',
}

type LineChart = {
  dataObject: IChartData | null;
  lineOptions: LineOptions;
  dottedSegmentOptions?: DottedSegmentOptions;
  isLoading?: boolean;
  showLegend?: boolean;
  editLocation?: string;
};

const shouldBeDotted = (dottedSegmentOptions: DottedSegmentOptions, isCurrent: boolean | undefined, nonNullArray: number[], ctx: ScriptableLineSegmentContext) => {
  const lastValidIndex = nonNullArray[nonNullArray.length - 1];

  if(dottedSegmentOptions.hasADottedSegment && dottedSegmentOptions.dottedSegmentType === 'complete' && isCurrent)  return true;
  if(dottedSegmentOptions.hasADottedSegment && dottedSegmentOptions.dottedSegmentType === 'segment' && isCurrent) {
    if(dottedSegmentOptions.dottedSegmentAlignment === 'start' && ctx.p0DataIndex === 0) return true;
    if(dottedSegmentOptions.dottedSegmentAlignment === 'end' && (ctx.p1DataIndex === lastValidIndex || ctx.p0DataIndex === lastValidIndex) ) return true;
  }
  return false;
}

const borderDashCallback = (dottedSegmentOptions: DottedSegmentOptions, isCurrent: boolean | undefined, nonNullArray: number[]) => (ctx: ScriptableLineSegmentContext): number[] | undefined => {
  const dotted = shouldBeDotted(dottedSegmentOptions, isCurrent, nonNullArray, ctx);

  return dotted ? [2,5] : undefined;
}

const borderCapStyleCallback = (dottedSegmentOptions: DottedSegmentOptions, isCurrent: boolean | undefined, nonNullArray: number[]) => (ctx: ScriptableLineSegmentContext): 'round' | undefined => {
  const dotted = shouldBeDotted(dottedSegmentOptions, isCurrent, nonNullArray, ctx);

  return dotted ? "round" : undefined;
}

const borderWidthCallback = (dottedSegmentOptions: DottedSegmentOptions, isCurrent: boolean | undefined, nonNullArray: number[]) => (ctx: ScriptableLineSegmentContext): number | undefined => {
  const dotted = shouldBeDotted(dottedSegmentOptions, isCurrent, nonNullArray, ctx);

  return dotted ? 2.3 : undefined;
}



const LineChart: React.FC<LineChart> = ({
  dataObject,
  lineOptions,
  dottedSegmentOptions = { hasADottedSegment: false },
  isLoading = false,
  showLegend = true,
  editLocation,
}) => {
  const { translateObject, translate } = useI18n();

  const customLabels = useMemo(() => {
    if (dataObject?.isTimeBased && dataObject?.realLabels && dataObject?.labels) {
      return dataObject.labels.reduce((acc, labelDate, index) => {
        acc[labelDate] = translateObject(dataObject.realLabels[index]); 
        return acc;
      }, {} as { [key: number]: any });
    }
    return {};
  }, [dataObject]);  
  const options = useMemo(() => getOptions(lineOptions, customLabels), [lineOptions, customLabels]);

  const data = useMemo(() => {
    if (!dataObject) return null;

    const data: ChartData<"line", number[], string | number> = {
      labels: dataObject.isTimeBased
        ? dataObject.labels
        : dataObject.labels.map((label) => translateObject(label)),
      datasets: dataObject.datasets.map((dataset, index, datasets) => {
        const { data, label } = dataset;
        const nonNullIndex = dataset.data.map((value, index) => value !== null ? index : null).filter((value): value is number => value !== null);
        return ({
        data: data,
        label:
          typeof label === "string" ||
          typeof label === "undefined"
            ? label
            : translateObject(label),
        backgroundColor: "#FFF",
        fill: true,
        borderColor:
          COLORS[
            (COLORS.length - ((datasets.length - index) % COLORS.length)) %
              COLORS.length
          ],
        borderWidth: 1.4,
        borderDash: [],
        segment: {
          borderDash: borderDashCallback(dottedSegmentOptions, dataset.isCurrent, nonNullIndex),
          borderCapStyle: borderCapStyleCallback(dottedSegmentOptions, dataset.isCurrent, nonNullIndex),
          borderWidth: borderWidthCallback(dottedSegmentOptions, dataset.isCurrent, nonNullIndex),
        }
      })}),
    };

    return data;
  }, [dataObject, dottedSegmentOptions]);

  return (
    <CustomCard
      background="white"
      header={
        <Header
          title={
            isLoading === false && !!dataObject
              ? translateObject(dataObject.title)
              : translate("Loading...")
          }
          subtitle={
            !!dataObject && dataObject.subtitle
              ? translateObject(dataObject.subtitle)
              : ""
          }
          to={editLocation}
        />
      }
      body={
        isLoading === false && !!data && !!data.datasets ? (
          <>
            <Line options={options} data={data} height={250} />
            {showLegend && (
              <LegendContainer>
                {data.datasets.map(
                  (dataset, index) =>
                  !!dataset.label && (
                    <Label
                      key={dataset.label}
                      color={dataset.borderColor?.toString() ?? ""}
                    >
                      {dataset.label}
                    </Label>
                  )
                )}
              </LegendContainer>
            )}
          </>
        ) : (
          <Loader />
        )
      }
    />
  );
};
export default LineChart;
