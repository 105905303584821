import React from 'react';
import { PrimaryButton, SecondaryButton } from '@etiquette-ui/buttons';
import BaseDialog from './BaseDialog';

import {TextBody, ButtonContainer, Footer} from './styles';

const Actions:React.FC<{onClose:()=>void, onSubmit:()=>void, closeButtonText: string, confirmButtonText:string}> = ({ onClose, onSubmit, closeButtonText, confirmButtonText }) => {
  return (
    <Footer>
      <ButtonContainer>
        <SecondaryButton type='text' onClick={onClose}>{closeButtonText}</SecondaryButton>
      </ButtonContainer>
      <ButtonContainer>
        <PrimaryButton onClick={onSubmit}>{confirmButtonText}</PrimaryButton>
      </ButtonContainer>
    </Footer>
  );
}

type InfoDialog = {
  textBody: string,
  title?: string,
  onClose: ()=>void,
  onSubmit: ()=>void,
  closeButtonText: string,
  confirmButtonText: string
}
const InfoDialog: React.FC<InfoDialog> = ({textBody, title, onClose, onSubmit, closeButtonText, confirmButtonText}) => {
    return (
    <BaseDialog
      open={open}
      header={title}
      onClose={onClose}
      isResponsive={false}
      dismissible={false}
      footer={<Actions onClose={onClose} onSubmit={() => { onSubmit();onClose()}} closeButtonText={closeButtonText} confirmButtonText={confirmButtonText}/>}
    >
      <TextBody>{textBody}</TextBody>
      
    </BaseDialog>
  )
};
export default InfoDialog;

