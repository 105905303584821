import ES from "./dictionaries/es";
import EN from "./dictionaries/en";
import Translation from "./interfaces/Translation";
import { Language } from './interfaces/Language';

const VAR_REGEX = /%[A-Za-z0-9-_ ]*%/g;

const dictionaries: { [key in Language]: { [key: string]: string } } = {
    [Language.Spanish]: ES,
    [Language.English]: EN,
};

const hasVariables = (string: string) => {
    return [...Array.from(string.matchAll(VAR_REGEX))].length!==0;
}

export const getTranslation = (key: string, vars:{ [key: string]: any } = {}, lang: Language) => {
    const dictionary = dictionaries[lang];

    if(!dictionary[key]){ 
        console.info("TRANSLATION NOT FOUND FOR: ", `'${key}'`);
        return `**${key}**`;
    }

    if(!hasVariables(dictionary[key])) return dictionary[key];

    return dictionary[key].replaceAll(VAR_REGEX, (match)=> vars[match.replaceAll('%','')] ?? '');
}

export const processTranslations = (value: Translation, langKey: Language): string => {
    return value[langKey];
}