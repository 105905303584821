import React, { useEffect } from "react";
import { default as MainHeaderApp } from "@appsinti/ui-core/header";
import { ArrowLeftIcon } from "@etiquette-ui/icons";
import { useData } from "../../../../../../data";
import LineChart from "../../../../../../components/charts/line";
import SpecialDayItem from "../../components/SpecialDayItem";
import Loader from "../../../../../../components/charts/components/Loader";
import Card from "../../../../containers/Card";
import Content from "../../../../containers/Content";
const { parseDate } = require("@appsinti/stats-utils");
import NewItemButton from "../../../../components/NewItemButton";

const Main: React.FC = () => {
  const {
    sales,
    fetchSales,
    fechSpecialDaysConfig,
    specialDaysConfig,
  } = useData();

  useEffect(() => {
    fechSpecialDaysConfig();
    fetchSales()
  }, []);
  
  return (
    <>
      <MainHeaderApp
        title={"Edit Special Days"}
        icon={<ArrowLeftIcon styles={{}} />}
        backButtonLocation="../.."
      />
      <Content>
        {sales?.specialDays && (
          <LineChart
            dataObject={sales?.specialDays}
            lineOptions={{
              xAxis: {
                hasTextAsValue: true,
                min: sales.specialDays.xAxis?.min,
                max: sales.specialDays.xAxis?.max,
              },
              yAxis: {
                hasTextAsValue: false,
                min: sales.specialDays.yAxis?.min,
                max: sales.specialDays.yAxis?.max,
              },
              isTimeBased: sales?.specialDays.isTimeBased,
              timeOptions: {
                unit: "day",
                displayFormats: { day: "EEEEEE" },
              },
            }}
            dottedSegmentOptions={{
              hasADottedSegment: true,
              dottedSegmentType: "complete",
            }}
          />
        )}
        <Card
          body={
            specialDaysConfig ? (
              <>
                {Object.entries(specialDaysConfig).sort(([, valueA], [, valueB])=>{
                  const calculateValueA = parseDate(valueA.date)?.getTime() ?? 0;
                  const calculateValueB = parseDate(valueB.date)?.getTime() ?? 0;
                  return calculateValueA - calculateValueB;
                }).map(([key, value]) => (
                  <SpecialDayItem key={key} specialDay={value} dayId={key} />
                ))}
                <NewItemButton to="day" />
              </>
            ) : (
              <Loader />
            )
          }
        />
      </Content>
    </>
  );
};

export default Main;
