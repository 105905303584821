import React, {useState} from 'react';
import styled from 'styled-components';
import { parse, format } from "date-fns";
import useI18n from '@appsinti/i18n/useI18n';
import { PRIMARY, WRONG_LIGHT } from "@etiquette-ui/colors";
import { Calendar } from "@etiquette-ui/calendars";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Row = styled.div`
  max-width: 100%;
  flex: 0 0 100%;
  padding: 0px;
  display: flex;
  padding: 0px 5px;
`;

const Label = styled.p`
  font: normal normal normal 14px/18px Montserrat;
  text-align: left;
  margin: auto;
  margin-left: 0px;
`;

const Input = styled.span`
  font: normal normal normal 14px/18px Montserrat;
  color: ${PRIMARY};
  font-weight: 600;
  text-align: right;
`;

const CalendarContainer = styled.div<{isOpen?:boolean}>`
  max-width: 100%;
  flex: 0 0 100%;
  top: 100%;
  left: 0px;
  right: 0px;
  display: flex;
  position: relative;
  justify-content: center;
  background: white;
  overflow: hidden;
  transition: height .5s ease;
  height: ${({isOpen}) => !!isOpen ? '265px' : '0px'};

  @media (min-width: 768px) {
    height: ${({isOpen}) => !!isOpen ? '290px' : '0px'};
  }
`;

const Error = styled.div`
  padding: 5px;
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: -5px;
  font-weight: 600;
  font-size: 1.3rem;
  color: ${WRONG_LIGHT};
`;

const ToggleCalendar: React.FC<{
  date: string;
  setDate: (date: Date) => void;
  dateFormat?: string;
  error?: string;
}>  = ({date, setDate, dateFormat = 'MMMM dd, yyyy', error = ''}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [localDate, setLocalDate] = useState<Date>(parse(date, 'yyyy-MM-dd', new Date()));
  const {translateObject, selectedLang} = useI18n();

  const onChange = (date: Date) => {
    setLocalDate(date);
    setDate(date);
  }

  return (
    <Container>
      <Row onClick={()=>setIsOpen(!isOpen)}>
        <Label>{translateObject({en: 'On', es: 'El'})}</Label>
        <Input>{format(localDate, dateFormat)}</Input>
        
      </Row>
      {!!error && (<Row><Error>{error}</Error></Row>)}
      <CalendarContainer isOpen={isOpen}>
        <Calendar
          language={selectedLang}
          selected={parse(date, 'yyyy-MM-dd', new Date())}
          formatDate={"MMMM dd"}
          onChange={(date: Date)=>onChange(date)}
          utcOffset={9}
          containerProps={{
            style: {              
              paddingTop: '10px',
            }
          }}
        />
      </CalendarContainer>      
    </Container>
  )
}
export default ToggleCalendar;