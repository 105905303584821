import { ArrowLeftIcon } from "@etiquette-ui/icons";
import React, { useReducer, useMemo, useState } from "react";
import { default as MainHeaderApp } from "@appsinti/ui-core/header";
import Content from "../../../../containers/Content";
import Card from "../../../../containers/Card";
import PrimaryButton, { DangerButton } from "@etiquette-ui/buttons";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import specialRangeDatesReducer, { SpecialRangeDateState, getInitialState, getSubmitObject } from "./specialRangeDatesReducer";
import NameSection from "../../../../components/NameSection";
import SectionContainer from "../../../../containers/SectionContainer";
import SectionTitle from "../../../../components/SectionTitle";
import FixedDateSection from "./components/FixedRangeDateSection";
import PatternRangeDateSection from "./components/PatternRangeDateSection";
import SelectOption from "../../../../../../../interfaces/SelectOption";
import CustomRangeDateSection from "./components/CustomRangeDateSection";
import SpecialRangeDatesConfig, { DayOfWeek, WeekModifier, OffsetDirection } from "@appsinti/stats-utils/src/interfaces/SpecialRangeDatesConfig";
import validateForm from "./validators";
import { useData } from "../../../../../../data";
import { Ring } from "@uiball/loaders";
import useI18n from "@appsinti/i18n/useI18n";
import { Dropdown } from "@etiquette-ui/inputs-dropdowns";

const translations = {
  'Specific Date Range': {en:'Specific Date Range',es: 'Rango de fecha único'},
  'Offset Date Range': {en:'Offset Date Range',es: 'Rango por días compensados'},
  'Custom Date Range': {en:'Custom Date Range',es: 'Rangos diversos'},
  'Date selection': {en:'Date selection',es: 'Selección de rango'},
  'Recurrence Type': {en:'Recurrence Type',es: 'Frecuencia'},
  'Add Special Date Range': {en:'Add Special Date Range',es: 'Agregar Nuevo Rango'},
  'Edit Special Date Range': {en:'Edit Special Date Range',es: 'Editar Rango'},
}

const Edit: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {translate, translateObject, selectedLang} = useI18n();

  const options = [
    {value: 'date', label: translateObject(translations['Specific Date Range'])},
    {value: 'pattern-offset', label: translateObject(translations['Offset Date Range'])},
    {value: 'custom', label: translateObject(translations['Custom Date Range'])}
  ];

  const locationState = location.state as
    | { rangeDate: SpecialRangeDatesConfig, rangeDateId: string }
    | undefined;
  const [state, dispatch] = useReducer(
    specialRangeDatesReducer,
    getInitialState(locationState?.rangeDate, selectedLang)
  );
  const { addSpecialRangeDate, updateSpecialRangeDate, deleteSpecialRangeDate, loading } = useData();
  const [errors, setErrors] = useState<undefined|{[key:string]:string}>(undefined);

  const isEditMode = useMemo(()=>!!locationState?.rangeDate, [locationState]);

  const setSelectedType = (date: "date" | "pattern-offset" | "custom") =>
    dispatch({ type: "SET_SELECTED_TYPE", payload: date });

  const isFormValid = useMemo(()=>{
    if(errors === undefined || Object.keys(errors).length > 0) return false;
    return validateForm(state);
  },[state, errors]);

  const onSubmit = async (state: SpecialRangeDateState) => {        
    const submitObject = getSubmitObject(state);
    if(submitObject === null) { 
      alert("Invalid information");
      return;
    }
    try {
      if(!!locationState?.rangeDateId) {
        await updateSpecialRangeDate(submitObject, locationState.rangeDateId)
      } else {
        await addSpecialRangeDate(submitObject)
      }
      navigate(-1);
    } catch (e) {
      alert(e);
    } 
  };
  
  const onDelete = async (rangeDateId: string) => {
    try {
      await deleteSpecialRangeDate(rangeDateId);
      navigate(-1);
    } catch (e) {
      alert(e);
    }
  }

  return (
    <>
      <MainHeaderApp
        title={translateObject(translations[`${isEditMode ? "Edit Special Date Range" : "Add Special Date Range"}`])}
        icon={<ArrowLeftIcon styles={{}} />}
        backButtonLocation=".."
      />
      <Content>
        <NameSection
          title={state.title.en}
          setTitle={(title: string) =>
            dispatch({ type: "SET_TITLE", payload: title })
          }
        />
        <Card
          body={
            <SectionContainer>
              <SectionTitle>{translateObject(translations['Date selection'])}</SectionTitle>

              <Dropdown
                labelProps={{
                  label: translateObject(translations['Recurrence Type'])
                }}
                selectProps={{
                  defaultValue: options[0],
                  options: options,
                  onChange: (option:{value: "date" | "pattern-offset" | "custom", label:string})=>setSelectedType(option.value),
                  styles: {
                    singleValue: (base) => ({
                      ...base,
                      fontSize: 'var(--font-size)',
                    }),
                    option: (base) => ({
                      ...base,
                      fontSize: '14px',
                    }),
                  }
                }}
                valueContainerProps={{
                  style: {
                    padding: '15px'
                  }
                }}
                containerProps={{
                  styles: {
                    zIndex: '2',
                  }
                }}
                labelFontSize="12px"
                fontBaseSize="14px"
              />

              {
                {
                  'date': (<FixedDateSection
                    dates={state.fixedRangeDate}
                    setDates={(startDate: Date, endDate) =>
                      dispatch({
                        type: "SET_FIXED_RANGE_DATE",
                        payload: {startDate: format(startDate, "yyyy-MM-dd"), endDate: format(endDate, "yyyy-MM-dd")},
                      })
                    }
                  />),
                  'pattern-offset': (<PatternRangeDateSection
                    weekModifier={state.patternRangeDate.weekModifier}
                    month={state.patternRangeDate.month}
                    dayOfWeek={state.patternRangeDate.dayOfWeek}
                    offset={state.patternRangeDate.offset}
                    offsetDirection={state.patternRangeDate.offsetDirection}
                    setOffset={(days: SelectOption<number>) =>
                      dispatch({
                        type: "SET_PATTERN_RANGE_DATE_OFFSET",
                        payload: days,
                      })
                    }
                    setOffsetDirection={(time: SelectOption<OffsetDirection>) =>
                      dispatch({
                        type: "SET_PATTERN_RANGE_DATE_OFFSET_DIRECTION",
                        payload: time,
                      })
                    }
                    setMonth={(month: SelectOption<number>) =>
                      dispatch({
                        type: "SET_PATTERN_RANGE_DATE_MONTH",
                        payload: month,
                      })
                    }
                    setDayOfWeek={(dayOfWeek: SelectOption<DayOfWeek>) =>
                      dispatch({
                        type: "SET_PATTERN_RANGE_DATE_OF_WEEK",
                        payload: dayOfWeek,
                      })
                    }
                    setWeekModifier={(weekModifier: SelectOption<WeekModifier>) =>
                      dispatch({
                        type: "SET_PATTERN_RANGE_DATE_WEEK_MODIFIER",
                        payload: weekModifier,
                      })
                    }
                  />),
                  'custom': (<CustomRangeDateSection 
                    dates={state.customRangeDates}
                    setDate={(key: string, startDate: Date, endDate: Date) =>
                      dispatch({
                        type: "SET_CUSTOM_RANGE_DATES_DATE",
                        payload: { key, startDate: format(startDate, "yyyy-MM-dd"), endDate: format(endDate, "yyyy-MM-dd") },
                      })
                    }
                    removeDate={(key: string) => 
                      dispatch({
                        type: "REMOVE_CUSTOM_RANGE_DATES_DATE",
                        payload: key,
                      })
                    }
                    addDate={()=>
                      dispatch({
                        type: "ADD_CUSTOM_RANGE_DATES_DATE",
                      })
                    }
                    errors={errors}
                    setErrors={setErrors}
                  />)

                }[state.selectedType]
              }
            </SectionContainer>
          }
        />
        <PrimaryButton className={""} disabled={!isFormValid || loading} type="button" onClick={() => onSubmit(state)}>
          {loading ? <Ring color={"white"} size={30} /> : translate(!!locationState?.rangeDateId ? "Update" : "Save")}
        </PrimaryButton>
        {!!locationState?.rangeDateId && <DangerButton className={""} disabled={loading} type="button" onClick={() => onDelete(locationState?.rangeDateId)}>
          {translate('Delete')}
        </DangerButton>}
      </Content>
    </>
  );
};

export default Edit;
