import React from 'react';
import styled from 'styled-components';
import Icon from '@etiquette-ui/icons';
import { PSmall, Subtitle } from '@etiquette-ui/typography';

import LogoSVG from 'resources/img/quince-logo.svg';

const Container = styled.div`
  background-color: white;
  height: auto;
  border-radius: 8px 0px 0px 8px;
  box-shadow: 0px -4px 12px #03326914;
  padding: 20px 16px;
  padding-left: 4rem;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  p {
    font-weight: 600;
  }
`;

const Logo = styled.img`
  width: auto;
  height: 30px;
  margin-right: 10px;
`;

const Close = styled.div`
  width: 20px;
  height: 20px;
`;

const ProfileContainer = styled.div`
  padding-top: 3rem;
  padding-bottom: 0.5rem;
`;

const UserName = styled(Subtitle)`
  font-size: 2.4rem;
  line-height: normal;
  margin-bottom: 5px;
`;

const Email = styled(PSmall)`
  font-size: 1.4rem;
  line-height: normal;
`;

const MenuHeader: React.FC<{ toggle: ()=>void }> = ({toggle}) => {
  const auth = JSON.parse(localStorage.getItem('auth') ?? '');

  return (
    <Container>
      <TitleContainer>
        <LogoContainer>
          <Logo src={LogoSVG} alt={'Dependency-logo'} />
        </LogoContainer>
        <Close onClick={toggle} >
          <Icon icon={'wrong'} styles={{ line: { fill: 'none', strokeWidth:'1.5px', stroke:'black' } }}></Icon>
        </Close>
      </TitleContainer>
      <ProfileContainer>
          <div>
            {auth.name && <UserName className={''}>{auth.name}</UserName>}
            {auth.email && <Email className={''}>{auth.email}</Email>}
          </div>
      </ProfileContainer>
    </Container>
  );
};

export default MenuHeader;
