import Axios from "axios";
import { BACKEND } from "../constants/config";
import { ISource } from "../interfaces/source";

const url = `${BACKEND}/appsinti/sound`
const sourceKey = "sound_source"

export const getSources = async (appId: number): Promise<ISource[]> => {
    try {
        const response = (await Axios.get<ISource>(url, {
            headers: {
                "app-id": appId
            }
        }))
        return response.data as ISource[]
    } catch (error) {
        return []
    }
}

export const modifyNameById = async (appId, id, name): Promise<boolean> => {
    try {
        await Axios.patch(url, { id, name }, {
            headers: {
                "app-id": appId
            }
        })
        return true
    } catch (e) {
        return false
    }
}

export const setSelectedSource = (source: ISource) => {
    localStorage.setItem(sourceKey, JSON.stringify(source))
}

export const getSelectedSource = (): ISource | null => {
    const rawSource = localStorage.getItem(sourceKey)
    if (!rawSource) return null
    return JSON.parse(rawSource)
}
