import {BaseDialog as EtiquetteBaseDialog} from '@etiquette-ui/alert-dialog'; 
import { BG } from '@etiquette-ui/colors';
import styled from 'styled-components';

const BaseDialog = styled(EtiquetteBaseDialog)`
  > div:first-child {
    border-radius: 1.2rem;
    overflow: hidden;

    > img:nth-child(1){
      top: 1.2rem;
    }

    > div {

      &:nth-child(2) {
        background-color: ${BG};
        display: block;
        * {
          text-align: left;
          font-weight: 600;
          color: white;
          font-size: 1.5rem;
        }
      }

      &:nth-child(3) {
        padding: 0px;

        > div:first-child {
          padding: 1.5rem;
          margin: 0px;
        }
      }
    }
  }
`;
export default BaseDialog;