import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { DataProvider } from "./data";
import { RoutesProvider, useRoutes } from "./routes";

const RoutesList: React.FC = () => {
  const { routes } = useRoutes();
  return (
    <Routes>
      {routes.map(({ path, Component }) => (
        <Route key={path} path={`${path}/*`} element={<Component />} />
      ))}
      <Route path="/" element={<Navigate to={routes[0].path} />} />
    </Routes>
  );
};

const Home: React.FC<{ appId: number }> = ({ appId }) => {
  return (
    <RoutesProvider appId={appId}>
      <RoutesList />
    </RoutesProvider>
  );
};

const HomeContainer: React.FC<{
  config: {
    backendUrl: string;
    backendHost: string;
    appId: number;
    groupId: number | null;
  };
}> = ({ config }) => {
  return (
    <DataProvider
      url={config.backendUrl}
      host={config.backendHost}
      appId={config.appId}
      groupId={config.groupId}
    >
      <Home appId={config.appId} />
    </DataProvider>
  );
};

export default HomeContainer;
