import React from "react";
import ToggleCalendar from "./ToggleRangeCalendar";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  padding-bottom: 7px;
`;

const FixedDateSection: React.FC<{
  dates: {startDate: string, endDate: string};
  setDates: (startDate: Date, endDate: Date) => void;
}> = ({ dates, setDates }) => {
  return (
    <Container>
      <ToggleCalendar dates={dates} setDates={setDates} dateFormat='MMMM dd' />
    </Container>    
  );
};

export default FixedDateSection;
