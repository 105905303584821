import React, { useEffect, useMemo } from "react";
import Body from "@appsinti/stats-frontend/home/components/Body";
import BarChart from "@appsinti/stats-frontend/home/components/charts/bar";
import LineChart from "@appsinti/stats-frontend/home/components/charts/line";
import { useData } from "../../../../data";
import { Ring } from "@uiball/loaders";
import { BG_DARK } from "@etiquette-ui/colors";
import COLORS from "@appsinti/stats-frontend/resources/constants/colors";
import Navbar from "../../../../components/Navbar";

const Graph: React.FC = () => {
  const { sales, fetchSales, loading } = useData();

  useEffect(() => {
    fetchSales();
  }, []);

  const rangeDataStacks = useMemo(() => {
    if (!sales?.rangeDates) return [] as string[];
    const stacks = new Set<string>();
    for (const set of sales.rangeDates.datasets) {
      if (typeof set.stack === "string") stacks.add(set.stack);
    }
    return Array.from(stacks.values());
  }, [sales?.rangeDates]);

  return sales && !loading ? (
    <Body>
      <LineChart
        dataObject={sales?.month}
        lineOptions={{
          xAxis: {
            hasTextAsValue: true,
            min: sales.month.xAxis?.min,
            max: sales.month.xAxis?.max,
          },
          yAxis: {
            hasTextAsValue: false,
            min: sales.month.yAxis?.min,
            max: sales.month.yAxis?.max,
          },
          isTimeBased: sales.month.isTimeBased,
          timeOptions: {
            unit: "day",
            displayFormats: { day: "EEEEEE" },
          },
        }}
        dottedSegmentOptions={{
          hasADottedSegment: true,
          dottedSegmentType: "segment",
          dottedSegmentAlignment: "end",
        }}
      />
      <LineChart
        dataObject={sales?.dayWeek}
        lineOptions={{
          xAxis: {
            hasTextAsValue: true,
            min: sales.dayWeek.xAxis?.min,
            max: sales.dayWeek.xAxis?.max,
          },
          yAxis: {
            hasTextAsValue: false,
            min: sales.dayWeek.yAxis?.min,
            max: sales.dayWeek.yAxis?.max,
          },
          isTimeBased: sales.dayWeek.isTimeBased,
          timeOptions: { unit: "day", displayFormats: { day: "EEEEEE" } },
        }}
        dottedSegmentOptions={{
          hasADottedSegment: true,
          dottedSegmentType: "complete",
        }}
      />
      <LineChart
        dataObject={sales?.week}
        lineOptions={{
          xAxis: {
            hasTextAsValue: true,
            min: sales.week.xAxis?.min,
            max: sales.week.xAxis?.max,
            reverse: true,
          },
          yAxis: {
            hasTextAsValue: false,
            min: sales.week.yAxis?.min,
            max: sales.week.yAxis?.max,
          },
          isTimeBased: sales?.week.isTimeBased,
          timeOptions: {
            unit: "day",
            displayFormats: { day: "EEEEEE" },
          },
        }}
        dottedSegmentOptions={{
          hasADottedSegment: true,
          dottedSegmentType: "segment",
          dottedSegmentAlignment: "start",
        }}
      />
      <LineChart
        dataObject={sales?.daily}
        lineOptions={{
          xAxis: {
            hasTextAsValue: true,
            min: sales.daily.xAxis?.min,
            max: sales.daily.xAxis?.max,
          },
          yAxis: {
            hasTextAsValue: false,
            min: sales.daily.yAxis?.min,
            max: sales.daily.yAxis?.max,
          },
          isTimeBased: sales?.daily.isTimeBased,
          timeOptions: {
            unit: "day",
            displayFormats: { day: "EEEEEE" },
          },
        }}
      />
      <BarChart
        dataObject={sales?.dailyEmployee}
        barOptions={{
          isHorizontal: true,
          yAxis: {
            showLabels: false,
          },
        }}
        isOnlyOneColor={true}
      />
      <BarChart
        dataObject={sales?.weeklyEmployee}
        barOptions={{
          isHorizontal: true,
          yAxis: {
            showLabels: false,
          },
        }}
        isOnlyOneColor={true}
      />
      <LineChart
        dataObject={sales?.specialDays}
        lineOptions={{
          xAxis: {
            hasTextAsValue: true,
            min: sales.specialDays.xAxis?.min,
            max: sales.specialDays.xAxis?.max,
          },
          yAxis: {
            hasTextAsValue: false,
            min: sales.specialDays.yAxis?.min,
            max: sales.specialDays.yAxis?.max,
          },
          isTimeBased: sales?.specialDays.isTimeBased,
          timeOptions: {
            unit: "day",
            displayFormats: { day: "EEEEEE" },
          },
        }}
        dottedSegmentOptions={{
          hasADottedSegment: true,
          dottedSegmentType: "complete",
        }}
        editLocation={"edit-special-days"}
      />
      <LineChart
        dataObject={sales?.weekYearly}
        lineOptions={{
          xAxis: {
            hasTextAsValue: true,
            min: sales.weekYearly.xAxis?.min,
            max: sales.weekYearly.xAxis?.max,
          },
          yAxis: {
            hasTextAsValue: false,
            min: sales.weekYearly.yAxis?.min,
            max: sales.weekYearly.yAxis?.max,
          },
          isTimeBased: sales?.weekYearly.isTimeBased,
          timeOptions: {
            unit: "day",
            displayFormats: { day: "EEEEEE" },
          },
        }}
        dottedSegmentOptions={{
          hasADottedSegment: true,
          dottedSegmentType: "segment",
          dottedSegmentAlignment: "start",
        }}
      />
      <BarChart
        dataObject={sales?.rangeDates}
        barOptions={{
          isStacked: true,
          stackLabels: sales.rangeDates.stackLabels,
          isTimeBased: sales.rangeDates.isTimeBased,
          timeOptions: { unit: "year", displayFormats: { month: "yyyy" } },
          xAxis: {
            min: sales?.rangeDates.xAxis?.min,
            max: sales?.rangeDates.xAxis?.max,
          },
          yAxis: {
            min: sales?.rangeDates.yAxis?.min,
            max: sales?.rangeDates.yAxis?.max,
          },
        }}
        colorCallback={(index, dataset) => {
          if (!dataset.stack) return COLORS[0];
          const stackIndex = rangeDataStacks.indexOf(dataset.stack);
          return COLORS[stackIndex % COLORS.length];
        }}
        editLocation={"edit-range-dates"}
      />
      {/* <ComparableChart /> */}
    </Body>
  ) : (
    <Body style={{ display: "flex", justifyContent: "center" }}>
      <Ring color={BG_DARK} size={50} />
    </Body>
  );
};

export default Graph;
