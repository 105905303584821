import React from "react";

import IconTextItem from "@appsinti/ui-core/IconTextItem";

import WeatherIcon from "./resources/img/weather-icon.svg";

import useI18n from "@appsinti/i18n/useI18n";

import HomeComponent from "./home";

const Home = HomeComponent;

export const Preview: React.FC = () => {
  const { translate } = useI18n();

  return (
    <IconTextItem
      icon={WeatherIcon}
      isImage={true}
      body={<>{translate("Weather")}</>}
    />
  );
};

export default Home;
