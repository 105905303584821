import React, {useContext} from 'react';
import styled from 'styled-components';
import {SwitchButton as SuperSwitchButton} from '@etiquette-ui/selectors';
import {SoundContext} from "./providers/soundProvider";

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .label {
    text-transform: uppercase;
    font-size: 1.4rem;
    color: inherit;
  }
`;

const SwitchButton: React.FC<{ isActive: boolean, setIsActive: (newState: boolean) => void }>
    = ({isActive, setIsActive}) => {

    return (
        <Container onClick={() => setIsActive(!isActive)}>
            <div className="label">
                {isActive ? 'ON' : 'OFF'}
            </div>
            <SuperSwitchButton active={isActive}></SuperSwitchButton>
        </Container>
    )
}

export default SwitchButton;
