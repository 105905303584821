import React, {useEffect, useState} from 'react';
import {PrimaryButton, SecondaryButton} from '@etiquette-ui/buttons';

import {Container, CustomRow, Text } from '../../styles';
import {FullWidth, MiddleWidth} from '@appsinti/ui-core/styles';
import VideoUI from '../../../../dialogs/CameraDialog/VideoUI';
import { Option } from '@appsinti/ui-core/dropdown/interfaces/IGroupOption';

import useI18n from '@appsinti/i18n/useI18n';
import { Socket } from "socket.io-client";

type VideoViewer ={
  option: Option,
  changeCamera: () => void,
  videoSocket: Socket,
  onCancel: ()=>void,
}
const VideoViewer: React.FC<VideoViewer> = ({changeCamera, onCancel, option, videoSocket}) => {
  const { translate } = useI18n();
  const [muted, setMuted] = useState<boolean>(true);
  const [withAudio, setWithAudio] = useState<boolean>(false);
  const [streamReady, setStreamReady] = useState<boolean>(false);

  return (
    <Container>
      <CustomRow>
        <FullWidth>
          <Text>
            <strong>{translate('Viewing')}: </strong> {option.label}
          </Text>
        </FullWidth>
        <FullWidth>
          {option.parentId && (<VideoUI
            areaId={option.parentId}
            cameraId={option.value}
            muted={muted}
            setWithAudio={setWithAudio}
            streamReady={streamReady}
            setStreamReady={setStreamReady}
            setMuted={setMuted}
            videoSocket={videoSocket}
          />)}
        </FullWidth>
      </CustomRow>
      <CustomRow style={{justifyContent: 'space-between'}}>
        <MiddleWidth>
          <PrimaryButton onClick={onCancel}>{translate('Back')}</PrimaryButton>
        </MiddleWidth>
        <MiddleWidth>
          <SecondaryButton onClick={changeCamera}>{translate('Select and change')}</SecondaryButton>
        </MiddleWidth>
      </CustomRow>
    </Container>
  )
}
export default VideoViewer;