import useWindowSize from "customHooks/useWindowSize";
import React, { useEffect, useState } from "react";
import Body from "./components/Body";
import Header from "./components/header";
import { useApps } from "pages/home/AppProvider";
import { useParams } from "react-router-dom";

const Grid: React.FC = () => {
  const [, windowHeight] = useWindowSize();
  const [headerHeight, setHeaderHeight] = useState<number | undefined>(0);
  const [bodyHeight, setBodyHeight] = useState<number>(0);
  const { groupId } = useParams<{ groupId: string }>();
  const { selectGroup } = useApps();

  useEffect(() => {
    if (headerHeight !== undefined && headerHeight >= 0 && windowHeight >= 0) {
      setBodyHeight(windowHeight - headerHeight);
    }
  }, [windowHeight, headerHeight]);

  useEffect(() => {
    if (!groupId) {
      selectGroup(null);
      return;
    }
    selectGroup(groupId);
  }, [groupId, selectGroup]);

  return (
    <>
      <Header setHeaderHeight={setHeaderHeight} headerHeight={headerHeight} />
      <Body bodyHeight={bodyHeight} />
    </>
  );
};

export default Grid;
