import { ArrowLeftIcon } from "@etiquette-ui/icons";
import React, { useReducer, useMemo, useState } from "react";
import { default as MainHeaderApp } from "@appsinti/ui-core/header";
import Content from "../../../../containers/Content";
import Card from "../../../../containers/Card";
import PrimaryButton, { DangerButton } from "@etiquette-ui/buttons";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import specialDayReducer, { SpecialDayState, getInitialState, getSubmitObject } from "./specialDayReducer";
import NameSection from "../../../../components/NameSection";
import SectionContainer from "../../../../containers/SectionContainer";
import SectionTitle from "../../../../components/SectionTitle";
import FixedDateSection from "./components/FixedDateSection";
import PatternDateSection from "./components/PatternDateSection";
import SelectOption from "../../../../../../../interfaces/SelectOption";
import CustomDateSection from "./components/CustomDateSection";
import { DayOfWeek, SpecialDay, WeekModifier } from "@appsinti/stats-utils/interfaces/SpecialDaysConfig";
import validateForm from "./validators";
import { useData } from "../../../../../../data";
import { Ring } from "@uiball/loaders";
import useI18n from "@appsinti/i18n/useI18n";
import { Dropdown } from "@etiquette-ui/inputs-dropdowns";

const translations = {
  'Specific Date': {en:'Specific Date',es: 'Una fecha única'},
  'Day Of The Week': {en:'Day Of The Week',es: 'Un día de la semana'},
  'Custom Date': {en:'Custom Date',es: 'Una fecha personalizada'},
  'Date selection': {en:'Date selection',es: 'Tipo de fecha'},
  'Recurrence Type': {en:'Recurrence Type',es: 'Frecuencia'},
  'Add Special Day': {en:'Add Special Day',es: 'Agregar Nueva Fecha'},
  'Edit Special Day': {en:'Edit Special Day',es: 'Editar Fecha'},
}

const Edit: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {translate, translateObject, selectedLang} = useI18n();

  const options = [
    {value: 'date', label: translateObject(translations['Specific Date'])},
    {value: 'pattern', label: translateObject(translations['Day Of The Week'])},
    {value: 'custom', label: translateObject(translations['Custom Date'])}
  ];

  const locationState = location.state as
    | { specialDay: SpecialDay, dayId: string }
    | undefined;
  const [state, dispatch] = useReducer(
    specialDayReducer,
    getInitialState(locationState?.specialDay, selectedLang)
  );
  const { updateSpecialDay, addSpecialDay, loading, deleteSpecialDay } = useData();
  const [errors, setErrors] = useState<undefined|{[key:string]:string}>(undefined);

  const isEditMode = useMemo(()=>!!locationState?.specialDay, [locationState]);

  const setSelectedType = (date: "date" | "pattern" | "custom") =>
    dispatch({ type: "SET_SELECTED_TYPE", payload: date });

  const isFormValid = useMemo(()=>{
    if(errors === undefined || Object.keys(errors).length > 0) return false;

    return validateForm(state);
  },[state, errors]);

  const onSubmit = async (state: SpecialDayState) => {
    const submitObject = getSubmitObject(state);
    if(submitObject === null) { 
      alert("Invalid information");
      return;
    }
    try {
      if(!!locationState?.dayId) {
        await updateSpecialDay(submitObject, locationState.dayId)
      } else {
        await addSpecialDay(submitObject)
      }
      navigate(-1);
    } catch (e) {
      alert(e);
    }
  };
  
  const onDelete = async (dayId: string) => {
    try {
      await deleteSpecialDay(dayId);
      navigate(-1);
    } catch (e) {
      alert(e);
    }
  }

  return (
    <>
      <MainHeaderApp
        title={translateObject(translations[`${isEditMode ? "Edit Special Day" : "Add Special Day"}`])}
        icon={<ArrowLeftIcon styles={{}} />}
        backButtonLocation=".."
      />
      <Content>
        <NameSection
          title={state.title}
          setTitle={(title: string) =>
            dispatch({ type: "SET_TITLE", payload: title })
          }
        />
        <Card
          body={
            <SectionContainer>
              <SectionTitle>{translateObject(translations['Date selection'])}</SectionTitle>

              <Dropdown
                labelProps={{
                  label: translateObject(translations['Recurrence Type'])
                }}
                selectProps={{
                  defaultValue: options[0],
                  options: options,
                  onChange: (option:{value: "date" | "pattern" | "custom", label:string})=>setSelectedType(option.value),
                  styles: {
                    singleValue: (base) => ({
                      ...base,
                      fontSize: 'var(--font-size)',
                    }),
                    option: (base) => ({
                      ...base,
                      fontSize: '14px',
                    }),
                  }
                }}
                valueContainerProps={{
                  style: {
                    padding: '15px'
                  }
                }}
                containerProps={{
                  styles: {
                    zIndex: '2',
                  }
                }}
                labelFontSize="12px"
                fontBaseSize="14px"
              />

              {
                {
                  'date': (<FixedDateSection
                    date={state.fixedDay?.date}
                    setDate={(date: Date) =>
                      dispatch({
                        type: "SET_FIXED_DAY",
                        payload: format(date, "yyyy-MM-dd"),
                      })
                    }
                  />),
                  'pattern': (<PatternDateSection
                    weekModifier={state.patternDay.weekModifier}
                    month={state.patternDay.month}
                    dayOfWeek={state.patternDay.dayOfWeek}
                    setMonth={(month: SelectOption<number>) =>
                      dispatch({
                        type: "SET_PATTERN_DAY_MONTH",
                        payload: month,
                      })
                    }
                    setDayOfWeek={(dayOfWeek: SelectOption<DayOfWeek>) =>
                      dispatch({
                        type: "SET_PATTERN_DAY_OF_WEEK",
                        payload: dayOfWeek,
                      })
                    }
                    setWeekModifier={(weekModifier: SelectOption<WeekModifier>) =>
                      dispatch({
                        type: "SET_PATTERN_DAY_WEEK_MODIFIER",
                        payload: weekModifier,
                      })
                    }
                  />),
                  'custom': (<CustomDateSection 
                    dates={state.customDates}
                    setDate={(key: string, date: Date) =>
                      dispatch({
                        type: "SET_CUSTOM_DATES_DATE",
                        payload: { key, date: format(date, "yyyy-MM-dd") },
                      })
                    }
                    removeDate={(key: string) => 
                      dispatch({
                        type: "REMOVE_CUSTOM_DATES_DATE",
                        payload: key,
                      })
                    }
                    addDate={()=>
                      dispatch({
                        type: "ADD_CUSTOM_DATES_DATE",
                      })
                    }
                    errors={errors}
                    setErrors={setErrors}
                  />)

                }[state.selectedType]
              }
            </SectionContainer>
          }
        />
        <PrimaryButton className={""} disabled={!isFormValid || loading} type="button" onClick={() => onSubmit(state)}>
          {loading ? <Ring color={"white"} size={30} /> : translate(!!locationState?.dayId ? "Update" : "Save")}
        </PrimaryButton>
        {!!locationState?.dayId && <DangerButton className={""} disabled={loading} type="button" onClick={() => onDelete(locationState?.dayId)}>
          {translate('Delete')}
        </DangerButton>}
      </Content>
    </>
  );
};

export default Edit;
