import React, {useState, useMemo} from "react";
import styled from "styled-components";
import { AddIcon, DeleteIcon } from "@etiquette-ui/icons";
import { TextButton } from "@etiquette-ui/buttons";
import { WRONG, INPUTS  } from "@etiquette-ui/colors";
import useI18n from "@appsinti/i18n/useI18n";
import ToggleRangeCalendar from './ToggleRangeCalendar';
import Translation from '@appsinti/i18n/interfaces/Translation';
import { parse} from 'date-fns';

const errorsMessages: {[key: string]: Translation} = {
  sameYear: {en: 'Range must be around the same year', es: 'Range must be around the same year'},
  duplicatedYear: {en: 'The year you’ve chosen is already in use. Please select a unique year for each entry.', es: 'The year you’ve chosen is already in use. Please select a unique year for each entry.'}
}

const CustomDatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  p.label {
    text-align: left;
    font: normal normal normal 14px/18px Montserrat;
  }

  .button button {
    height: unset;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 2px;
    justify-content: center;
  }

  .date-container {
    display: flex;
    align-items: start;
    position: relative;
    padding-bottom: 10px;

    .calendar-container{
      margin-top: 7px;
      &.__trashIcon {
      margin-right: auto;
      max-width: 90%;
    }}

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 0px;
      border-top: 1px solid ${INPUTS};
      z-index: 0;
    }
  }

  .button-container {
    width: 28px;
    margin-left: -5px;
  }
`;

const CustomRangeDateSection: React.FC<{
  dates: { [key: string]: {startDate: string, endDate: string} };
  setDate: ( key: string, startDate: Date, endDate: Date ) => void;
  removeDate: ( key: string ) => void;
  addDate: () => void;
  errors: undefined|{[key:string]:string};
  setErrors: (errors: {}|{[key:string]:string})=>void;
}> = ({ dates, setDate, removeDate, addDate, errors, setErrors }) => {
  const {translate, translateObject} = useI18n();  

  const takenYears = useMemo(()=>{
    let years = {};
    for(const [key, {startDate,}] of Object.entries(dates)){
      years[parse(startDate, "yyyy-MM-dd", new Date()).getFullYear()] = key;
    }
    return years;
  },[dates]);

  const onChange = (key: string, startDate: Date, endDate: Date) => {

    if(!!takenYears[startDate.getFullYear()] && takenYears[startDate.getFullYear()] !== key) {
      setErrors({...errors, [key]: translateObject(errorsMessages.duplicatedYear)});
      return;
    }
    if(startDate.getFullYear() !== endDate.getFullYear()){      
      setErrors({...errors, [key]: translateObject(errorsMessages.sameYear)});
      return;      
    }
    
    setDate(key, startDate, endDate);
    const currentErrors = !!errors ? errors : {};
    delete currentErrors[key];
    setErrors(currentErrors);
  }

  return (
    <div>
      <CustomDatesContainer>
        {Object.entries(dates).map(([key, date]) => (
          <div className="date-container" key={key}>
            <div className={`calendar-container ${Object.values(dates).length > 1 ? '__trashIcon' : ''}`} >
              <ToggleRangeCalendar 
                dates={date} 
                setDates={(startDate: Date, endDate: Date) => onChange(key, startDate, endDate)} 
                disabledRestrictedSpan={false}
                error={!!errors && !!errors[key] ? errors[key] : ''}
              />
            </div>
            {Object.values(dates).length > 1 && (
              <div className="button-container">
                <TextButton
                  className={"button"}
                  disabled={false}
                  type="button"
                  onClick={() => removeDate(key)}
                >
                  <DeleteIcon
                    styles={{
                      svg: { width: "20px", height: "20px"},
                      line: {
                        fill: 'none',
                        strokeLinecap:'round',
                        strokeLinejoin:'round',
                        strokeWidth:'1.5px',
                        stroke: WRONG
                    }
                    }}
                  />
                </TextButton>
              </div>
            )}
            
          </div>
        ))}
        <TextButton
          className={"button"}
          disabled={false}
          type="button"
          onClick={addDate}
        >
          <AddIcon styles={{ svg: { width: "20px", height: "20px" } }} />
          {translate('Add')}
        </TextButton>
      </CustomDatesContainer>
    </div>
  );
};

export default CustomRangeDateSection;
