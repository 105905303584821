import React from 'react';
import styled from 'styled-components';
import { BG_DARK } from '@etiquette-ui/colors';
import { Ring } from '@uiball/loaders';

const Container = styled.div`
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loader: React.FC = () => {
  return (
    <Container>
      <Ring
        color={BG_DARK}
        size={20}
      />
    </Container>
  )
}

export default Loader;
