import React from "react";
import styled from "styled-components";

import { NavItem } from "@etiquette-ui/selectors";
import { BG_DARK } from "@etiquette-ui/colors";

import CustomLink from "@appsinti/ui-core/CustomLink";

import useI18n from "@appsinti/i18n/useI18n";
import { useLocation, useMatch, useResolvedPath } from "react-router-dom";
import { useRoutes } from "../routes";

const Container = styled.nav`
  display: flex;
  justify-content: center;

  padding: 1rem 2rem 0.5rem;
  border-radius: 0px 0px 2.4rem 2.4rem;

  position: fixed;
  z-index: 1;
  left: 0px;
  right: 0px;

  background-color: ${BG_DARK};

  user-select: none;
`;

const CustomNavItemComponent = styled(NavItem)<{ isActive?: boolean }>`
  text-align: left;
  min-width: auto;

  &,
  * {
    font-size: 1.4rem;
    font-weight: ${({ isActive }) => (isActive ? 600 : 400)};
  }

  div > div:first-of-type {
    height: 2px;
    margin-top: 1px;
    width: 4rem;
  }
`;

const CustomNavItem = ({ path, children }) => {
  const location = useLocation();
  return (
    <CustomNavItemComponent
      className={""}
      isActive={cleanURL(location.pathname).includes(cleanURL(path)) ? true : false}
    >
      {children}
    </CustomNavItemComponent>
  );
};

const Navbar: React.FC = () => {
  const { translateObject } = useI18n();
  const { routes } = useRoutes();
  const location = useLocation();

  return (
    <Container>
      {routes.map(({ path, title }, i) => (
        <CustomNavItem key={i} path={path}>
          <CustomLink to={replaceLastPath(location.pathname, cleanURL(path))}>
            {translateObject(title)}
          </CustomLink>
        </CustomNavItem>
      ))}
    </Container>
  );
};

const replaceLastPath = (pathname: string, newLastSegment: string) => {
  const pathSegments = cleanURL(pathname).split("/");
  pathSegments[pathSegments.length - 1] = newLastSegment;
  return pathSegments.join("/");
};

const cleanURL = (pathname: string) => {
  let path = pathname;
  if(path.endsWith("*")) path = path.slice(0, -1);
  if(path.endsWith("/")) path = path.slice(0, -1);

  return path;
}

export default Navbar;
