import CardTemplate from "@appsinti/ui-core/CardTemplate";
import React from "react";
import CardTitleComponent from "../CardTitleComponent";
import { CustomRow } from "../styles";
import { MetricData } from "@appsinti/ui-core/styles";
import { formatPercentage } from "@appsinti/common-utils";
import { WRONG_DARK } from "@etiquette-ui/colors";
import StatsPreview from "../../../interfaces/StatsPreview";

const CourtesyCancellations: React.FC<{ preview: StatsPreview }> = ({
  preview,
}) => {
  return (
    <CardTemplate
      background={"white"}
      header={
        <CustomRow>
          <CardTitleComponent title={"Cancel"} />
          <CardTitleComponent title={"Courtesy"} />
        </CustomRow>
      }
      body={
        <CustomRow>
          <div className="column">
            <MetricData size={"bigger"} weight={"bold"} color={WRONG_DARK}>
              {formatPercentage(preview.total.cancellation, 1)}
            </MetricData>
          </div>
          <div className="column">
            <MetricData size={"bigger"} weight={"bold"} color={WRONG_DARK}>
              {formatPercentage(preview.total.courtesy, 1)}
            </MetricData>
          </div>
        </CustomRow>
      }
    />
  );
};

export default CourtesyCancellations;
