import { Row } from "@appsinti/ui-core/styles";
import styled from "styled-components";

export const CustomRow = styled(Row)`
  text-align: center;
  gap: 2px;
  display: flex;
  margin: 0;
  padding: 0;
  div {
    display: flex;
    flex: 1;
    display: flex;
    gap: 5px;

    &.column {
      flex-direction: column;
    }
    &.row {
      flex-direction: row;
    }

    span {
      flex: 1;
    }

    @media screen and (max-width: 380px) {
      gap: 3px;
    }
  }
`;
