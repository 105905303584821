import { BACKEND } from "./resources/constants/urls";
import socketIOClient, { Socket } from "socket.io-client";
import React, {
  useState,
  useEffect,
  PropsWithChildren,
  createContext,
  useContext,
} from "react";

interface ISocketContext {
  socket: Socket | undefined;
}

const SocketContext = createContext<ISocketContext>({
  socket: undefined
});

const SocketProvider: React.FC<PropsWithChildren<{ appId: number }>> = ({ appId, children }) => {
  const [socket, setSocket] = useState<Socket | undefined>(undefined);

  useEffect(() => {
    const socket = socketIOClient(BACKEND, { 
      path : "/appsinti.socket",
      extraHeaders: {
        "app-id": `${appId}`,
      }
     });
    socket.off('connect').on('connect', () => {
      console.log('socket connected');
    });
    socket.off('connect_error').on("connect_error", (err) => {
      console.log('connect_error', err);
    });
    setSocket(socket);

    return function cleanup() {
      socket.disconnect();
    };
  }, []);

  useEffect(()=>{console.log('socket', socket)},[socket]);

  return (
    <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>
  );
};

export const useSocket = (): ISocketContext => {
  const context = useContext(SocketContext);
  if (context === undefined) {
    throw new Error("useSocket must be used within a SocketProvider");
  }
  return context;
};

export default SocketProvider;
