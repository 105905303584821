import React, { useEffect } from 'react';
import styled from 'styled-components';

import IconTextItem from '@appsinti/ui-core/IconTextItem';
import { Container, Row } from '@appsinti/ui-core/styles';

import SoundIcon from '../resources/img/sound-icon.svg';
import Selector from './Selector';
import AlertServiceProvider from '@etiquette-ui/alert-dialog';
import AudioController from "../containers/AudioController";
import { default as UUID } from "node-uuid"
import { clearPlayer } from "../services/player";

const FirstColumn = styled.div`
  max-width: 70%;
  flex: 0 0 70%;
`;
const SecondColumn = styled.div`
  max-width: 30%;
  flex: 0 0 30%;
`;

const Editor: React.FC<{
    appId: number;
  }> = ({ appId }) => {
    useEffect(() => {
        clearPlayer()
    }, [])

    return (
        <AlertServiceProvider>
            <IconTextItem
                icon={SoundIcon}
                isImage={true}
                body={<Container>
                    <Row>
                        <FirstColumn>
                            Audio
                        </FirstColumn>
                        <SecondColumn>
                            <AudioController source={"editor"} instanceId={UUID.v4()} />
                        </SecondColumn>
                    </Row>
                </Container>}
            />
            <Selector appId={appId}/>
        </AlertServiceProvider>
    )
}

export default Editor;
