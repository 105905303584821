import { useAccess } from "providers/access";
import React from "react";
import { Navigate, Route, useLocation } from "react-router-dom";
import { getToken } from "resources/api/auth";

interface PrivateRouteInterface {
  groupId?: number;
  access?: string;
  userType?: string;
  component: React.ComponentType<any>;
}

const useAuth = (
  groupId: number | null,
  access: string | null,
  userType: string | null
) => {
  const { hasAccess, hasAccessInGroup } = useAccess();
  const token = getToken();

  if (!token) {
    return { isAuthenticated: false, isAuthorized: false };
  }

  const isAuthorized =
    access === null && userType === null
      ? true
      : groupId
      ? hasAccessInGroup(groupId, access, userType)
      : hasAccess(access, userType);

  return { isAuthenticated: true, isAuthorized };
};

const PrivateRoute: React.FC<PrivateRouteInterface> = ({
  groupId = null,
  access = null,
  userType = null,
  component: Component,
}) => {
  const location = useLocation();
  const { isAuthenticated, isAuthorized } = useAuth(groupId, access, userType);

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (!isAuthorized) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return <Component />;
};

export default PrivateRoute;
