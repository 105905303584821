import { toFixed } from '@appsinti/common-utils';
import { Language } from '@appsinti/i18n/interfaces/Language';
import type {ChartOptions, TooltipItem} from 'chart.js';
import { enUS, es } from 'date-fns/locale';
import {format, parse} from 'date-fns'

const Locales: { [key in Language]: Locale } = {
  [Language.English]: enUS,
  [Language.Spanish]: es,
}

type Axis = {
  min?: number | string,
  max?: number | string,
  hasTextAsValue?: boolean,
  reverse ?: boolean
};

export type LineOptions = {
  isStacked?: boolean,
  isPercentageSet?: boolean,
  isTimeBased?: boolean,
  xAxis?: Axis,
  yAxis?: Axis,
  timeOptions?: {
    unit?: false | "millisecond" | "second" | "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year",
    displayFormats?: {
      millisecond?: string,
      second?: string,
      minute?: string,
      hour?: string,
      day?: string,
      week?: string,
      month?: string,
      quarter?: string,
      year?: string,
    }
  },
  language?: Language,
}

type CustomLabels = {
  [key: string]: string;
};
export const getOptions: (quotes: LineOptions, customLabels: CustomLabels) => ChartOptions<"line"> = ({
  isStacked = false,
  isPercentageSet = false,
  xAxis = { hasTextAsValue: false },
  yAxis = { hasTextAsValue: false },
  isTimeBased = false,
  timeOptions,
  language = Language.English
}, customLabels) => {
  let xTicksConfiguration: any;
  if (isTimeBased || xAxis.hasTextAsValue) {
    if (customLabels) {
      xTicksConfiguration = {
        callback: function (val) {
          const utcDate = new Date(val).toISOString();
          if (customLabels[utcDate] !== undefined) return customLabels[utcDate];
        }
      };

    } else {
      xTicksConfiguration = {};
    }
  } else {
    xTicksConfiguration = {
      callback: function (val) {
        if (typeof val === 'string') return val;
        return formattNumber(val);
      }
    };
  }

  const options: ChartOptions<"line"> = {
    responsive: true,
    indexAxis: 'x',
    scales: {
      x: {
        type: isTimeBased ? 'time' : 'category',
        time: timeOptions,
        grid: {
          display: false,
        },
        stacked: isStacked ? true : false,
        ticks: xTicksConfiguration,
        bounds: 'data',
        min: xAxis.min,
        max: xAxis.max,
        adapters: {
          date: {
            locale: Locales[language],
          }
        },
        reverse : xAxis.reverse || false
      },
      y: {
        stacked: isStacked ? true : false,
        ticks: yAxis.hasTextAsValue ? {} : {
          callback: function (val) {
            if (typeof val === 'string') return val;
            return formattNumber(val);
          }
        },
        min: yAxis.min,
        max: yAxis.max && Math.round(+yAxis.max),
        border: {
          display: false,
        },
        afterUpdate: function (scaleInstance) {
          scaleInstance.width = 50; // sets the width to 100px
        },
        adapters: {
          date: {
            locale: Locales[language],
          }
        },
      }
    },
    plugins: {
      legend: {
        display: false,
        position: 'bottom' as const,
      },
      title: {
        display: false,
      },
      zoom: {
        limits: {
          x: getZoomLimits(xAxis),
          y: getZoomLimits(yAxis),
        },
        pan: {
          enabled: true,
          mode: 'xy',
          threshold: 1,
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true
          },
          mode: 'xy',
        }
      },
      datalabels: {
        clip: true,
        display: true,
        anchor: 'end',
        align: 'top',
        color: '#454546',
        offset: -2,
        font: { size: 9 },
        formatter: (value: any, context: any) => {
          if (isPercentageSet) {
            return `${value.toFixed(2)}%`;
          }
          return formattNumber(value);
        }
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            if (isPercentageSet) {
              const value = context.parsed.y;
              return `${value.toFixed(2)}%`;
            }
          },
          title(context: TooltipItem<"line">[]): string | string[] | void {
            if (isTimeBased && xAxis.hasTextAsValue) {
              const parsedDate = parse(context[0].label, "MMM d, yyyy, h:mm:ss a", new Date());
              return customLabels[format(parsedDate, "yyyy-MM-dd") + "T06:00:00.000Z"] || context[0].label;
            }

            return context[0].label;
          }
        }
      }
    },
  };

  return options;
}

const getZoomLimits = (axis: Axis) => {
  if (!axis.max || !axis.min) return {}

  return {
    min: typeof axis.min === 'string' ? new Date(axis.min).valueOf() : axis.min,
    max: typeof axis.max === 'string' ? new Date(axis.max).valueOf() : axis.max,
  }
}

const formattNumber = (number: number) => {
  if (typeof number === 'string')
    return number;
  if (number < 0) return '';
  if (Math.abs(number) >= 1000000) {
    return `${toFixed(number / 1000000, 1)}M`
  } else if (Math.abs(number) > 1000) {
    return `${toFixed(number / 1000, 1)}K`
  } else {
    return toFixed(number, 1);
  }
}
