import React, { useState } from 'react';
import {PrimaryButton, SecondaryButton} from '@etiquette-ui/buttons';

import Input from '@appsinti/ui-core/Input';
import {MiddleWidth} from '@appsinti/ui-core/styles';
import {Container, CustomRow, Text } from '../../../components/dialogs/styles';

import useI18n from '@appsinti/i18n/useI18n';
import { modifyNameById } from '../../../../services/cameras';
import { Ring } from '@uiball/loaders';

type Props = {
  onClose: ()=>void,
  id: string,
  name: string,
  newName: string,
  setNewName: React.Dispatch<string>,
  nextStep: ()=>void,
  appId: number|undefined,
}
const EditName: React.FC<Props> = ({ onClose, id, name, newName, setNewName, nextStep, appId}) => {
  const [error, setError] = useState<string>('');
  const { translate, selectedLang } = useI18n();
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.value !== '') setError('');
    setNewName(event.target.value);
  }

  const handleSubmit = async () => {
    if(name === newName) {
      setError(translate('New name must be different'));
      return;
    }
    if(name.length === 0) {
      setError(translate('Name must be in a valid format'));
      return;
    }
    setLoading(true);
    try {
      if( !!appId ){ 
        await modifyNameById(id, newName, selectedLang, appId);
        nextStep();
      }
    } catch (e) {
      setError(translate('Name must be in a valid format'));
    }
    setLoading(false);
  }

  return (
    <Container>
      <Text style={{ textAlign: 'start' }}>
        <strong>{translate('Current name')}</strong> {name}
      </Text>
      <Input
        className={''}
        inputProps={{
          onChange: handleChange,
          value: newName
        }}
        labelProps={{
          label: translate('New name')
        }}
        errorProps={{
          error: error 
        }}
      />
      <CustomRow>
        <MiddleWidth>
          <SecondaryButton onClick={onClose} disabled={loading}>{translate('Cancel')}</SecondaryButton>
        </MiddleWidth>
        <MiddleWidth>
          <PrimaryButton onClick={handleSubmit} disabled={loading}>{loading ? <Ring color={"white"} size={30} /> : translate('Update')}</PrimaryButton>
        </MiddleWidth>
      </CustomRow>
    </Container>
  )
}
export default EditName;
