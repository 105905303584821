import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import useI18n from '@appsinti/i18n/useI18n';
import { AddIcon } from "@etiquette-ui/icons";
import { PRIMARY } from "@etiquette-ui/colors";

const CustomLink = styled(Link)`
  height: unset;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 2px;
  justify-content: center;
  text-decoration: none;
  color: ${PRIMARY};
  font-size: 14px;
`;

const NewItemButton: React.FC<{to: string}> = ({to}) => {
  const {translateObject} = useI18n();
  return (
    <CustomLink to={to}>
      <AddIcon
        styles={{ svg: { width: "20px", height: "20px" } }}
      />
      {translateObject({en: 'Add', es: 'Nuevo'})}
    </CustomLink>
  )
}
export default NewItemButton;