const COLORS = [
  "#89E231",
  "#5191DE",
  "#EB6446",
  "#BB20DB",
  "#EBA631",
  "#FF0000",
  "#18FFF9",
  "#800080",
];

export default COLORS;
