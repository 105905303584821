import React, {useEffect, useState, useMemo} from 'react';
import styled from 'styled-components';
import {useAlert, ALERT_TYPES} from '@etiquette-ui/alert-dialog'; 
import { useData } from '../../../data';
import useI18n from '@appsinti/i18n/useI18n';

import CustomIcon from '@appsinti/ui-core/CustomIcon';
import EditIcon from '@appsinti/ui-core/resources/img/edit-icon.svg';

import CameraDialog from '../../../dialogs/CameraDialog';
import EditNameDialog from '../../../dialogs/EditNameDialog';
import ConfirmDialog from '@appsinti/ui-core/dialogs/ConfirmDialog';

import { IconContainer } from '../../../styles';
import Preview from "./Preview";

import Translation from '@appsinti/i18n/interfaces/Translation';

const Container = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 1rem;
`;
const Header = styled.div`
  position: absolute;
  top: .5rem;
  left: 0px;
  right: 0px;
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
`;

const Star = styled.div`
  width: 25px;
  height: 25px;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const translations:{[key:string]: Translation} = {
  'add favorite': {
    en: 'will set as favorite and be listed at the top list, click an option below to continue.',
    es: 'será listada como favorita. ¿Deseas confirmar esta acción?'
  },
  'delete favorite': {
    en: 'will be removed from your favorite list, click an option below to continue.',
    es: 'será eliminada de tus favoritos. ¿Deseas confirmar esta acción?'
  },
  'set as favorite': {
    en: 'Add to favorite list',
    es: 'Agregar a favoritos'
  },
  'remove from favorite': {
    en: 'Remove from favorites',
    es: 'Quitar de favoritos'
  }
}

const Video: React.FC<{title: string, id: string, areaId: string, preview: string, appId: number|undefined}> = ({title, id, areaId, preview, appId}) => {
  const alert = useAlert();
  const {starred, updateStarred} = useData();
  const [name, setTitle] = useState<string>(title);
  const [showCamera, setShowCamera] = useState<boolean>(false);
  const {translate, translateObject} = useI18n();
  
  const starredCamerasId: string[]  = useMemo(()=>{
    if(!starred) return [];
    return starred.map( camera => camera.id);
  },[starred]);

  const showCameraDialog = () => {
    console.log('show camera');
    setShowCamera(true);
  } 

  const showEditCameraDialog = () => {
    alert({
      type: ALERT_TYPES.CUSTOM,
      config: {
        props: {
          id: id,
          name: title,
          appId
        },
        body: EditNameDialog,
      },
    }).then((name: string)=>{
      setTitle(name)
    })
  }

  const showStarredDialog = () => {
    alert({
      type: ALERT_TYPES.CUSTOM,
      config: {
        props:{
          title: translate('Action required'),
          textBody: `${title} ${translateObject(starredCamerasId.includes(id) ? translations['delete favorite'] : translations['add favorite'])}`,
          confirmButtonText: translateObject(starredCamerasId.includes(id) ? translations['remove from favorite'] : translations['set as favorite']),
          closeButtonText: translate('Cancel'),
          onSubmit: () => onChangeStarred(id)
        },
        body: ConfirmDialog
      },
    })
  }

  const onChangeStarred = (newId: string) => {
    let starredIds: string[] = starredCamerasId;
    
    if(starredIds.includes(newId)){
      starredIds = starredIds.filter( id => id !== newId);
    }
    else starredIds = [...starredIds, newId];

    updateStarred(starredIds);
  }

  useEffect(()=>{
    setTitle(title);
  },[title]);

  return (
    <Container>
      <Header>
        <IconContainer onClick={showEditCameraDialog} isLeftPosition={true}>
          <CustomIcon icon={EditIcon} isImage={true} />
        </IconContainer>
        <Title>
          {name}
        </Title>
        <Star onClick={showStarredDialog}>
          <CustomIcon icon={'star'} svgStyles={{line: {fill: starredCamerasId.includes(id) ? 'white' : 'none'}}} />
        </Star>
      </Header>
      <CameraDialog open={showCamera} onClose={() => setShowCamera(false)} title={title} areaId={areaId} id={id} />
      <Preview url={preview} onClick={showCameraDialog}/>
    </Container>
  )
}
export default Video;