import React from "react";
import { ChevronRightIcon } from "@etiquette-ui/icons";
import ListItemContainer from "../../../containers/ListItemContainer";
import { RangeDate } from "@appsinti/stats-utils/src/interfaces/RangeDatesConfig";
import useI18n from "@appsinti/i18n/useI18n";

const RangeDateItem: React.FC<{ rangeDate: RangeDate, rangeDateId: string }> = ({ rangeDate, rangeDateId }) => {
  const {translateObject} = useI18n();
  return (
    <ListItemContainer to={"range-date"} state={{ rangeDate, rangeDateId }}>
      <p>{translateObject(rangeDate.title)}</p>
      <ChevronRightIcon styles={{ svg: { width: "20px", height: "20px" } }} />
    </ListItemContainer>
  );
};
export default RangeDateItem;