import React, { useMemo } from "react";
import { useAccess } from "providers/access";
import { SummaryApp } from "resources/api/interfaces/IApp";
import { ADMIN_IMAGES_URL } from "resources/constants/urls";
import Icon from "@etiquette-ui/icons";
import StatsSummaryPreview from "@appsinti/stats-summary-frontend/preview";
import {
  ContentContainer,
  IconContainer,
  ImageLarge,
  TitleContainer,
  TitleWithImageContainer,
} from "./styles";

const ChevronRight: React.FC<{ bigger?: boolean }> = ({ bigger = false }) => {
  return (
    <IconContainer className={bigger ? "bigger" : ""}>
      <Icon
        icon={"chevron-right"}
        styles={{
          line: { fill: "none", strokeWidth: "1.5px", stroke: "black" },
          svg: { height: "100%", width: "100%" },
        }}
      />
    </IconContainer>
  );
};

const ComponentPicker: React.FC<{ app?: SummaryApp; groupId: number }> = ({
  app,
  groupId,
}) => {
  const { groups } = useAccess();
  const heightRestriction = useMemo(() => {
    const groupsList = Object.values(groups);
    if (groupsList.length === 2) return "half";
    if (groupsList.length === 3) return "third";
    if (groupsList.length === 4) return "half";
    if (groupsList.length > 4) return "third";
    return "half";
  }, [groups]);

  const widthRestriction = useMemo(() => {
    const groupsList = Object.values(groups);
    if (groupsList.length < 4) return "full-width";
    return "half-width";
  }, [groups]);

  const size = useMemo(
    () => (Object.values(groups).length > 3 ? "half-width" : "full-width"),
    [groups]
  );

  if (!app) {
    return (
      <>
        <TitleContainer className={size}>
          <p>{groups[groupId].name}</p>
          <ChevronRight bigger={Object.values(groups).length <= 3} />
        </TitleContainer>
        <ContentContainer>
          <ImageLarge
            src={`${ADMIN_IMAGES_URL}/${groups[groupId].imageLight}`}
          />
        </ContentContainer>
      </>
    );
  }

  return (
    <>
      <TitleContainer className={size}>
        <TitleWithImageContainer className={size}>
          <div className="image">
            <ImageLarge
              src={`${ADMIN_IMAGES_URL}/${groups[groupId].imageLight}`}
            />
          </div>
          <p>{groups[groupId].name}</p>
        </TitleWithImageContainer>
        <ChevronRight bigger={Object.values(groups).length <= 3} />
      </TitleContainer>
      <ContentContainer>
        {app.module === "stats-summary" && (
          <StatsSummaryPreview
            appId={app.id}
            heightRestriction={heightRestriction}
            widthRestriction={widthRestriction}
          />
        )}
      </ContentContainer>
    </>
  );
};

export default ComponentPicker;
