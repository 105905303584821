import React from "react";
import { Route, Routes } from "react-router-dom";
import Graph from "./pages/Graph";
import EditSpecialDays from "./pages/EditSpecialDays";
import EditRangeDates from "./pages/EditRangeDates";

const Sales: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Graph />} />
      <Route path="edit-special-days/*" element={<EditSpecialDays />} />
      <Route path="edit-range-dates/*" element={<EditRangeDates />} />
    </Routes>
  );
};
export default Sales;
