import React, { useEffect, useMemo } from "react";
import Body from "@appsinti/stats-frontend/home/components/Body";
import BarChart from "@appsinti/stats-frontend/home/components/charts/bar";
import LineChart from "../../components/charts/line";
import { useData } from "../../data";
import { BG_DARK } from "@etiquette-ui/colors";
import { Ring } from "@uiball/loaders";
import COLORS from "../../../resources/constants/colors";
import Translation from "@appsinti/i18n/interfaces/Translation";
import MultipleCharts from "./components/MultipleCharts";
import Navbar from "../../components/Navbar";

const Cash: React.FC = () => {
  const { cash, fetchCash } = useData();

  useEffect(() => {
    fetchCash();
  }, []);

  const dataStacks = useMemo(() => {
    if (!cash?.monthly_sales_months) return [] as string[];
    const stacks = new Set<string>();
    for (const set of cash.monthly_sales_months.labels) {
      if (typeof set.en === "string") stacks.add(set.en);
    }
    return Array.from(stacks.values());
  }, [cash?.monthly_sales_months]);

  return cash ? (
    <Body>
      <LineChart
        dataObject={cash?.month}
        lineOptions={{
          xAxis: {
            hasTextAsValue: true,
            min: cash.month?.xAxis?.min,
            max: cash.month?.xAxis?.max,
          },
          yAxis: {
            hasTextAsValue: false,
            min: cash.month?.yAxis?.min,
            max: cash.month?.yAxis?.max,
          },
          isTimeBased: cash?.month.isTimeBased,
          timeOptions: {
            unit: "day",
            displayFormats: { day: "EEEEEE" },
          },
        }}
        dottedSegmentOptions={{
          hasADottedSegment: true,
          dottedSegmentType: "segment",
          dottedSegmentAlignment: "end",
        }}
      />
      <LineChart
        dataObject={cash?.day_week_data}
        lineOptions={{
          xAxis: {
            hasTextAsValue: true,
            min: cash.day_week_data.xAxis?.min,
            max: cash.day_week_data.xAxis?.max,
          },
          yAxis: {
            hasTextAsValue: false,
            min: cash.day_week_data.yAxis?.min,
            max: cash.day_week_data.yAxis?.max,
          },
          isTimeBased: cash?.day_week_data.isTimeBased,
          timeOptions: {
            unit: "day",
            displayFormats: { day: "EEEEEE" },
          },
        }}
        dottedSegmentOptions={{
          hasADottedSegment: true,
          dottedSegmentType: "complete",
        }}
      />
      <LineChart
        dataObject={cash?.weekly}
        lineOptions={{
          xAxis: {
            hasTextAsValue: true,
            min: cash.weekly.xAxis?.min,
            max: cash.weekly.xAxis?.max,
          },
          yAxis: {
            hasTextAsValue: false,
            min: cash.weekly.yAxis?.min,
            max: cash.weekly.yAxis?.max,
          },
          isTimeBased: cash?.weekly.isTimeBased,
          timeOptions: {
            unit: "day",
            displayFormats: { day: "EEEEEE" },
          },
          isPercentageSet: true,
        }}
        dottedSegmentOptions={{
          hasADottedSegment: true,
          dottedSegmentType: "segment",
          dottedSegmentAlignment: "start",
        }}
      />
      <LineChart
        dataObject={cash?.daily}
        lineOptions={{
          xAxis: {
            hasTextAsValue: true,
            min: cash.daily.xAxis?.min,
            max: cash.daily.xAxis?.max,
          },
          yAxis: {
            hasTextAsValue: false,
            min: cash.daily.yAxis?.min,
            max: cash.daily.yAxis?.max,
          },
          isTimeBased: cash?.daily.isTimeBased,
          timeOptions: {
            unit: "day",
            displayFormats: { day: "EEEEEE" },
          },
        }}
      />
      <BarChart
        dataObject={cash?.daily_employee}
        barOptions={{
          isHorizontal: true,
          yAxis: {
            showLabels: false,
          },
          isPercentageSet: true,
        }}
        isOnlyOneColor={true}
      />
      <BarChart
        dataObject={cash?.weekly_employee}
        barOptions={{
          isHorizontal: true,
          yAxis: {
            showLabels: false,
          },
          isPercentageSet: true,
        }}
        isOnlyOneColor={false}
        colorCallback={(index, _, dataObject) => {
          if (!dataObject) {
            return COLORS[0];
          }
          const maxDaysWorked = Math.max(
            ...dataObject.labels.map((label) => {
              const enLabel = (label as Translation)?.en || "";
              const match = enLabel.match(/\((\d+) days\)/);
              return match ? parseInt(match[1], 10) : 0;
            })
          );

          const enLabel = (dataObject?.labels[index] as Translation)?.en || "";
          const match = enLabel.match(/\((\d+) days\)/);
          const days = match ? parseInt(match[1], 10) : 0;
          return days === maxDaysWorked ? COLORS[1] : COLORS[4];
        }}
      />
      <BarChart
        dataObject={cash?.sales_per_employee}
        barOptions={{
          isHorizontal: true,
          yAxis: {
            showLabels: false,
          },
          isPercentageSet: true,
        }}
        isOnlyOneColor={true}
      />
      <MultipleCharts
        datastacks={dataStacks}
        title={cash?.monthly_sales_months.title}
        subtitle={cash?.monthly_sales_months.subtitle}
      >
        {cash?.monthly_sales_months.datasets.map((dataset) => (
          <>
            <h2>{dataset.title.en}</h2>
            <BarChart
              dataObject={dataset}
              barOptions={{
                isHorizontal: true,
                yAxis: {
                  showLabels: false,
                },
                xAxis: {
                  showLabels: true,
                },
                isPercentageSet: true,
              }}
              isOnlyOneColor={false}
              fullCard={false}
              colorCallback={(index, _, dataObject) => {
                if (!dataObject) {
                  return COLORS[0];
                }
                return COLORS[index];
              }}
            />
          </>
        ))}
      </MultipleCharts>
    </Body>
  ) : (
    <Body style={{ display: "flex", justifyContent: "center" }}>
      <Ring color={BG_DARK} size={50} />
    </Body>
  );
};
export default Cash;
