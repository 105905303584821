const EN: { [key: string]: string } = {

    //Core frontend
    'Logout': 'Log Out',
    'min old': 'min old',
    'FORMAT_TIME_DISTANCE': '%distance% ago',

    //Weather
    'Weather': 'Weather',
    'Today': 'Today',

    //Stats
    'Total sales': 'Total sales',
    'Sales/ Guest':  'Sales/ Guest',
    'Closed sales': 'Closed sales',
    'Cash %': 'Cash %',
    'Now/Total': 'Now/Total',
    'Reserve': 'Reserve',
    'Cancellations': 'Cancel',
    'Courtesy': 'Courtesy',
    'Month': 'Month',
    'Week': 'Week',
    'Customer Count': 'Customer Count',
    'Edit Special Days': 'Edit Special Days',

    //Big brother
    'camera': 'camera',
    'Cameras': 'Cameras',
    'Viewing': 'Viewing',
    'New camera': 'New camera',
    'Top camera': 'Top camera',
    'Bottom camera': 'Bottom camera',
    'Update Camera': 'Update camera',
    'Camera Preview': 'Camera Preview',
    'Action required': 'Action required',
    'Click on Continue button to complete the renamed': 'Click on Continue button to complete the renamed',
    'You need to highlight other camera to replace this one': 'You need to highlight other camera to replace this one',
    'Click on Update to set the camera as assigned to appsinti home': 'Click on Update to set the camera as assigned to appsinti home',
    'Click on Update to delete the camera assigned to appsinti home': 'Click on Update to delete the camera assigned to appsinti home',
    'You will change the camera view from the Appsinti home. Choose one below to replace:': 'You will change the camera view from the Appsinti home. Choose one below to replace:',

    //Buttons and inputs
    'Add': 'Add',
    'Delete': 'Delete',
    'Save': 'Save',
    'Back': 'Back',
    'Close': 'Close',
    'Cancel': 'Cancel',
    'Update': 'Update',
    'Continue': 'Continue',
    'New name': 'New name',
    'Current name':'Current name:',
    'Select...': 'Select...',
    'Back and edit': 'Back and edit',
    'Select and change': 'Select to change',
    'Appsinti home cameras': 'Appsinti home cameras',

    //Messages
    'Name must be in a valid format': 'Name must be in a valid format',
    'The upgrade was successful': 'The upgrade was successful',
    'The upgrade failed, try again later': 'The upgrade failed, try again later',
    'Loading...': 'Loading...',
};

export default EN;
