import SelectOption from "../../../../../../../interfaces/SelectOption";
import { v4 as uuidv4 } from "uuid";
import { WeekModifiersNames, daysOfWeekNames, monthNames } from "../../../../../../../resources/constants/dates";
import { add, format, parse, set } from "date-fns";
import { DayOfWeek, SpecialDay, WeekModifier } from "@appsinti/stats-utils/src/interfaces/SpecialDaysConfig";
import { DayOfWeekValues, WeekModifierValues } from "@appsinti/stats-utils/src/interfaces/SpecialDateValues";

export interface SpecialDayState {
  selectedType: "date" | "pattern" | "custom";
  title: string;
  fixedDay: { date: string };
  patternDay: {
    month: SelectOption<number>;
    weekModifier: SelectOption<WeekModifier>;
    dayOfWeek: SelectOption<DayOfWeek>;
  };
  customDates: { [key: string]: string };
}

type SpecialDayAction =
  | { type: "SET_TITLE"; payload: string }
  | { type: "SET_SELECTED_TYPE"; payload: "date" | "pattern" | "custom" }
  | { type: "SET_FIXED_DAY"; payload: string }
  | { type: "SET_PATTERN_DAY_MONTH"; payload: SelectOption<number> }
  | { type: "SET_PATTERN_DAY_WEEK_MODIFIER"; payload: SelectOption<WeekModifier> }
  | { type: "SET_PATTERN_DAY_OF_WEEK"; payload: SelectOption<DayOfWeek> }
  | { type: "SET_CUSTOM_DATES_DATE"; payload: { key: string, date: string } }
  | { type: "REMOVE_CUSTOM_DATES_DATE"; payload: string }
  | { type: "ADD_CUSTOM_DATES_DATE" }

export const getInitialState = (
  initialSpecialDay?: SpecialDay,
  selectedLang?: string,
): SpecialDayState => {
  const today = format(new Date(), "yyyy-MM-dd");
  const key = uuidv4();
  const lang = !!selectedLang ? selectedLang : 'en';

  const intialState: SpecialDayState = {
    selectedType: "date",
    title: "",
    fixedDay: { date: today },
    patternDay: {
      month: { value: 1, label: monthNames[1][lang] },
      dayOfWeek: {
        value: DayOfWeekValues.Sunday,
        label: daysOfWeekNames[DayOfWeekValues.Sunday][lang],
      },
      weekModifier: {
        value: WeekModifierValues.First,
        label: WeekModifiersNames[WeekModifierValues.First][lang],
      },
    },
    customDates: { [key]: today },
  };

  if (initialSpecialDay) {
    intialState.title = initialSpecialDay.title;
    intialState.selectedType = initialSpecialDay.date.type;

    if (initialSpecialDay.date.type === "date") {
      intialState.fixedDay = { date: initialSpecialDay.date.date };
    }

    if (initialSpecialDay.date.type === "pattern") {
      intialState.patternDay = {
        month: {
          value: initialSpecialDay.date.month,
          label: monthNames[initialSpecialDay.date.month][lang],
        },
        dayOfWeek: {
          value: initialSpecialDay.date.dayOfWeek,
          label: daysOfWeekNames[initialSpecialDay.date.dayOfWeek][lang],
        },
        weekModifier: {
          value: initialSpecialDay.date.weekModifier,
          label: WeekModifiersNames[initialSpecialDay.date.weekModifier][lang],
        },
      };
    }

    if(initialSpecialDay.date.type === "custom") {
      intialState.customDates = Object.fromEntries(Object.entries(initialSpecialDay.date.dates).sort(([, valueA], [, valueB])=> parse(valueA, "yyyy-MM-dd", new Date()).getTime() - parse(valueB, "yyyy-MM-dd", new Date()).getTime()));
    }
  }

  return intialState;
};

export const getSubmitObject = (state: SpecialDayState): SpecialDay | null => {
  if(state.selectedType === "date") {
    return {
      title: state.title,
      date: {
        type: "date",
        date: state.fixedDay.date,
      }
    }
  }

  if(state.selectedType === "pattern") {
    return {
      title: state.title,
      date: {
        type: "pattern",
        month: state.patternDay.month.value,
        weekModifier: state.patternDay.weekModifier.value,
        dayOfWeek: state.patternDay.dayOfWeek.value,
      }
    }
  }

  if(state.selectedType === "custom") {
    return {
      title: state.title,
      date: {
        type: "custom",
        dates: state.customDates,
      }
    }
  }

  return null;
}

const specialDayReducer = (
  state: SpecialDayState,
  action: SpecialDayAction
): SpecialDayState => {
  switch (action.type) {
    case "SET_TITLE":
      return { ...state, title: action.payload };
    case "SET_SELECTED_TYPE":
      return { ...state, selectedType: action.payload };
    case "SET_FIXED_DAY":
      return { ...state, fixedDay: { date: action.payload } };
    case "SET_PATTERN_DAY_MONTH":
      return {
        ...state,
        patternDay: { ...state.patternDay, month: action.payload },
      };
    case "SET_PATTERN_DAY_OF_WEEK":
      return {
        ...state,
        patternDay: { ...state.patternDay, dayOfWeek: action.payload },
      };
    case "SET_PATTERN_DAY_WEEK_MODIFIER":
      return {
        ...state,
        patternDay: { ...state.patternDay, weekModifier: action.payload },
      };
    case "SET_CUSTOM_DATES_DATE":
      return { ...state, customDates: { ...state.customDates, [action.payload.key]: action.payload.date } }
    case "REMOVE_CUSTOM_DATES_DATE":
      const customDates = {};
      for(const [key, value] of Object.entries(state.customDates)) {
        if(key !== action.payload) {
          customDates[key] = value;
        }
      }
      return { ...state, customDates }
    case "ADD_CUSTOM_DATES_DATE":
      const date = Object.values(state.customDates).pop();
      const years = Object.entries(state.customDates).map(([,date]) => parse(date, "yyyy-MM-dd", new Date()).getFullYear());      
      let nextYear = date ? add(parse(date, "yyyy-MM-dd", new Date()), { years: 1 }).getFullYear() : new Date().getFullYear();
      
      while(years.includes(nextYear)){ nextYear++; };

      return { 
        ...state, 
        customDates: { 
          ...state.customDates, 
          [uuidv4()]: format(date ? set(parse(date, "yyyy-MM-dd", new Date()), { year: nextYear }) : new Date(), "yyyy-MM-dd") 
        } 
        }
    default:
      return state;
  }
};

export default specialDayReducer;
