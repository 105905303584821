import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import VideoUI from "./VideoUI";
import Modal from "react-modal";
import "./styles.css";
import {
  BG,
  BG_DARK,
  DISABLED_BUTTON,
  RIGHT_DARK,
  TEXT,
} from "@etiquette-ui/colors";
import { useSocket } from "../../Socket";
import useI18n from "@appsinti/i18n/useI18n";
import { H3, P, Subtitle } from "@etiquette-ui/typography";
import CloseSVG from "../../../resources/close-icon.svg";
import CustomIcon from "@appsinti/ui-core/CustomIcon";
import VolumeOnSVG from "../../../resources/volume-on-icon.svg";
import VolumeOffSVG from "../../../resources/volume-off-icon.svg";

Modal.setAppElement("#root");

const Content = styled.div`
  padding: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 24px 24px;
  overflow: hidden;
`;

const HeaderContainer = styled.div`
  padding: 16px;
  color: white;
  background-color: ${BG};
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: start;
  > h3 {
    color: white;
  }
`;

const CloseIcon = styled.img`
  position: absolute;
  right: 6px;
  top: -27px;
  width: 20px;
  cursor: pointer;
  z-index: 5;
`;

const VideoContainer = styled.div`
  display: flex;
  width: 95vw;
  max-width: 100%;
  position: relative;
`;

const ControlsContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 20px 0;
`;

const TextButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  p {
    font-size: 18px;
    user-select: none;
  }
`;

const IconContainer = styled.div<{ active: boolean }>`
  width: 44px;
  height: 44px;
  display: flex;
  border-radius: 22px;
  background-color: ${({ active }) => (active ? RIGHT_DARK : DISABLED_BUTTON)};
  align-items: center;
  justify-content: center;
  img {
    width: 80%;
    height: auto;
  }
`;

type Props = {
  open: boolean;
  onClose: () => void;
  areaId: string;
  id: string;
  title: string;
};

const CameraModal: React.FC<Props> = ({ open, onClose, title, areaId, id }) => {
  const { translateObject } = useI18n();
  const [muted, setMuted] = useState<boolean>(true);
  const { socket: videoSocket } = useSocket();
  const [withAudio, setWithAudio] = useState<boolean>(false);
  const [streamReady, setStreamReady] = useState<boolean>(false);

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      className={"video-ui-modal"}
      overlayClassName={"video-ui-background"}
      shouldCloseOnOverlayClick={false}
    >
      <CloseIcon src={CloseSVG} alt="close" onClick={onClose} />
      <HeaderContainer>
        <H3 className={""}>{title}</H3>
      </HeaderContainer>
      <Content>
        <VideoContainer>
          {!!videoSocket && (<VideoUI
            areaId={areaId}
            cameraId={id}
            muted={muted}
            videoSocket={videoSocket}
            setWithAudio={setWithAudio}
            streamReady={streamReady}
            setStreamReady={setStreamReady}
            setMuted={setMuted}
          />)}
        </VideoContainer>
        {withAudio && streamReady && (
          <ControlsContainer>
            <TextButton onClick={() => setMuted(!muted)}>
              <Subtitle className="">
                {muted
                  ? translateObject({ es: "Escuchar", en: "Listen Now" })
                  : translateObject({
                      es: "Escuchando...",
                      en: "Listening...",
                    })}
              </Subtitle>
              <IconContainer active={!muted}>
                <CustomIcon
                  isImage={true}
                  icon={muted ? VolumeOffSVG : VolumeOnSVG}
                />
              </IconContainer>
            </TextButton>
          </ControlsContainer>
        )}
      </Content>
    </Modal>
  );
};
export default CameraModal;
