import React from "react";
import { AppProvider } from "./AppProvider";
import { Route, Routes } from "react-router-dom";
import Grid from "./pages/Grid";
import AppPage from "./pages/AppPage";
import UpdateProvider from "@appsinti/trigger-update";
import MetronomeProvider from "@appsinti/metronome";
import AlertServiceProvider from '@etiquette-ui/alert-dialog';
import Summary from "./pages/Summary";

export const HomePage: React.FC = () => {

  return (
    <AppProvider>
      <UpdateProvider>
        <MetronomeProvider>
          <AlertServiceProvider>
          <Routes>
            <Route path={"/"} element={<Summary />} />
            <Route path={`/app/:appId/*`} element={<AppPage />} />
            <Route path={"/:groupId"} element={<Grid />} />
          </Routes>
          </AlertServiceProvider>
        </MetronomeProvider>
      </UpdateProvider>
    </AppProvider>
  );
};
