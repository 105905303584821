import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Icon from '@etiquette-ui/icons';
import { PRIMARY } from '@etiquette-ui/colors';

import IconTextItem from '@appsinti/ui-core/IconTextItem';
import DropdownContainer from '@appsinti/ui-core/dropdown/DropdownContainer';
import { Container, Row } from '@appsinti/ui-core/styles';

import AudioIcon from '@appsinti/sound-frontend/resources/img/audio-icon.svg';
import EditIcon from '@appsinti/ui-core/resources/img/edit-icon.svg';

import Item from '../Item';
import { ISource } from "../interfaces/source";
import { getSources, setSelectedSource as setSelectedSourceInLocalStorage } from "../services/sources";
import { useAlert, ALERT_TYPES } from '@etiquette-ui/alert-dialog';
import EditNameDialog from "./components/dialogs/editNameDialog";

const IconContainer = styled.div<{ isDisplayed: boolean }>`
  max-width: 2rem;
  flex: 0 0 2rem;
  margin-left: auto;
  display: flex;
  align-items: center;

  transform: ${({ isDisplayed }) => isDisplayed ? "scaleY(-1)" : "scaleY(1)"};
  transition-duration: .3s;
`;

const Selector: React.FC<{
    appId: number;
}> = ({ appId }) => {
    const [isDisplayed, setIsDisplayed] = useState<boolean>(false);
    const [sources, setSources] = useState<ISource[]>([])
    const [selectedSourceName, setSelectedSourceName] = useState('')
    const customAlert = useAlert();

    const toggleDisplayed = () => {
        setIsDisplayed(!isDisplayed);
    }

    const showEditSourceDialog = (id, name) => {
        customAlert({
            type: ALERT_TYPES.CUSTOM,
            config: {
                props: {
                    id,
                    name,
                    setTitle: setSelectedSource,
                    appId
                },
                body: EditNameDialog,
            },
        })
    }

    const setSelectedSource = (id, name) => {
        const idx = sources.findIndex(source => source.id === id)
        sources[idx].name = name
        setSelectedSourceName(name)
        setSources(sources)
        // setSelectedSourceInLocalStorage(sources[idx]) // this line is commented out until the selection logic is implemented
    }

    useEffect(() => {
        (async function () {
            const sources = await getSources(appId)
            if (sources.length > 0) {
                setSelectedSourceInLocalStorage(sources[0])
                setSources(sources)
                setSelectedSourceName(sources[0].name)
            }
        })()
    }, [])

    return (
        <Container>
            <IconTextItem
                icon={AudioIcon}
                isImage={true}
                body={<Container>
                    <Row onClick={toggleDisplayed}>
                        Audio Selection: {selectedSourceName}
                        <IconContainer isDisplayed={isDisplayed}>
                            <Icon
                                icon={'chevron-down'}
                                styles={{
                                    svg: { width: '20px' },
                                    line: {
                                        fill: 'none',
                                        strokeLinecap: 'round',
                                        strokeLinejoin: 'round',
                                        strokeWidth: '2px',
                                        stroke: 'white',
                                    },
                                }}
                            />
                        </IconContainer>
                    </Row>
                    {isDisplayed && (
                        <DropdownContainer background={PRIMARY}>
                            {
                                sources.map(source => (
                                    <IconTextItem
                                        icon={EditIcon}
                                        isImage={true}
                                        isInverted={true}
                                        isIconClikable={true}
                                        onClick={() => showEditSourceDialog(source.id, source.name)}
                                        body={<Item isDarkType={true} name={source.name} isActive={true} />}
                                        key={source.id}
                                    />
                                ))
                            }
                        </DropdownContainer>
                    )}
                </Container>}
            />
        </Container>
    )
}

export default Selector;
