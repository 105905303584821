import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/file";
import { Ring } from "@uiball/loaders";
import { BACKEND } from "../../../constants/config";
import { BG_DARK } from "@etiquette-ui/colors";
import styled from "styled-components";
import axios, { AxiosError } from "axios";
import { Socket } from "socket.io-client";

const VideoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px 0;
`;

function supportsHLS() {
  var video = document.createElement("video");
  return Boolean(
    video.canPlayType("application/vnd.apple.mpegURL") ||
      video.canPlayType("audio/mpegurl")
  );
}

const VideoUI: React.FC<{
  areaId: string;
  cameraId: string;
  muted: boolean;
  setWithAudio: React.Dispatch<React.SetStateAction<boolean>>;
  streamReady: boolean;
  setStreamReady: React.Dispatch<React.SetStateAction<boolean>>;
  setMuted: React.Dispatch<React.SetStateAction<boolean>>;
  videoSocket: Socket;
}> = ({
  areaId,
  cameraId,
  muted,
  setWithAudio,
  streamReady,
  setStreamReady,
  setMuted,
  videoSocket,
}) => {
  const [videoSrc, setVideoSrc] = useState<string>("");

  useEffect(() => {
    return () => {
      setStreamReady(false);
      setWithAudio(false);
      setMuted(true);
    };
  }, []);

  useEffect(() => {
    if (!videoSocket) return;

    videoSocket.emit("start-bigbrother-cam", { areaId, cameraId });

    videoSocket.on("bigbrother-stream-ready", ({ streamUrl }) => {
      console.log(streamUrl);
      setVideoSrc(streamUrl);
    });

    videoSocket.on("bigbrother-stream-audio", ({ withAudio }) => {
      console.log(withAudio);
      setWithAudio(withAudio);
    });

    return () => {
      videoSocket.emit("stop-bigbrother-cam", { areaId, cameraId });
      videoSocket.off("bigbrother-stream-ready");
      videoSocket.off("bigbrother-stream-audio");
    };
  }, [videoSocket]);

  useEffect(() => {
    if (!videoSrc || streamReady) return;
    const interval = setInterval(async () => {
      try {
        await axios.get(
          `${BACKEND}/appsinti/cameras${videoSrc}?nocache=${Date.now()}`
        );
        setStreamReady(true);
        clearInterval(interval);
      } catch (e: AxiosError | any) {
        console.error(e);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [videoSrc]);

  return !!videoSrc && streamReady ? (
    supportsHLS() ? (
      <video
        src={`${BACKEND}/appsinti/cameras${videoSrc}?nocache=${Date.now()}`}
        width={"100%"}
        height={"auto"}
        autoPlay={true}
        muted={muted}
        style={{ objectFit: "cover" }}
        playsInline={true}
      />
    ) : (
      <ReactPlayer
        url={`${BACKEND}/appsinti/cameras${videoSrc}?nocache=${Date.now()}`}
        width={"100%"}
        height={"auto"}
        playing={true}
        muted={muted}
        style={{ objectFit: "cover" }}
        playsinline={true}
        config={{ forceHLS: true }}
      />
    )
  ) : (
    <VideoContainer>
      <Ring color={BG_DARK} size={22} />
    </VideoContainer>
  );
};

export default VideoUI;
