import React from 'react';

import {Container} from './styles';
import Card from './components/Card';
import IVideo from '../../../interfaces/IVideo';
import IArea from '../../../interfaces/IArea';

import { useData } from '../../data';

const convertToAnArea = (cameras: IVideo[]): IArea => {
  return ({
    id: 'starred',
    name: {en: 'Favorites', es: 'Favoritos'},
    cameras
  });
}

const Accordion: React.FC = () => {
  const { areas, starred } = useData();

  const starredArea = {
    id: 'starred',
    name: {en: 'Favorites', es: 'Favoritos'},
    cameras: starred
  }
  
  return (
    <Container>
      {starred.length > 0 && (
        <Card key={starredArea.id} area={starredArea} isTitleEditable={false}/>
      )}
      {areas.map((area, i)=>(
        <Card key={area.id} area={area} isCollapsedInit={ starred.length > 0 ? true : i !== 0 } />
      ))}
    </Container>
  )
}

export default Accordion;
