import React, { useEffect, useState } from "react";
import styled from "styled-components";

import MainCard from "@appsinti/ui-core/MainCard";
import CardTemplate from "@appsinti/ui-core/CardTemplate";
import { Row, FullWidth, CardTitle } from "@appsinti/ui-core/styles";

import useI18n from "@appsinti/i18n/useI18n";
import IVideo from "./interfaces/IVideo";
import { getData } from "./services/cameras";
import { useUpdate } from "@appsinti/trigger-update";
import { Ring } from "@uiball/loaders";
import { getPreviewMirror, updatePreviewMirror } from "@appsinti/preview-mirror";

const CamerasGrid = styled.div`
  height: 100%;
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 5px;
`;

const PreviewImage = styled.div<{ previewImage: string }>`
  background-image: url(${({ previewImage }) => previewImage});
  background-size: cover;
  width: 100%;
  height: 100%;
`;

const Preview: React.FC<{ appId: number }> = ({ appId }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { translate } = useI18n();
  const [previewCameras, setPreviewCameras] = useState<IVideo[]>([]);
  const { translateObject } = useI18n();
  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  const { update, addLoadingState, resolveLoadingState } = useUpdate();

  const getLocalPreview = () => {
    const preview = getPreviewMirror(appId);
    if(!preview)    
      fetchPreviewCameras();
    else {
      setPreviewCameras(JSON.parse(preview) as IVideo[]);
      resolveLoadingState("bigbrother-frontend");
      setLoading(false);
    }
  }

  async function fetchPreviewCameras() {
    const { previews } = await getData(appId);
    updatePreviewMirror(JSON.stringify(previews), appId);
    getLocalPreview();
    setCurrentTime(new Date());
  }

  useEffect(() => {
    getLocalPreview();
  }, []);

  useEffect(() => {
    if(update){
      setLoading(true);
      addLoadingState("bigbrother-frontend");
    }
    fetchPreviewCameras();
  }, [update]);

  return (
    <MainCard title={translate("Cameras")} lastUpdatedTime={currentTime}>
      <CamerasGrid>
        {loading && previewCameras.length === 0 ? (
          <div style={{ justifySelf: "center" }}>
            <Ring color={"white"} size={30} />
          </div>
        ) : (
          previewCameras.map((camera) => (
            <CardTemplate
              header={
                <Row>
                  <FullWidth>
                    <CardTitle>
                      {translateObject(camera.locationName)}
                    </CardTitle>
                  </FullWidth>
                </Row>
              }
              body={<PreviewImage previewImage={camera.previewImageURL} />}
              noPadding={true}
              key={camera.id}
            />
          ))
        )}
      </CamerasGrid>
    </MainCard>
  );
};

export default Preview;
